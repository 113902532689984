const initialState = {
  showNoteForm: false,
};

const triggersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_NOTE_FORM': {
      return {
        ...state,
        showNoteForm: true,
        issueId: action.issueId,
        note: action.note,
      };
    }
    case 'HIDE_NOTE_FORM': {
      return {
        ...state,
        showNoteForm: false,
        issueId: null,
      };
    }
    default:
      return state;
  }
};

export default triggersReducer;
