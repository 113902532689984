import { BaseModel, BaseCollection } from './base';

class IssueModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      name: '',
      drug_name: '',
      status: '',
      stage: '',
      view: '',
      cart_id: '',
      issue_days: '',
      issue_names: '',
      note_count: '',
      created_at: '',
      updated_at: '',
      activeEdit: false,
    };
  }

  static get routes() {
    return {
      issuesPath: '/issues',
      cartIssuesPath: (cartId) => `/issues?cart_id=${cartId}`,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  get routes() {
    return {
      all: '/issues',
      instance: this.id ? `/issues/${this.id}` : '/issues',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  modelName() {
    return 'issue';
  }
}

class IssuesCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return IssueModel;
  }
}

export { IssueModel, IssuesCollection };
