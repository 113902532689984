import React, { Component } from 'react';
import { connect } from 'react-redux';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import {
  removeCartFromOrder as removeCartFromOrderAction,
  generatePayment as generatePaymentAction,
  withdrawPayment as withdrawPaymentAction,
  removeOrder as removeOrderAction,
} from '@actions/panel/cart_view/orders';

import { CartsCollection } from '@models/cart';
import { UserModel } from '@models/user';

class OrderItem extends Component {
  getDeliveryLabel = (deliveryType) => {
    const option = this.props.deliveryOptions.find((d) => d.delivery_type === deliveryType);

    return option ? option.label : '---';
  }

  render() {
    const {
      order: {
        id,
        invoice_number,
        totalPriceWithCurrency,
        delivery_type,
        refundInfo,
        refundable,
        isDraft,
        isPending,
        submitted_at,
        isAwaitingShipment,
        isShipped,
        formattedCreatedAt,
        admin: {
          fullname,
        },
      },
      user,
      carts,
      removeCartFromOrder,
      generatePayment,
      withdrawPayment,
      removeOrder,
      openRefundForm,
    } = this.props;

    const orderCarts = carts.filterByOrderId(id);

    return (
      <>
        <tr className="open-cart-item align-center">
          <td className="text-center">
            {invoice_number}

            {(isDraft || isPending) && orderCarts.length === 0 && (
              <button
                onClick={() => removeOrder(id)}
                className="btn btn-outline-danger btn-sm"
              >
                <DeleteOutlineIcon fontSize="small" />
              </button>
            )}
          </td>
          <td>---</td>
          <td>---</td>
          <td>---</td>
          <td>{totalPriceWithCurrency}</td>
          <td>---</td>
          <td>---</td>
          <td>{formattedCreatedAt}</td>
          <td>{fullname || 'AUM Robot'}</td>
          <td>
            {isDraft && orderCarts.length > 0 && (
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => generatePayment(id)}
              >
                Generate
              </button>
            )}

            {isPending && orderCarts.length > 0 && (
              <span>
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => withdrawPayment(id)}
                >
                  Withdraw
                </button>

                <a
                  href={user.routes.signInToken}
                  className="btn btn-outline-danger btn-sm ml-2"
                  data-method="post"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Link
                </a>
              </span>
            )}

            {submitted_at && (submitted_at)}
          </td>
          <td>
            {this.getDeliveryLabel(delivery_type)}
            {isAwaitingShipment && ' (awaiting shipment)'}
            {isShipped && ' (shipped)'}
          </td>
          <td>
            {refundable && !refundInfo
              ? (
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => openRefundForm(this.props.order)}
                >
                  Refund
                </button>
              ) : (
                refundInfo
              )}
          </td>
        </tr>
        {orderCarts.map((cart) => (
          <tr key={cart.id} className="open-cart-item align-center">
            <td className="text-center">
              {isDraft && (
                <button
                  onClick={() => removeCartFromOrder(id, cart)}
                  className="btn btn-outline-danger btn-sm"
                >
                  <DeleteOutlineIcon fontSize="small" />
                </button>
              )}
            </td>
            <td>
              <a href={cart.routes.byId} className="btn btn-link btn-sm">{cart.id}</a>
              {/* <InfoIco title="Address Info" /> */}
            </td>
            <td>{cart.product_name}</td>
            <td>{cart.final_quantity}</td>
            <td>{cart.finalPriceWithCurrency}</td>
            <td>{cart.have_discounts ? 'Yes' : 'No'}</td>
            <td>{cart.branchName}</td>
            <td>{cart.formattedCreatedAt}</td>
            <td>---</td>
            <td>---</td>
            <td>{this.getDeliveryLabel(cart.delivery_type)}</td>
            <td>
              {cart.refunded_value > 0 && `- $${Number(cart.refunded_value).toFixed(2)}`}
            </td>
          </tr>
        ))}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: new UserModel(state.user),
  carts: new CartsCollection(state.carts.all_carts),
});

const mapDispatchToProps = (dispatch) => ({
  generatePayment: (orderId) => dispatch(generatePaymentAction(orderId)),
  withdrawPayment: (orderId) => dispatch(withdrawPaymentAction(orderId)),
  removeCartFromOrder: (orderId, cart) => dispatch(removeCartFromOrderAction(orderId, cart)),
  removeOrder: (orderId) => dispatch(removeOrderAction(orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
