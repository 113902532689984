import React, { Component } from "react";

import { UserModel } from "@models/user";
import PhoneInput from "@components/shared/PhoneInput";
import DateInput from "@components/shared/DateInput";
import TextInput from "@/components/shared/TextInput";
import CustomSelect from "@/components/shared/CustomSelect";

import http from "@/utils/http";

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: new UserModel(),
      landingPageOptions: this.landingPageOptions(),
      landingPageName: "",
      priceOptions: this.priceOptions(),
      priceName: "",
      processing: false,
      edited: false,
    };
  }

  handleInputChange = ({ target: { name, value } }) => {
    this.setState((prevState) => {
      const { user } = prevState;
      user.set(name, value);

      return { user, edited: true };
    });
  };

  handleSelectChange = (value, name) => {
    this.setState((prevState) => {
      const user = new UserModel({ ...prevState.user });
      user.set(name, value);

      return { user, edited: true };
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    this.setState({ processing: true });

    const { user } = this.state;

    http
      .post(user.routes.all, { user: user.attributes })
      .then((response) => {
        const newUser = new UserModel(response.data);
        window.location.pathname = newUser.routes.byId;
      })
      .catch((error) => {
        this.setState({ user: new UserModel(error.response.data) });
      })
      .finally(() => this.setState({ processing: false }));
  };

  landingPageOptions() {
    return this.props.landingPages.map((lp) => ({
      name: lp.name,
      value: lp.id,
    }));
  }

  priceOptions() {
    return this.props.prices.map((p) => ({
      name: p.title,
      value: p.id,
    }));
  }

  render() {
    const {
      user: {
        first_name,
        last_name,
        email,
        mobile_phone,
        date_of_birth,
        gender,
        landing_page_id,
        price_id,
        errors,
      },
      landingPageOptions,
      priceOptions,
      processing,
      edited,
    } = this.state;

    let { landingPageName } = this.state;
    const { landingPages } = this.props;

    const landingPage = landingPages.filter(
      (lp) => lp.id.toString() === landing_page_id
    );
    if (landingPage.length > 0) {
      landingPageName = landingPage[0].name;
    }

    let { priceName } = this.state;
    const { prices } = this.props;
    const price = prices.filter((p) => p.id.toString() === price_id);

    if (price.length > 0) {
      priceName = price[0].title;
    }

    return (
      <form
        onSubmit={this.handleFormSubmit}
        className="contact__form"
        id="adminContactForm"
      >
        <div className="container p-0">
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextInput
                  name="first_name"
                  label="First name"
                  type="text"
                  value={first_name || ""}
                  errors={errors.first_name}
                  onChange={this.handleInputChange}
                  icon="user"
                  isRequired="required"
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <TextInput
                  name="last_name"
                  label="Last name"
                  type="text"
                  value={last_name || ""}
                  errors={errors.last_name}
                  onChange={this.handleInputChange}
                  icon="user"
                  isRequired="required"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextInput
                  name="email"
                  label="Email"
                  type="email"
                  value={email || ""}
                  errors={errors.email}
                  onChange={this.handleInputChange}
                  icon="email"
                  isRequired="required"
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <CustomSelect
                  name="landing_page_id"
                  label="Landing Page"
                  value={landingPageName}
                  options={landingPageOptions}
                  onSelect={this.handleSelectChange}
                  errors={errors.landing_page}
                  isRequired="required"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <PhoneInput
                  type="tel"
                  name="mobile_phone"
                  label="Mobile Phone"
                  value={mobile_phone || ""}
                  onChange={this.handleInputChange}
                  errors={errors.mobile_phone}
                  icon="phone"
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <DateInput
                  name="date_of_birth"
                  label="Date Of Birth"
                  type="text"
                  placeholder="MM/DD/YYYY"
                  value={date_of_birth || ""}
                  errors={errors.date_of_birth}
                  onChange={this.handleInputChange}
                  required
                  icon="date"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <CustomSelect
                  name="gender"
                  label="Gender"
                  customClass="up"
                  value={gender || ""}
                  options={[{ name: "male" }, { name: "female" }]}
                  onSelect={this.handleSelectChange}
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <CustomSelect
                  name="price_id"
                  label="Price"
                  customClass="up"
                  value={priceName}
                  options={priceOptions}
                  onSelect={this.handleSelectChange}
                  errors={errors.price}
                  isRequired="required"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col row justify-content-end">
          <button
            type="reset"
            className="btn btn-sm btn-light mr-3"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>

          <button
            type="submit"
            className="btn btn-sm btn-primary"
            disabled={!edited || processing}
          >
            {processing ? "Processing..." : "Save"}
          </button>
        </div>
      </form>
    );
  }
}

export default ContactForm;
