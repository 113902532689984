import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '@material-ui/core/Icon';
import { TagModel, TagsCollection } from '@models/tag';
import TagForm from './TagForm';
import TagsList from './TagsList';

class Tags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTagForm: false,
      tags: new TagsCollection(this.props.tags),
    };
  }

  toggleTagForm = () => {
    this.setState((prevState) => ({ showTagForm: !prevState.showTagForm }));
  }

  hideForm = (newTag) => {
    this.setState((prevState) => {
      const newTags = new TagsCollection([newTag, ...prevState.tags.models]);
      return {
        showTagForm: false,
        tags: newTags,
      };
    });
  };

  render() {
    const { showTagForm, tags } = this.state;
    const { taggableModel, taggableModelId } = this.props;

    return (
      <div className="cart-tags text-right">
        <TagsList tags={tags} />
        <Icon key="toggleForm" onClick={this.toggleTagForm}>{showTagForm ? 'close' : 'add'}</Icon>
        {
          (showTagForm)
           && (
           <TagForm
             key="tagForm"
             onSubmitForm={this.hideForm}
             tags={tags}
             tag={new TagModel({ name: '', taggable_id: taggableModelId, taggable_model: taggableModel })}
           />
           )
        }
      </div>
    );
  }
}

Tags.propTypes = {
  taggableModelId: PropTypes.number.isRequired,
};

export default Tags;
