import { BaseModel, BaseCollection } from './base';

class TagModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      name: '',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  get routes() {
    return {
      all: '/tags',
    };
  }
}

class TagsCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return TagModel;
  }
}

export { TagModel, TagsCollection };
