import { CartModel } from '@models/cart';
import { OrderModel } from '@models/order';

import http from '@/utils/http';
import { updateCart } from './carts';


const addOrder = (order) => ({
  type: 'ADD_ORDER',
  order,
});

const updateOrder = (order) => ({
  type: 'UPDATE_ORDER',
  order,
});

const destroyOrder = (orderId) => ({
  type: 'DESTROY_ORDER',
  orderId,
});

const assignOrderToCarts = (cartsIds, order) => ({
  type: 'ASSIGN_ORDER_TO_CARTS',
  cartsIds,
  order,
});

const refreshOrderCartsStage = (orderId, stage) => ({
  type: 'REFRESH_ORDER_CARTS_STAGE',
  orderId,
  stage,
});

const createOrder = (cartsIds) => (dispatch) => http
  .post(OrderModel.routes.ordersPath, { order: { new_carts_ids: cartsIds } })
  .then((response) => {
    dispatch(addOrder(response.data));
    dispatch(assignOrderToCarts(cartsIds, response.data));

    window.flashMessages.addMessage({
      type: 'success',
      text: 'Order has been created.',
    });
  })
  .catch((error) => {
    window.flashMessages.addMessage({
      type: 'error',
      text: `Order cannot be created due to some errors: ${error.response.data.errors.full_messages.join('. ')}`,
    });
  });

const refreshOrder = (orderId) => (dispatch) => http
  .get(OrderModel.routes.orderPath(orderId))
  .then((response) => {
    dispatch(updateOrder(response.data));
  });

const addCartsToOrder = (orderId, cartsIds) => (dispatch) => http
  .put(OrderModel.routes.orderPath(orderId), { order: { new_carts_ids: cartsIds } })
  .then((response) => {
    dispatch(updateOrder(response.data));
    dispatch(assignOrderToCarts(cartsIds, response.data));

    window.flashMessages.addMessage({
      type: 'success',
      text: 'Carts have been added the order.',
    });
  })
  .catch((error) => {
    window.flashMessages.addMessage({
      type: 'error',
      text: `Carts cannot be added due to some errors: ${error.response.data.errors.full_messages.join('. ')}`,
    });
  });

const removeCartFromOrder = (orderId, cart) => (dispatch) => http
  .put(OrderModel.routes.orderPath(orderId), { order: { removable_cart_id: cart.id } })
  .then((response) => {
    dispatch(updateOrder(response.data));

    const removedCart = new CartModel({ ...cart, order_id: null, order_invoice_number: null });
    dispatch(updateCart(removedCart.attributes));

    window.flashMessages.addMessage({
      type: 'success',
      text: 'Cart have been removed from order.',
    });
  })
  .catch(() => {
    window.flashMessages.addMessage({
      type: 'error',
      text: 'Carts cannot be removed due to some errors.',
    });
  });

const generatePayment = (orderId) => (dispatch) => http
  .post(OrderModel.routes.orderPaymentsPath(orderId))
  .then((response) => {
    dispatch(refreshOrderCartsStage(response.data.id, 'contact'));
    dispatch(updateOrder(response.data));

    window.flashMessages.addMessage({
      type: 'success',
      text: 'Payment has been generated.',
    });
  })
  .catch((error) => {
    window.flashMessages.addMessage({
      type: 'error',
      text: `Payment cannot be generated due to some errors: ${error.response.data.errors.join('. ')}`,
    });
  });

const withdrawPayment = (orderId) => (dispatch) => http
  .post(OrderModel.routes.withdrawOrderPaymentsPath(orderId))
  .then((response) => {
    dispatch(refreshOrderCartsStage(response.data.id, 'processed'));
    dispatch(updateOrder(response.data));

    window.flashMessages.addMessage({
      type: 'success',
      text: 'Payment has been withdrawn.',
    });
  })
  .catch((error) => {
    window.flashMessages.addMessage({
      type: 'error',
      text: `Payment cannot be withdrawn due to some errors: ${error.response.data.errors.join('. ')}`,
    });
  });

const removeOrder = (orderId) => (dispatch) => http
  .delete(OrderModel.routes.orderPath(orderId))
  .then(() => {
    dispatch(destroyOrder(orderId));

    window.flashMessages.addMessage({
      type: 'success',
      text: 'Order has been removed.',
    });
  })
  .catch(() => {
    window.flashMessages.addMessage({
      type: 'error',
      text: 'Order cannot be removed due to some errors.',
    });
  });

export {
  createOrder,
  refreshOrder,
  addCartsToOrder,
  removeCartFromOrder,
  generatePayment,
  withdrawPayment,
  updateOrder,
  removeOrder,
};
