import React, { Component } from 'react';
import { connect } from 'react-redux';

import { IssuesCollection } from '@models/issue';
import { CartsCollection } from '@models/cart';

import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';
import FormDialog from '@components/shared/FormDialog';
import IssuesList from './IssuesList';
import IssueForm from './IssueForm';

class IssuesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormDialogOpen: false,
    };
  }

  handleOpenDialog = () => {
    this.setState({ isFormDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isFormDialogOpen: false });
  };

  render() {
    const { isFormDialogOpen } = this.state;
    const {
      currentCartId,
      userId,
      issues,
      options,
      carts,
    } = this.props;

    const currentCart = carts.models.filter((cart) => cart.id === currentCartId);

    return currentCartId ? (
      <div className="issues-container user-cart-section">
        <CustomExpansionPanel
          id="issues-panel"
          headerText="Issues"
        >
          <div className="panel__content w-100">
            <div className="d-flex justify-content-end p-2">
              {currentCartId && (
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={this.handleOpenDialog}
                >
                  New issue
                </button>
              )}
            </div>

            <IssuesList
              issues={issues}
              currentUserId={userId}
              currentCart={currentCart[0]}
              currentCartId={currentCartId}
              issueOptions={options}
            />
          </div>
        </CustomExpansionPanel>

        <FormDialog
          title="Create new issue"
          isOpen={isFormDialogOpen}
          onClose={this.handleCloseDialog}
          customClass="dialog__wide"
        >
          <IssueForm
            issueOptions={options}
            currentCartId={currentCartId}
            carts={carts}
            onCancel={this.handleCloseDialog}
          />
        </FormDialog>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  issues: new IssuesCollection(state.issues.cart_issues),
  carts: new CartsCollection(state.carts.all_carts),
  options: state.issues.issue_options,
});

export default connect(mapStateToProps)(IssuesContainer);
