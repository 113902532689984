import moment from 'moment';

import { BaseModel, BaseCollection } from './base';

class UserModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      first_name: '',
      last_name: '',
      email: '',
      email_verified: false,
      date_of_birth: '',
      gender: '',
      home_phone: '',
      mobile_phone: '',
      mobile_phone_verified: false,
      species: 'human',
      created_at: '',
      landing_page: '',
      landing_page_id: null,
      default_payment_profile_id: null,
      demographics_verified: '',
      price_id: '',
      poc_attributes: {
        name: '',
        relationship: '',
        mobile_phone: '',
        home_phone: '',
      },
    };
  }

  constructor(attributes = {}) {
    super(attributes);

    if (attributes.date_of_birth) {
      this.date_of_birth = moment(attributes.date_of_birth).format('L');
    }
  }

  get fullname() {
    return [this.first_name, this.last_name].join(' ');
  }

  get mobilePhone() {
    return (this.mobile_phone ? this.mobile_phone : '');
  }

  get attributes() {
    const { errors, ...attrs } = this;
    attrs.date_of_birth = moment.utc(this.date_of_birth, 'MM/DD/YYYY').format();

    return attrs;
  }

  fullNameWithPhoneVerification() {
    if (!this.mobile_phone_verified) {
      return `${this.fullname} (not verified)`;
    }
    return this.fullname;
  }

  fullNameWithEmailVerification() {
    if (!this.email_verified) {
      return `${this.fullname} (not verified)`;
    }
    return this.fullname;
  }

  pocName() {
    return `${this.poc_attributes.name} (POC)`;
  }

  get routes() {
    return {
      all: '/users',
      byId: `/users/${this.id}`,
      signInToken: `/admins/sign_in_token/${this.id}`,
      assignCreditCard: '/users/assign_credit_card',
      verifyByPlaid: '/users/verify_by_plaid',
    };
  }
}

class UsersCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return UserModel;
  }
}

export { UserModel, UsersCollection };
