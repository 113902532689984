import { combineReducers } from 'redux';

import user from './user';
import carts from './carts';
import orders from './orders';
import stage_changes from './stage_changes';
import notes from './notes';
import text_messages from './text_messages';
import doctors from './doctors';
import triggers from './triggers';
import issues from './issues';

const cartPageReducer = combineReducers({
  user,
  carts,
  orders,
  stage_changes,
  notes,
  text_messages,
  doctors,
  triggers,
  issues,
});

export default cartPageReducer;
