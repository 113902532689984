
import React from 'react';
import PropTypes from 'prop-types';

import { PharmaciesCollection, PharmacyModel } from '@models/pharmacy';

import PharmacyItem from './PharmacyItem';

const PharmaciesList = (props) => {
  const { pharmacyTemplates, currentPharmacy, updateCurrentPharmacy } = props;

  const currentPharmacyItem = (
    <PharmacyItem
      key={currentPharmacy.id}
      pharmacy={currentPharmacy}
      currentPharmacy={currentPharmacy}
      isCurrentPharmacy={true}
    />
  );

  const pharmaciesTemplatesList = pharmacyTemplates.models.map((pharmacy) => (
    <PharmacyItem
      key={pharmacy.id}
      pharmacy={pharmacy}
      currentPharmacy={currentPharmacy}
      isCurrentPharmacy={false}
      updateCurrentPharmacy={updateCurrentPharmacy}
    />
  ));

  return (
    <div>
      <table className="table table-hover table-striped">
        <thead className="thead-light">
          <tr>
            <th />
            <th>Pharmacy name</th>
            <th>Store number</th>
            <th>Phone number</th>
            <th>Street</th>
            <th>Suite</th>
            <th>City</th>
            <th>State</th>
            <th>ZIP</th>
            <th>Fax number</th>
            <th>Label</th>
          </tr>
        </thead>
        <tbody>
          {currentPharmacy.id && currentPharmacyItem}
          {pharmaciesTemplatesList}
        </tbody>
      </table>
    </div>
  );
};

PharmaciesList.propTypes = {
  pharmacyTemplates: PropTypes.instanceOf(PharmaciesCollection).isRequired,
  currentPharmacy: PropTypes.instanceOf(PharmacyModel).isRequired,
};

export default PharmaciesList;
