import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CartModel } from '@models/cart';
import { DoctorModel } from '@models/doctor';

import CustomInput from '@components/shared/panel/CustomInput';
import InlineEditButtons from '@components/shared/panel/InlineEditButtons';
import { assignDoctorToCart } from '@actions/panel/cart_view/carts';
import updateCurrentDoctor from '@actions/panel/cart_view/doctors';

import http from '@/utils/http';

class DoctorItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doctor: props.doctor,
      doctorCopy: props.doctor,
      activeEdit: false,
      processing: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (_.isEqual(prevProps.doctor, this.props.doctor)) return;
    this.setState({ doctor: this.props.doctor });
  }

  handleInputChange = ({ target: { name, value } }) => {
    // it fixes IMaskInput issue
    const { phone, fax, fax_from_user } = this.state.doctor;
    if (name === 'phone' && value === _.toString(phone)) return;
    if (name === 'fax' && value === _.toString(fax)) return;
    if (name === 'fax_from_user' && value === _.toString(fax_from_user)) return;

    this.setState((prevState) => {
      const doctor = new DoctorModel({ ...prevState.doctor });
      doctor.set(name, value.toUpperCase());

      return { doctor, activeEdit: true };
    });
  };

  handleCheckboxChange = ({ target: { name, checked } }) => {
    this.setState((prevState) => {
      const doctor = new DoctorModel({ ...prevState.doctor });
      doctor.set(name, checked);

      return { doctor, activeEdit: true };
    });
  }

  saveDoctor = () => {
    const { doctor } = this.state;

    this.setState({ processing: true });

    http.put(doctor.routes.instance, { doctor: doctor.attributes })
      .then((response) => {
        const newDoctor = new DoctorModel(response.data);
        this.setState({ doctor: newDoctor, doctorCopy: newDoctor, activeEdit: false });

        window.flashMessages.addMessage({
          type: 'success',
          text: 'Doctor has been updated.',
        });
      })
      .catch((error) => {
        const invalidDoctor = new DoctorModel(error.response.data);
        this.setState({ doctor: invalidDoctor });

        window.flashMessages.addMessage({
          type: 'error',
          text: 'Doctor cannot be updated due to some errors.',
        });
      })
      .finally(() => this.setState({ processing: false }));
  }

  handleCancelEdit = () => {
    this.setState((prevState) => ({ doctor: prevState.doctorCopy, activeEdit: false }));
  }

  handleDoctorChange = () => {
    const {
      currentDoctor, updateDoctor, currentCart, updateCart,
    } = this.props;
    const { doctor } = this.state;

    const newCurrentDoctor = new DoctorModel({
      ...doctor.attributes,
      id: currentDoctor.id,
      cart_id: currentCart.id,
      parent_id: doctor.id,
    });

    const httpMethod = newCurrentDoctor.id ? 'put' : 'post';

    http({
      method: httpMethod,
      url: newCurrentDoctor.routes.instance,
      doctor: newCurrentDoctor.attributes,
      data: { doctor: newCurrentDoctor.attributes },
    }).then((response) => {
      const savedCurrentDoctor = new DoctorModel(response.data);
      updateDoctor(savedCurrentDoctor);
      currentCart.doctor_id = savedCurrentDoctor.id;
      updateCart(currentCart);

      window.flashMessages.addMessage({
        type: 'success',
        text: 'Doctor has been updated.',
      });
    });
  }

  render() {
    const {
      doctor: {
        id,
        first_name,
        last_name,
        phone,
        phone_verified,
        fax,
        fax_from_user,
        fax_verified,
        street_1,
        street_2,
        city,
        state,
        zip,
        npi,
        type,
        errors,
      },
      activeEdit,
      processing,
    } = this.state;

    const { isCurrentDoctor, currentCart } = this.props;

    return (
      <tr className={`align-center doctor-item ${isCurrentDoctor ? 'table-success' : ''}`}>
        <td>
          {activeEdit && (
            <InlineEditButtons
              approveAction={this.saveDoctor}
              cancelAction={this.handleCancelEdit}
              processing={processing}
            />
          )}

          {!isCurrentDoctor && !activeEdit && currentCart.isPresent && (
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={this.handleDoctorChange}
            >
              Use
            </button>
          )}
        </td>
        <td>
          <CustomInput
            type="text"
            className={`input bg-white ${errors.first_name && 'is-invalid'}`}
            name="first_name"
            onChange={this.handleInputChange}
            value={first_name || ''}
            error={errors.first_name ? errors.first_name[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`input bg-white ${errors.last_name && 'is-invalid'}`}
            name="last_name"
            onChange={this.handleInputChange}
            value={last_name || ''}
            error={errors.last_name ? errors.last_name[0] : null}
          />
        </td>
        <td>
          <div className="with-confirmation">
            <CustomInput
              type="tel"
              className={`input bg-white ${errors.phone && 'is-invalid'}`}
              name="phone"
              onChange={this.handleInputChange}
              value={phone || ''}
              error={errors.phone ? errors.phone[0] : null}
            />
            <input
              type="checkbox"
              name="phone_verified"
              checked={phone_verified}
              onChange={this.handleCheckboxChange}
              id={`phone_verified_${id}`}
            />
            <label htmlFor={`phone_verified_${id}`} />
          </div>
        </td>
        <td>
          <div className="with-confirmation">
            <CustomInput
              type="tel"
              className={`input bg-white ${errors.fax && 'is-invalid'}`}
              name="fax"
              onChange={this.handleInputChange}

              value={fax || ''}
              error={errors.fax ? errors.fax[0] : null}
            />
            <input
              disabled={!fax}
              type="checkbox"
              name="fax_verified"
              checked={fax_verified}
              onChange={this.handleCheckboxChange}
              id={`fax_verified_${id}`}
            />
            <label htmlFor={`fax_verified_${id}`} />
          </div>
        </td>
        <td>
          <CustomInput
            type="tel"
            className={`input bg-white ${errors.fax_from_user && 'is-invalid'}`}
            name="fax_from_user"
            onChange={this.handleInputChange}

            value={fax_from_user || ''}
            error={errors.fax_from_user ? errors.fax_from_user[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className="input bg-white"
            name="street_1"
            value={street_1 || ''}
            onChange={this.handleInputChange}
            error={errors.street_1 ? errors.street_1[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className="input bg-white"
            name="street_2"
            value={street_2 || ''}
            onChange={this.handleInputChange}
            error={errors.street_2 ? errors.street_2[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className="input bg-white"
            name="city"
            value={city || ''}
            onChange={this.handleInputChange}
            error={errors.city ? errors.city[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className="input bg-white"
            name="state"
            value={state || ''}
            maxLength="2"
            onChange={this.handleInputChange}
            error={errors.state ? errors.state[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className="input bg-white"
            name="zip"
            value={zip || ''}
            onChange={this.handleInputChange}
            error={errors.zip ? errors.zip[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className="input bg-white"
            name="npi"
            value={npi || ''}
            onChange={this.handleInputChange}
            error={errors.npi ? errors.npi[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className="input bg-white"
            name="type"
            value={type || ''}
            onChange={this.handleInputChange}
            error={errors.type ? errors.type[0] : null}
          />
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCart: new CartModel(state.carts.current_cart),
});

const mapDispatchToProps = (dispatch) => ({
  updateCart: (cart) => dispatch(assignDoctorToCart(cart)),
  updateDoctor: (doctor) => dispatch(updateCurrentDoctor(doctor)),
});

DoctorItem.propTypes = {
  currentCart: PropTypes.instanceOf(CartModel).isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(DoctorItem);
