/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'panel' %> to the appropriate

import { } from 'jquery-ujs';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';

import '../vendor/modernizr-custom';
import 'bootstrap/dist/js/bootstrap';
import './sentry';

/* eslint-disable import/first, import/newline-after-import */

// Global Helpers
import * as globalHelpers from '@initializers/initializeHelpers';
window.globalHelpers = globalHelpers;

// Cart page
import initializeCartView from '@initializers/panel/initialize_cart_view';
window.initializeCartView = initializeCartView;

// Flash Messages
import initializeFlashMessages from '@initializers/initialize_flash_messages';
window.initializeFlashMessages = initializeFlashMessages;

// MarkdownEditors
import initializeMarkdownEditors from '@initializers/panel/initialize_markdown_editors';
window.initializeMarkdownEditors = initializeMarkdownEditors;

// Discount Template Form
import initializeDiscountTemplateForm from '@initializers/panel/initialize_discount_template_form';
window.initializeDiscountTemplateForm = initializeDiscountTemplateForm;

// Layout Behavior
import initializeLayoutBehavior from '@initializers/initialize_layout_behavior';
window.initializeLayoutBehavior = initializeLayoutBehavior;

// Views
import * as initializeViews from '@initializers/panel/initialize_views';
window.initializeViews = initializeViews.initializeViews;

window.initializeContactForm = initializeViews.initializeContactForm;


// Polyfills
import Polyfills from '../utils/polyfills';
Polyfills();

/* eslint-enable import/first, import/newline-after-import */
