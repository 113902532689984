import React from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const CustomExpansionPanel = (props) => (
  <ExpansionPanel elevation={0} defaultExpanded>
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
      id={props.id}
      classes={{ root: 'custom-header p-0' }}
    >
      <h5>{props.headerText}</h5>
    </ExpansionPanelSummary>

    <ExpansionPanelDetails classes={{ root: 'flex-column p-0' }}>{props.children}</ExpansionPanelDetails>
  </ExpansionPanel>
);

export default CustomExpansionPanel;
