const initialState = {
  current_cart: {},
  all_carts: [],
};

const cartsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CART': {
      const allCarts = state.all_carts.map((cart) => (
        cart.id === action.cart.id ? action.cart : cart
      ));

      return {
        ...state,
        current_cart: state.current_cart.id === action.cart.id ? action.cart : state.current_cart,
        all_carts: allCarts,
      };
    }
    case 'ASSIGN_ORDER_TO_CARTS': {
      const allCarts = state.all_carts.map((cart) => {
        if (!action.cartsIds.includes(cart.id)) return cart;

        return {
          ...cart,
          order_id: action.order.id,
          order_invoice_number: action.order.invoice_number,
        };
      });

      const { current_cart } = state;
      if (action.cartsIds.includes(current_cart.id)) {
        current_cart.order_id = action.order.id;
        current_cart.order_invoice_number = action.order.invoice_number;
      }

      return {
        ...state,
        current_cart,
        all_carts: allCarts,
      };
    }
    case 'REFRESH_ORDER_CARTS_STAGE': {
      const allCarts = state.all_carts.map((cart) => (
        cart.order_id === action.orderId ? { ...cart, stage: action.stage } : cart
      ));

      const currentCart = { ...state.current_cart };

      if (currentCart.order_id === action.orderId) {
        currentCart.stage = action.stage;
      }

      return {
        ...state,
        current_cart: currentCart,
        all_carts: allCarts,
      };
    }
    case 'ASSIGN_DOCTOR_TO_CURRENT_CART': {
      const current_cart = action.cart;

      return {
        ...state,
        current_cart,
      };
    }
    default:
      return state;
  }
};

export default cartsReducer;
