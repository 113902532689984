import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import { updateTextMessages } from '@actions/panel/cart_view/text_messages';
import { TextMessagesCollection } from '@models/text_message';

class CommunicationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textMessages: props.textMessages,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.textMessages.models.length !== prevProps.textMessages.models.length) {
      this.setState({ textMessages: this.props.textMessages });
    }
  }

  render() {
    const { textMessages } = this.state;

    const columns = [
      {
        Header: 'To',
        accessor: 'to_fullname',
      },
      {
        Header: 'Subject',
        accessor: 'subject',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Category',
        accessor: 'branch',
      },
      {
        Header: 'Date',
        accessor: 'send_date',
      },
      {
        Header: 'Time',
        accessor: 'send_hour',
      },
      {
        Header: 'Pharmacy number',
        accessor: 'from_phone_number',
      },
      {
        Header: 'End Pt',
        accessor: 'to_phone_number',
      },
      {
        Header: 'By',
        accessor: 'admin_fullname',
      },
    ];

    return (
      <ReactTable
        minRows={1}
        columns={columns}
        data={textMessages.models}
        defaultPageSize={10}
        pageSizeOptions={[10, 30, 50]}
        className="-striped -highlight"
      />
    );
  }
}

CommunicationList.propTypes = {
  textMessages: PropTypes.instanceOf(TextMessagesCollection).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateTextMessages: (textMessages) => dispatch(updateTextMessages(textMessages)),
});

const mapStateToProps = (state) => ({
  textMessages: new TextMessagesCollection(state.text_messages),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationList);
