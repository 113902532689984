import { BaseModel, BaseCollection } from './base';

class TextMessageTemplateModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      name: '',
      template_body: '',
      prepared_body: '',
      user_type: 'user',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  get routes() {
    return {
      instance: `/text_message_templates/${this.id}?user_type=${this.user_type}&user_id=${this.user_id}`,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  modelName() {
    return 'text message template';
  }
}

class TextMessageTemplatesCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return TextMessageTemplateModel;
  }
}

export { TextMessageTemplateModel, TextMessageTemplatesCollection };
