import moment from 'moment';

import { BaseModel } from './base';

export default class PharmacistDetailModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      cart_id: null,
      product_id: null,
      product_variant_id: null,
      quantity: '',
      transfer_status: 'open',
      refills: '',
      original_refills: '',
      total_fills: '',
      directions: '',
      rx: '',
      from_pharmacist: '',
      to_pharmacist_id: null,
      original_date_at: '',
      first_fill: '',
      last_fill: '',
      created_at: '',
      updated_at: '',
    };
  }

  dateAttributes = ['original_date_at', 'first_fill', 'last_fill'];

  constructor(attributes = {}) {
    super(attributes);

    this.dateAttributes.forEach((attr) => {
      if (attributes[attr]) {
        this[attr] = moment(attributes[attr]).format('L');
      }
    });
  }

  get routes() {
    return {
      instance: this.id ? `/pharmacist_details/${this.id}` : '/pharmacist_details',
    };
  }

  get attributes() {
    const { errors, ...attrs } = this;

    this.dateAttributes.forEach((attr) => {
      attrs[attr] = moment(this[attr], 'L').format('YYYY-MM-DD');
    });

    return attrs;
  }
}
