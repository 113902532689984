// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TagModel, TagsCollection } from '@models/tag';
import SaveIcon from '@material-ui/icons/Save';
import TextInput from '@/components/shared/TextInput';

import http from '@/utils/http';

class TagForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tag: props.tag,
      tagName: '',
      tags: this.props.tags,
    };
  }

  handleFormSubmit = (event) => {
    event.preventDefault();

    const tagUnique = this.state.tags.models.every((tag) => tag.name !== this.state.tagName);
    if (tagUnique) {
      this.createTag();
    } else {
      window.flashMessages.addMessage({ type: 'error', text: 'Tag exists' });
    }
  }

  createTag = () => {
    const { tag, tagName } = this.state;
    const newTag = new TagModel({ ...tag });
    newTag.set('name', tagName);

    http.post(tag.routes.all, { tag: newTag.attributes })
      .then((response) => {
        this.onSuccessSubmit(response);
      })
      .catch(() => {
        this.onError();
      });
  }

  onSuccessSubmit = (response) => {
    this.props.onSubmitForm(new TagModel(response.data));
    window.flashMessages.addMessage({ type: 'success', text: 'Tag has been created' });
  }

  onError = () => {
    window.flashMessages.addMessage({ type: 'error', text: 'Error on add tag - try again' });
  }

  handleInputChange = (event) => {
    this.setState({ tagName: event.target.value });
  };

  render() {
    return (
      <form
        onSubmit={this.handleFormSubmit}
        className="tag__form row mx-0"
      >
        <TextInput
          isRequired="required"
          value={this.state.tagName}
          onChange={this.handleInputChange}
          customClass="with-button"
        />
        <button
          type="submit"
          className="input-button btn btn-sm btn-primary"
        >
          <SaveIcon />
        </button>
      </form>
    );
  }
}

TagForm.propTypes = {
  tag: PropTypes.instanceOf(TagModel).isRequired,
  tags: PropTypes.instanceOf(TagsCollection),
};

export default TagForm;
