import IMask from 'imask';
import MarkdownIt from 'markdown-it';
import 'print-this';
import moment from 'moment';

import { IMaskDateConfig, IMasPhoneNumberConfig } from '@/utils/imask_config';
import { checkCasePresence, checkAlphanumericPresence } from '@/utils/validators';
import { setupRequestHeaders } from '../components/shared/PhotoUploadHelpers';
import http from '@/utils/http';

// Please keep names alphabetical order

const applyPhoneNumberMask = (selector = 'input.phone-mask') => {
  $(selector).map((_index, el) => {
    IMask(el, IMasPhoneNumberConfig);
  });
};

const applyDateTimeMask = (selector = 'input.date-time-mask') => {
  $(selector).map((_index, el) => {
    IMask(el, IMaskDateConfig);
  });

  $('form.with-date-time-mask').submit(() => {
    $(selector).map((_index, el) => {
      const formattedDate = moment(el.value, IMaskDateConfig.mask).toISOString();
      $(el).closest('.form-group').find("input[type='hidden']").val(formattedDate);
    });
  });
};

const applyDateMask = (selector = 'input.date-mask') => {
  $(selector).map((_index, el) => {
    IMask(el, IMaskDateConfig);
  });

  $('form.with-date-mask').submit(() => {
    $(selector).map((_index, el) => {
      const formattedDate = moment(el.value, IMaskDateConfig.mask).format('DD/MM/YYYY');
      $(el).closest('.form-group').find("input[type='hidden']").val(formattedDate);
    });
  });
};

const handleAccordion = () => {
  // Add Inactive Class To All Accordion Headers
  $('.accordion-header').toggleClass('inactive-header');

  // Open The First Accordion Section When Page Loads
  const accordionLandingPage = $('.accordion-landing-page');
  if (!accordionLandingPage) {
    $('.accordion-header').first().toggleClass('active-header').toggleClass('inactive-header');
    $('.accordion-content').first().slideDown().toggleClass('open-content');
    $('.accordion-content').first().parent().toggleClass('is-open');
  }

  // The Accordion Effect
  $('.accordion-header').click(function () {
    $('.accordion').removeClass('is-open');
    if ($(this).is('.inactive-header')) {
      $('.active-header').toggleClass('active-header inactive-header').next().slideToggle()
        .toggleClass('open-content');
      // $('.accordion').toggleClass('is-open');
      $(this).toggleClass('active-header inactive-header');
      $(this).next().slideToggle().toggleClass('open-content');
      $(this).parent().toggleClass('is-open');
    } else {
      $(this).toggleClass('active-header inactive-header');
      $(this).next().slideToggle().toggleClass('open-content');
    }
  });

  return false;
};

const handleCartsToggle = (e) => {
  $('.account-nav__item.active, .account-card__toggle').on('click', (e) => {
    e.preventDefault();
    if ($(e.target).children().hasClass('account-nav__image')) {
      $(e.target)
        .parents('.account-nav')
        .toggleClass('is-open');
      return;
    }
    $(e.target).parents('.account-card').toggleClass('is-open');
  });
};

const handleCheckoutOptionChoice = () => {
  const checkedOption = $('.checkout-option__input:checked');
  if (checkedOption) $('.checkout-option__target').attr('href', checkedOption.val());

  $('.checkout-option__input').on('checked change', (e) => {
    $('.checkout-option__target').attr('href', e.target.value);
  });
};

const handleMultipleDrugsChoice = () => {
  // Helper for checkboxes on recent_drug checkout step

  $('.checkout-option__input').on('change', (e) => {
    if ($(e.target).attr('id') === 'no_drug') {
      $('.checkout-option__input:not(#no_drug)').prop('checked', false);
      return;
    }
    $('input#no_drug').prop('checked', false);
  });
};

const handlePrintAction = (selector) => {
  $('button.print').on('click', () => {
    $(selector).printThis();
  });
};

const renderMarkdownedContent = (selector) => {
  const md = new MarkdownIt('commonmark');

  $(selector).map((_index, el) => {
    $(el).html(md.render($(el).text()));
  });
};

const setTimeZoneInput = (selector) => {
  const timeZone = window.Intl && window.Intl.DateTimeFormat().resolvedOptions().timeZone;
  $(selector).val(timeZone);
};

const resetSearch = (buttonSelector, inputSelector, formSelector) => {
  $(buttonSelector).click(() => {
    $(inputSelector).val('');
    $(formSelector).submit();
  });
};

const verifyEmailPresence = () => {
  $(".back-arrow").on("click", () => {
    $(".email-verifier-wrapper, .registration-form-wrapper, .back-arrow").toggleClass("d-none")
  });
};

const handlePasswordChecks = () => {
  $(".password-input").on("keyup", (e) => {
    const text = $(e.target).val();

    if (text.length >= 8) {
      $(".check-wrapper.length .check-icon").addClass("active");
    } else {
      $(".check-wrapper.length .check-icon").removeClass("active");
    }

    if (checkCasePresence(text)) {
      $(".check-wrapper.case .check-icon").addClass("active");
    } else {
      $(".check-wrapper.case .check-icon").removeClass("active");
    }
  })
}

const checkIfEmailExists = () => {
  const validEmailRegex = /^\w+([\.-]?\w+)+([\.+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const email = $(".email-verifier").val();

  if (email.match(validEmailRegex)) {
    $.ajax({
      url: "/users/check_email_exists",
      method: "POST",
      data: { email: email },
      success: (res) => {
        if (res.status == 200) {
          $(".email-verifier-wrapper, .registration-form-wrapper").toggleClass("d-none")
          $(".email-verifier-errors").text("");
        } else {
          $(".email-verifier-errors").html(res.message);
        }
      },
    })
  } else {
    $(".email-verifier-errors").text("Invalid email address.");
  }
}

const onNavBackBtnClick = () => {
  const emailVerifierWrapper = $(".email-verifier-wrapper");

  if (emailVerifierWrapper.length) {
    $("#navBarBackButton").on("click", (e) => {
      e.preventDefault();

      if (emailVerifierWrapper.hasClass("d-none")) {
        $(".email-verifier-wrapper, .registration-form-wrapper").toggleClass("d-none")
      } else {
        history.back();
      }
    })
  }
}

let allowUserFormSubmit = false;

const checkIfCanSubmitForm = (e) => {
  e.preventDefault();
  const checkBox = $("#user_terms_of_service");
  const userFormIds = ["#user_first_name", "#user_last_name", "#user_email", "#user_password"];
  const userFormErrorsIds = [".first-name-error", ".last-name-error", ".email-error", ".password-error"];
  const validEmailRegex = /^\w+([\.-]?\w+)+([\.+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const userEmail = $("#user_email").val();
  const checkWrapperLength = $(".check-wrapper.length .check-icon");
  const checkWrapperCase = $(".check-wrapper.case .check-icon");
  let userFormIdsValues = {};

  userFormIds.forEach((index) => {
    userFormIdsValues[index] = $(index).val().length;
  })

  if (
    checkBox.is(":checked") &&
    !Object.values(userFormIdsValues).some((val) => !val) &&
    userEmail.match(validEmailRegex) &&
    checkWrapperLength.hasClass("active") &&
    checkWrapperCase.hasClass("active")
  ) {
    $("#new_user").trigger("submit");
  } else {
    userFormIds.forEach((value, index) => {
      userFormIdsValues[value] ? $(userFormErrorsIds[index]).text("") : $(userFormErrorsIds[index]).text("Required");
    })

    if (!(userEmail.match(validEmailRegex)) && userEmail.length) {
      $(".email-error").text("Invalid Email");
    }

    if (!checkBox.is(":checked")) {
      $(".check-box-error").text("Please check the box to continue");
    }
    $(".submit-registration-form").attr('disabled', true);
    allowUserFormSubmit = true;
  }
}

const checkIfCanSubmitFacebookForm = (e) => {
  e.preventDefault();
  const checkBox = $("#user_terms_of_service");
  const userFormIds = ["#user_first_name", "#user_last_name"];
  const userFormErrorsIds = [".first-name-error", ".last-name-error"];
  let userFormIdsValues = {};
  userFormIds.forEach((index) => {
    userFormIdsValues[index] = $(index).val().length;
  })

  if (
    checkBox.is(":checked") &&
    !Object.values(userFormIdsValues).some((val) => !val)
  ) {
    $("#user-profile-form").trigger("submit");
  } else {
    userFormIds.forEach((value, index) => {
      userFormIdsValues[value] ? $(userFormErrorsIds[index]).text("") : $(userFormErrorsIds[index]).text("Required");
    })

    if (!checkBox.is(":checked")) {
      $(".check-box-error").text("Please check the box to continue");
    }
  }
}

const errorFieldsOnUserForm = (e) => {
  const checkBox = $("#user_terms_of_service");
  const userFormIds = ["#user_first_name", "#user_last_name", "#user_email", "#user_password"];
  const userFormErrorsIds = [".first-name-error", ".last-name-error", ".email-error", ".password-error"];
  const validEmailRegex = /^\w+([\.-]?\w+)+([\.+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const userEmail = $("#user_email").val();
  const checkWrapperLength = $(".check-wrapper.length .check-icon");
  const checkWrapperCase = $(".check-wrapper.case .check-icon");
  let userFormIdsValues = {};

  userFormIds.forEach((index) => {
    userFormIdsValues[index] = $(index).val().length
  })

  if (
    checkBox.is(":checked") &&
    !Object.values(userFormIdsValues).some((val) => !val) &&
    userEmail.match(validEmailRegex) &&
    checkWrapperLength.hasClass("active") &&
    checkWrapperCase.hasClass("active") &&
    allowUserFormSubmit
  ) {
    if (checkBox.is(":checked")) {
      $(".check-box-error").text("")
    }
    $(".submit-registration-form").attr('disabled', false);
  } else if (allowUserFormSubmit) {
    $(".submit-registration-form").attr('disabled', true);
  }

  if (allowUserFormSubmit) {
    userFormIds.forEach((value, index) => {
      userFormIdsValues[value] ? $(userFormErrorsIds[index]).text("") : $(userFormErrorsIds[index]).text("Required");
    })

    if (!(userEmail.match(validEmailRegex)) && userEmail.length) {
      $(userFormErrorsIds[2]).text("Invalid Email");
    }

    if (
      $(userFormIds[3]).val().length &&
      !(checkWrapperLength.hasClass("active") &&
        checkWrapperCase.hasClass("active"))
    ) {
      $(userFormErrorsIds[3]).text("Must meet the requirments below");
    }

    if (checkBox.is(":checked")) {
      $(".check-box-error").text("")
    } else {
      $(".check-box-error").text("Please check the box to continue");
    }
  }
}

const newChatMessageHandler = () => {
  if (($("#chat-messages-modal").data('bs.modal') || {})._isShown) {
    apiReqMessageRead();
  } else {
    $(".new-message-notification").removeClass("d-none");
  }
}

const onMessageRead = () => {
  $(".messages-icon").on("click", (e) => {
    apiReqMessageRead();
  })
};

const apiReqMessageRead = () => {
  const url = `${gon.base_uri}/api/users/${gon.pocketmed_uuid}/status/read_message`;

  http.post(url, {}, setupRequestHeaders(gon.api_key))
    .then((response) => {
      if (response.status === 200) {
        $(".new-message-notification").addClass("d-none")
      }
    }).catch((err) => {
      console.log(err.response.data.errors)
    });
}

const inputFieldErrors = () => {
  $(".new_user input").on("keyup", (e) => {
    $(e.target).addClass("input-field-border").addClass("input-field-border").parents('.input-group').siblings('.input-error').remove();
  })
}

const verifyFormField = () => {
  $(".new_user input").on("keyup", (e) => {
    $(e.target).parents('.input-group').siblings('.text-danger').text("");
    errorFieldsOnUserForm(e);
  })
}

const handlePriceToggles = () => {
  $(".pricing-toggles input").on('change', (e) => {
    const toShow = e.target.value;
    const toHide = toShow === 'viagra' ? 'cialis' : 'viagra';

    $(`.table-container.${toShow}`).removeClass('d-none');
    $(`.table-container.${toHide}`).addClass('d-none');
  })
}

export {
  applyPhoneNumberMask,
  applyDateTimeMask,
  applyDateMask,
  handleAccordion,
  handleCartsToggle,
  handleCheckoutOptionChoice,
  handleMultipleDrugsChoice,
  handlePrintAction,
  renderMarkdownedContent,
  setTimeZoneInput,
  resetSearch,
  verifyEmailPresence,
  handlePasswordChecks,
  checkIfEmailExists,
  onNavBackBtnClick,
  checkIfCanSubmitForm,
  checkIfCanSubmitFacebookForm,
  newChatMessageHandler,
  onMessageRead,
  inputFieldErrors,
  errorFieldsOnUserForm,
  verifyFormField,
  handlePriceToggles
};
