const initialState = [];

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_ORDER': {
      return [...state, action.order];
    }
    case 'UPDATE_ORDER': {
      return state.map((order) => (
        order.id === action.order.id ? action.order : order
      ));
    }
    case 'DESTROY_ORDER': {
      return state.filter((order) => order.id !== action.orderId);
    }
    default:
      return state;
  }
};

export default ordersReducer;
