import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UserModel } from '@models/user';
import { CartModel } from '@models/cart';
import { DoctorModel } from '@models/doctor';
import { FaxModel } from '@models/fax';
import { searchNotes } from '@actions/panel/cart_view/notes';
import { updateNotes } from '@/components/panel/notes/NoteFilterService';

import PhoneInput from '@/components/shared/PhoneInput';

import http from '@/utils/http';

class FaxForm extends Component {
  constructor(props) {
    super(props);

    const doctor = new DoctorModel(props.doctors.current_doctor);

    let fax;
    if (props.faxToResend.cart_id) {
      fax = _.cloneDeep(props.faxToResend);
      fax.id = null;
      fax.user_id = this.props.user.id;
    } else {
      fax = new FaxModel({
        cart_id: this.props.currentCart.id,
        user_id: this.props.user.id,
        user_fullname: this.props.user.fullname,
        doctor_fullname: doctor.fullname,
        doctor_fax: doctor.fax,
      });
    }

    const previewLoading = false;
    const allowSend = !!((doctor.id && fax.doctor_fax && this.props.currentCart.shipping_address_id));
    const missingShippingAddress = !(this.props.currentCart.shipping_address_id);

    this.state = {
      fax,
      doctor,
      previewLoading,
      allowSend,
      missingShippingAddress,
    };
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState((prevState) => {
      const fax = new FaxModel({ ...prevState.fax });
      fax.set(name, value);

      if (value === '' && !fax.doctor_fax) {
        this.setState({ allowSend: false });
      } else {
        this.setState({ allowSend: true });
      }

      return { fax };
    });
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
  }

  handleSendFax = () => {
    const { fax } = this.state;

    fax.id = null;

    if (fax.validFaxNumber()) {
      const { onSuccessSubmit } = this.props;

      http.post(fax.routes.all, { fax: fax.attributes })
        .then((response) => {
          const newFax = new FaxModel(response.data);
          updateNotes(this.props.user.id,
            this.props.currentCart.id,
            this.props.searchNotes);
          onSuccessSubmit(newFax);
          window.flashMessages.addMessage({
            type: 'success',
            text: fax.createSuccessMessage(),
          });
        }).catch(() => {
          window.flashMessages.addMessage({
            type: 'error',
            text: fax.createErrorMessage(),
          });
        });
    } else {
      const errorMessages = fax.errorMessages();
      this.setState({ faxErrors: [errorMessages.fax_number] });
    }
  }

  handlePreviewClick = (e) => {
    e.preventDefault();

    const { fax } = this.state;

    this.setState({ previewLoading: true });

    http.post(fax.routes.preview, { fax: fax.attributes }, { responseType: 'blob' })
      .then((response) => {
        this.setState({ previewLoading: false });
        this.pdfFile(response.data);
      }).catch(() => {
        window.flashMessages.addMessage({
          type: 'error',
          text: fax.previewErrorMessage(),
        });
      });
  }

  pdfFile = (pdfStream) => {
    const file = new Blob([pdfStream], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  render() {
    const {
      fax: {
        user_fullname,
        doctor_fullname,
        doctor_fax,
        fax_top_note,
        fax_note,
      }, faxErrors,
      doctor,
      previewLoading,
      allowSend,
      missingShippingAddress,
    } = this.state;

    const { onCancel } = this.props;

    return (
      <div className="fax__create">
        {
          !doctor.id && (<b className="text-danger">Doctor is not assigned. To send fax assign a doctor.</b>)
        }
        {
          missingShippingAddress && (<b className="text-danger">Missing shipping address.</b>)
        }
        {
          previewLoading && (<b>PREVIEW IS GENERATING</b>)
        }
        <form onSubmit={this.handleFormSubmit} className="fax__form">
          <div className="container px-0">
            <div className="row">
              <div className="col-12 px-0 form-group">
                <label htmlFor="patient_info" className="input-label">Patient info</label>
                <input
                  name="user_fullname"
                  required={true}
                  value={user_fullname}
                  onChange={this.handleChange}
                  className="input"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 px-0 form-group">
                <label htmlFor="prescriber_info" className="input-label">Prescriber info</label>
                <input
                  name="doctor_fullname"
                  required={true}
                  value={doctor_fullname}
                  onChange={this.handleChange}
                  className="input"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 px-0">
                <label htmlFor="prescriber_fax" className="input-label">Prescriber fax</label>
                <PhoneInput
                  name="doctor_fax"
                  type="text"
                  required={true}
                  value={doctor_fax}
                  onChange={this.handleChange}
                  errors={faxErrors}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 px-0 form-group">
                <label htmlFor="top_note" className="input-label">Note at Top of Fax</label>
                <input
                  name="fax_top_note"
                  value={fax_top_note}
                  onChange={this.handleChange}
                  className="input"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 px-0">
                <label htmlFor="note" className="input-label">Doctor Fax Note</label>
                <textarea
                  name="fax_note"
                  rows="10"
                  className="note__fax-note-input textarea_input"
                  required={true}
                  value={fax_note}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="mx-0 row justify-content-end">
              <button
                type="reset"
                onClick={onCancel}
                className="btn btn-outline-danger btn-sm mx-1"
              >
                Cancel
              </button>
              { doctor.id && (
                <button
                  onClick={this.handlePreviewClick}
                  disabled={!allowSend}
                  className="btn btn-sm btn-primary"
                >
                  Preview Fax
                </button>
              )}
              { doctor.id && (
                <button
                  type="submit"
                  disabled={!allowSend}
                  onClick={this.handleSendFax}
                  className="btn btn-sm btn-primary"
                >
                  Send Fax
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

FaxForm.propTypes = {
  onSuccessSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  searchNotes: (notes) => dispatch(searchNotes(notes)),
});

const mapStateToProps = (state) => ({
  currentCart: new CartModel(state.carts.current_cart),
  user: new UserModel(state.user),
  doctors: state.doctors,
});

export default connect(mapStateToProps, mapDispatchToProps)(FaxForm);
