import { BaseModel, BaseCollection } from './base';

class TextMessageModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      user_id: null,
      body: '',
      to_phone_number: '',
      to_fullname: '',
      admin_fullname: '',
      trigger_key: '',
      status: '',
      send_date: '',
      send_hour: '',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  get routes() {
    return {
      all: '/text_messages',
    };
  }

  get subject() {
    return _.startCase(this.trigger_key);
  }

  // eslint-disable-next-line class-methods-use-this
  modelName() {
    return 'text message';
  }

  createErrorMessage() {
    if (this.errors.cart.length > 0) {
      return 'Select a cart to send text message';
    }
    return `New ${this.modelName()} cannot be created due to some errors.`;
  }
}

class TextMessagesCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return TextMessageModel;
  }
}

export { TextMessageModel, TextMessagesCollection };
