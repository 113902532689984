import { BaseModel, BaseCollection } from './base';

class PharmacyModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      name: '',
      phone: '',
      street_1: '',
      street_2: '',
      city: '',
      state: '',
      zip: '',
      store_number: '',
      parent_id: null,
    };
  }

  get routes() {
    return {
      all: '/pharmacies',
      instance: this.id ? `/pharmacies/${this.id}` : '/pharmacies',
    };
  }
}

class PharmaciesCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return PharmacyModel;
  }
}

export { PharmacyModel, PharmaciesCollection };
