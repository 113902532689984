import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

import { connect } from 'react-redux';

import { NoteModel } from '@models/note';

import { updateNotes as updateNotesAction } from '@actions/panel/cart_view/notes';
import TextAreaInput from '@/components/shared/TextAreaInput';

import http from '@/utils/http';

class NoteForm extends Component {
  constructor(props) {
    super(props);

    const noteType = props.triggers.issueId ? 'issue' : 'note';
    const note = props.triggers.note ? props.triggers.note : new NoteModel({
      cart_id: props.cartId,
      issue_id: props.triggers.issueId,
      note_type: noteType,
    });

    this.state = {
      note,
    };
  }

  onSuccessSubmit(newNote) {
    const { updateNotes, onSuccessSubmitForm } = this.props;

    updateNotes(newNote);
    onSuccessSubmitForm();

    window.flashMessages.addMessage({
      type: 'success',
      text: 'Note has been created.',
    });
  }

  onSuccessUpdateSubmit(note) {
    const { updateNotes, onSuccessSubmitForm } = this.props;

    onSuccessSubmitForm();
    const updatedNote = note;
    updatedNote.updated = true;
    updateNotes(updatedNote);

    window.flashMessages.addMessage({
      type: 'success',
      text: 'Note has been updated.',
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();

    const { note } = this.state;
    if (note.id) {
      this.updateNote(note);
    } else {
      this.createNote(note);
    }
  };

  createNote = (note) => {
    if (note.cart_id) {
      http.post(note.routes.instance, { note: note.attributes })
        .then((response) => {
          const newNote = new NoteModel(response.data);

          this.onSuccessSubmit(newNote);
        })
        .catch((error) => {
          const invalidNote = new NoteModel(error.response.data);

          this.setState({ note: invalidNote });
        });
    } else {
      window.flashMessages.addMessage({
        type: 'error',
        text: 'Please select a cart to create new note.',
      });
    }
  };

  updateNote = (note) => {
    if (note.cart_id) {
      http.put(note.routes.instance, { id: note.id, note: note.attributes })
        .then((response) => {
          const newNote = new NoteModel(response.data);

          this.onSuccessUpdateSubmit(newNote);
        })
        .catch((error) => {
          const invalidNote = new NoteModel(error.response.data);

          this.setState({ note: invalidNote });
        });
    } else {
      window.flashMessages.addMessage({
        type: 'error',
        text: 'Please select a cart to update note.',
      });
    }
  };

  handleContentChange = ({ target: { name, value } }) => {
    this.setState((prevState) => {
      const note = new NoteModel({ ...prevState.note });
      note.set(name, value);

      return { note };
    });
  };

  render() {
    const {
      note: {
        content,
        errors,
      },
    } = this.state;

    return (
      <div className="dropdown-wrapper">
        <div
          onClick={this.props.onClose}
          className="note__toggle_add is-open"
        >
          <Icon>close</Icon>
        </div>
        <form
          onSubmit={this.handleFormSubmit}
          className="note__form dropdown"
        >
          <TextAreaInput
            value={content || ''}
            name="content"
            rows="8"
            className="note__form_input"
            onChange={this.handleContentChange}
            placeholder="Note content"
            errors={errors.content}
          />
          <div className="text-right">
            <button
              type="submit"
              className="btn btn-sm btn-primary"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}


NoteForm.propTypes = {
  onSuccessSubmitForm: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateNotes: (note) => dispatch(updateNotesAction(note)),
});

const mapStateToProps = (state) => ({
  triggers: state.triggers,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NoteForm);
