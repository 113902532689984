import React from 'react';

const TextAreaInput = (props) => {
  const {
    errors, label, icon, isRequired, disabled, placeholder, rows, cols, ...attrs
  } = props;

  const haveIcon = icon ? `input__icon ${icon}` : '';

  const textarea = (
    <div className="form-group">
      { !!label
      && (
      <label className="input-label" htmlFor={attrs.name}>
        {label}
      </label>
      )}
      <textarea
        rows={rows || 5}
        cols={cols || 6}
        required={isRequired}
        disabled={disabled}
        className={`textarea_input ${haveIcon}`}
        placeholder={placeholder}
        {...attrs}
      />
      {errors && errors.length > 0 && (
        <span className="input__error">{errors[0]}</span>
      )}
    </div>
  );

  return textarea;
};

export default TextAreaInput;
