import IMask from 'imask';

const IMaskDateConfig = {
  mask: 'MM/DD/YYYY',
  blocks: {
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1900,
      to: 2100,
      maxLength: 4,
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
    },
    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
    },
  },
};

const IMasPhoneNumberConfig = {
  mask: '(000) 000-0000',
};

export { IMaskDateConfig, IMasPhoneNumberConfig };
