const baseStages = ['open', 'request', 'received', 'processed', 'contact', 'pending', 'fulfillment', 'shipping',
  'completed', 'lost'];

export const cartStagesByBranch = {
  root: ['open', 'request', 'lost'],
  mailed: baseStages,
  transfer: baseStages,
  patient_request: baseStages,
  pharmacy_request: baseStages,
  no_doctor: ['open', 'lost'],
  telemedicine: baseStages,
  refill_request: baseStages,
};

export const allowedStagesMap = (currentStage, previousStage = null) => {
  let stagesList;

  switch (currentStage) {
    case 'open':
      stagesList = ['open', 'request'];
      break;
    case 'request':
      stagesList = ['open', 'request', 'received'];
      break;
    case 'received':
      stagesList = ['open', 'request', 'received', 'processed'];
      break;
    case 'processed':
      stagesList = ['open', 'request', 'received', 'processed', 'contact'];
      break;
    case 'contact':
    case 'pending':
      stagesList = ['contact', 'pending', 'fulfillment'];
      break;
    case 'fulfillment':
      stagesList = ['fulfillment', 'shipping'];
      break;
    case 'shipping':
      stagesList = ['fulfillment', 'shipping', 'completed'];
      break;
    case 'completed':
      stagesList = ['completed'];
      break;
    case 'lost':
      stagesList = [previousStage];
      break;
    default:
      stagesList = [];
  }

  return [...stagesList, 'lost'];
};

export const resolveRequestStageLabel = (stageName, branch) => {
  if (stageName !== 'request') return _.capitalize(stageName);

  switch (branch) {
    case 'mailed':
      return 'Mailed';
    case 'transfer':
      return 'Transfer';
    default:
      return 'Request';
  }
};
