import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NoteModel } from '@models/note';
import { connect } from 'react-redux';
import { deleteNote as deleteNoteAction, editNote as editNoteAction } from '@actions/panel/cart_view/notes';

import { showNoteForm } from '@actions/panel/cart_view/triggers';
import Tags from '@components/panel/users/Tags';
import http from '@/utils/http';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

class NoteItem extends Component {
  deleteNote = () => {
    const { note } = this.props;
    http.delete(note.routes.instance, { id: note.id })
      .then(() => {
        const { deleteNote } = this.props;
        deleteNote(note);

        window.flashMessages.addMessage({
          type: 'success',
          text: 'Note has been removed.',
        });
      })
      .catch(() => {
        window.flashMessages.addMessage({
          type: 'error',
          text: 'Note cannot be removed due to some errors.',
        });
      });
  }

  editNote = () => {
    const { note } = this.props;
    this.props.showNoteForm(null, note);
  }

  render() {
    const {
      note: {
        id,
        content,
        note_type,
        admin_fullname,
        tags,
        cart_id,
        created_at,
      },
    } = this.props;

    return (
      <div className="note">
        <span className="note__admin_fullname">{admin_fullname || 'AUM Robot'} </span>
        <span className="note__cart_id">Cart: {cart_id}</span>
        <div className="row align-items-baseline">
          <span className="note__date">{created_at}</span>
          <span className="note__note_type note__note_type-note">{note_type}</span>
        </div>
        <div className="note_buttons">
          <a href="#"><EditIcon onClick={this.editNote} fontSize="small" /></a>
          <a href="#"><DeleteIcon onClick={this.deleteNote} fontSize="small" /></a>
        </div>
        <p key={`note-${id}`} className="note__content">{content}</p>
        <Tags tags={tags} taggableModel="note" taggableModelId={id} />
      </div>
    );
  }
}

NoteItem.propTypes = {
  note: PropTypes.instanceOf(NoteModel).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  deleteNote: (note) => dispatch(deleteNoteAction(note)),
  editNote: (note) => dispatch(editNoteAction(note)),
  showNoteForm: (issueId, note) => dispatch(showNoteForm(issueId, note)),
});

export default connect(null, mapDispatchToProps)(NoteItem);
