import { BaseModel, BaseCollection } from './base';

class NoteModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      content: '',
      note_type: '',
      admin_fullname: '',
      cart_id: '',
      updated_at: '',
      updated: false,
    };
  }

  get routes() {
    return {
      instance: this.id ? `/notes/${this.id}` : '/notes',
      all: '/notes',
    };
  }

  searchQuery = (searchParams) => {
    const {
      currentUserId, cartId, keyword, noteType, issueId,
    } = searchParams;

    let query = `?user_id=${currentUserId}`;

    if (cartId) {
      query += `&cart_id=${cartId}`;
    }
    if (keyword) {
      query += `&keyword=${keyword}`;
    }

    if (noteType && noteType !== 'all') {
      query += `&note_type=${noteType}`;
    }

    if (issueId) {
      query += `&issue_id=${issueId}`;
    }

    return query;
  }
}

class NotesCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return NoteModel;
  }
}

export { NoteModel, NotesCollection };
