import React, { Component } from 'react';
import { connect } from 'react-redux';

import { OrdersCollection } from '@models/order';

import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';
import OrdersList from './OrdersList';

class OrdersContainer extends Component {
  render() {
    const { orders, deliveryOptions, sectionHeader } = this.props;

    return (
      <div className="orders-history-container user-cart-section">
        <div className="mb-1">
          <CustomExpansionPanel
            id="orders-history-panel"
            headerText={sectionHeader}
          >
            <OrdersList
              orders={orders}
              deliveryOptions={deliveryOptions}
            />
          </CustomExpansionPanel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, localProps) => ({
  orders: new OrdersCollection(state.orders).findByGroup(localProps.ordersGroup),
});

export default connect(mapStateToProps)(OrdersContainer);
