const initialState = {
  doctors: [],
};

const doctorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CURRENT_DOCTOR': {
      const { doctor_templates } = state;

      return {
        ...state,
        doctor_templates,
        current_doctor: action.doctor,
      };
    }
    default:
      return state;
  }
};

export default doctorsReducer;
