import React, { useState, useRef, useEffect } from 'react';

import PhoneInput from '@components/shared/PhoneInput';

const CustomInput = (props) => {
  const {
    error,
    value,
    type,
    ...attrs
  } = props;
  const inputEl = useRef(null);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (inputEl.current) inputEl.current.querySelector('input').focus();
  }, [focused]);

  const handleBlur = () => {
    if (error) return;
    setFocused(false);
  };

  const errorPrepend = error ? (
    <div className="input-prepend custom-error">
      <span className="input-group-text">!</span>
    </div>
  ) : '';

  return (
    <>
      <span
        hidden={focused ? 'hidden' : ''}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className={`input-replacement ${props.customspanclass} ${props.error ? 'error' : ''}`}
        onFocus={() => setFocused(true)}
      >
        {props.value}
      </span>
      <div
        ref={inputEl}
        className="input-group"
        hidden={focused || props.error ? '' : 'hidden'}
      >
        {errorPrepend}
        {type !== 'tel' ? (
          <input
            onBlur={handleBlur}
            value={value}
            type={type}
            {...attrs}
          />
        ) : (
          <PhoneInput
            onBlur={handleBlur}
            value={value}
            type={type}
            {...props}
          />
        )}
        <div className="invalid-tooltip">{props.error}</div>
      </div>
    </>
  );
};

export default CustomInput;
