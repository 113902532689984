import React, { Component } from "react";
import { connect } from "react-redux";

import { CartModel, CartsCollection } from "@models/cart";
import { UserModel } from "@models/user";

import Tags from "@components/panel/users/Tags";
import CartSelect from "./CartSelect";
import ContactHeader from "./ContactHeader";

class ContactOverview extends Component {
  render() {
    const { currentCart, allCarts, user, tags, prices } = this.props;

    return (
      <div>
        <div className="row">
          <ContactHeader
            user={user}
            currentCart={currentCart}
            prices={prices}
          />
        </div>

        <div className="row align-items-center order-select">
          <strong>Open Cart: </strong>
          <CartSelect
            allCarts={allCarts}
            currentCartId={currentCart.id}
            user={user}
          />
          <Tags tags={tags} taggableModel="user" taggableModelId={user.id} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: new UserModel(state.user),
  currentCart: new CartModel(state.carts.current_cart),
  allCarts: new CartsCollection(state.carts.all_carts),
});

export default connect(mapStateToProps)(ContactOverview);
