import React from 'react';
import PropTypes from 'prop-types';

import { TagsCollection } from '@models/tag';
import TagItem from './TagItem';

const TagsList = (props) => {
  const { tags } = props;

  const tagList = tags.models.map((tag) => (
    <TagItem key={`tag-${tag.name}`} tag={tag} />
  ));

  return (
    <>
      {tagList}
    </>
  );
};

TagsList.propTypes = {
  tags: PropTypes.instanceOf(TagsCollection).isRequired,
};

export default TagsList;
