import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { DoctorsCollection } from '@models/doctor';

import DoctorItem from './DoctorItem';

const DoctorsList = (props) => {
  const {
    doctorTemplates,
    updateCurrentDoctor,
    currentDoctor,
  } = props;


  return (
    <div className="table-responsive">
      <table className="table table-hover table-striped">
        <thead className="thead-light">
          <tr>
            <th />
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone</th>
            <th>Fax</th>
            <th>Pt Provided Fax</th>
            <th>Street</th>
            <th>Unit/Suite</th>
            <th>City</th>
            <th>State</th>
            <th>ZIP</th>
            <th>NPI</th>
            <th>Doctor Type</th>
          </tr>
        </thead>
        <tbody>
          {currentDoctor.id && (
            <DoctorItem
              key={currentDoctor.id}
              doctor={currentDoctor}
              currentDoctor={currentDoctor}
              isCurrentDoctor={true}
            />
          )}

          {doctorTemplates.models.map((doctor) => (
            <DoctorItem
              key={doctor.id}
              doctor={doctor}
              currentDoctor={currentDoctor}
              isCurrentDoctor={false}
              updateCurrentDoctor={updateCurrentDoctor}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

DoctorsList.propTypes = {
  doctorTemplates: PropTypes.instanceOf(DoctorsCollection).isRequired,
};

const mapStateToProps = (state) => ({
  currentDoctor: state.doctors.current_doctor,
});

export default connect(mapStateToProps)(DoctorsList);
