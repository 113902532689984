import React, { Component } from "react";
import { connect } from "react-redux";

import updateUserAction from "@actions/panel/cart_view/users";
import { UserModel } from "@models/user";
import DateInput from "@components/shared/DateInput";
import PhoneInput from "@components/shared/PhoneInput";
import TextInput from "@/components/shared/TextInput";

import http from "@/utils/http";
import ButtonSpinner from "./ButtonSpinner";

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: new UserModel({ ...props.user }),
      processing: false,
      edited: false,
      disableButton: !this.props.user.can_be_plaid_verified,
      processingPlaid: false,
    };
  }

  handleInputChange = ({ target }) => {
    this.setState((prevState) => {
      const { user } = prevState;
      user.set(
        target.name,
        target.type === "checkbox" ? target.checked : target.value
      );

      return { user, edited: true };
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    const {
      props: { updateUser },
      state: { user },
    } = this;

    this.setState({ processing: true });

    http
      .put(user.routes.byId, { user: user.attributes })
      .then((response) => {
        updateUser(response.data);

        this.setState({
          user: new UserModel(response.data),
          edited: false,
        });

        window.flashMessages.addMessage({
          type: "success",
          text: "Contact has been updated successfully.",
        });
      })
      .catch((error) => {
        this.setState({ user: new UserModel(error.response.data) });

        window.flashMessages.addMessage({
          type: "error",
          text: "User cannot be update due to some errors.",
        });
      })
      .finally(() => this.setState({ processing: false }));
  };

  handleUserVerification = () => {
    const {
      props: { updateUser },
      state: { user },
    } = this;

    if (user.otp_verified) {
      this.setState({ processingPlaid: true });

      http
        .put(user.routes.verifyByPlaid, {
          user: {
            id: user.id,
          },
        })
        .then((res) => {
          const newUserData = {
            ...this.state.user,
            demographics_verified: res.data.status === "success",
          };

          updateUser(newUserData);
          this.setState({
            user: new UserModel(newUserData),
          });

          if (res.data.status === "success") {
            window.flashMessages.addMessage({
              type: "success",
              text: "User successfully Plaid Verified",
            });
          } else {
            window.flashMessages.addMessage({
              type: "alert",
              text: "User Plaid Verification failed",
            });
          }
        })
        .catch((err) => {
          console.log(err);

          window.flashMessages.addMessage({
            type: "alert",
            text: "Unable to request Plaid Verification for User",
          });
        })
        .finally(() => {
          this.setState({ processingPlaid: false });
        });
    } else {
      window.flashMessages.addMessage({
        type: "error",
        text: "User is not OTP verified.",
      });
    }
  };

  handleOtpVerification = (e) => {
    const {
      props: { updateUser },
      state: { user },
    } = this;

    const otpValue = e.target.checked;

    http
      .put(user.routes.byId, {
        user: {
          otp_verified: otpValue,
        },
      })
      .then((response) => {
        updateUser(response.data);

        user.set("otp_verified", otpValue);

        this.setState({
          user: new UserModel(response.data),
        });

        window.flashMessages.addMessage({
          type: "success",
          text: "User updated successfully",
        });
      })
      .catch((err) => {
        console.log(err);

        window.flashMessages.addMessage({
          type: "alert",
          text: "Unable to verify otp",
        });
      });
  };

  render() {
    const {
      first_name,
      last_name,
      date_of_birth,
      mobile_phone,
      mobile_phone_verified,
      home_phone,
      email,
      email_verified,
      gender,
      species,
      pioneer_id,
      errors,
      otp_verified,
      poc_attributes: {
        name: poc_name,
        relationship: poc_relationship,
        mobile_phone: poc_mobile_phone,
        home_phone: poc_home_phone,
      },
      errors: {
        first_name: first_name_errors,
        last_name: last_name_errors,
        date_of_birth: date_of_birth_errors,
        email: email_errors,
        gender: gender_errors,
        mobile_phone: mobile_phone_errors,
        home_phone: home_phone_errors,
      },
    } = this.state.user;

    const { processing, edited } = this.state;

    const { isSuperAdmin } = this.props;
    return (
      <form
        className="contact-form"
        onSubmit={this.handleFormSubmit}
        noValidate>
        <div className="row flex-wrap align-items-baseline">
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="first_name">First Name</label>
              <TextInput
                type="text"
                className={`input ${first_name_errors && "is-invalid"}`}
                name="first_name"
                value={first_name || ""}
                onChange={this.handleInputChange}
                errors={errors.first_name}
                required
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="last_name">Last Name</label>
              <TextInput
                type="text"
                className={`input ${last_name_errors && "is-invalid"}`}
                name="last_name"
                value={last_name || ""}
                onChange={this.handleInputChange}
                errors={errors.last_name}
                required
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="date_of_birth">DOB</label>
              <DateInput
                className={`input ${date_of_birth_errors && "is-invalid"}`}
                name="date_of_birth"
                value={date_of_birth || ""}
                onChange={this.handleInputChange}
                errors={errors.date_of_birth}
                required
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="mobile_phone">Mobile</label>
              <div className="with-confirmation">
                <PhoneInput
                  type="tel"
                  className={`input ${mobile_phone_errors && "is-invalid"}`}
                  name="mobile_phone"
                  value={mobile_phone || ""}
                  onChange={this.handleInputChange}
                  errors={errors.mobile_phone}
                  required
                />
                <input
                  type="checkbox"
                  id="mobile_phone_verified"
                  name="otp_verified"
                  checked={otp_verified}
                  onChange={this.handleOtpVerification}
                />
                <label htmlFor="mobile_phone_verified" className="lg" />
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="home_phone">Phone</label>
              <PhoneInput
                type="tel"
                className={`input ${home_phone_errors && "is-invalid"}`}
                name="home_phone"
                value={home_phone || ""}
                onChange={this.handleInputChange}
                errors={errors.home_phone}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <div className="with-confirmation">
                <input
                  type="email"
                  className={`input ${email_errors && "is-invalid"}`}
                  name="email"
                  value={email || ""}
                  onChange={this.handleInputChange}
                />
                <input
                  type="checkbox"
                  id="email_verified"
                  name="email_verified"
                  defaultChecked={email_verified}
                  onChange={this.handleInputChange}
                />
                <label htmlFor="email_verified" className="lg" />
              </div>
              {email_errors && (
                <div className="invalid-feedback">{email_errors[0]}</div>
              )}
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="gender">Gender</label>
              <select
                className={`input input-select bg-contain ${
                  gender_errors && "is-invalid"
                }`}
                name="gender"
                value={gender || ""}
                onChange={this.handleInputChange}>
                <option value="" />
                <option value="male">male</option>
                <option value="female">female</option>
              </select>
              {gender_errors && (
                <div className="invalid-feedback">{gender_errors[0]}</div>
              )}
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="species">Species</label>
              <select
                className="input input-select bg-contain "
                name="species"
                value={species || ""}
                onChange={this.handleInputChange}>
                <option value="human">Human</option>
                <option value="animal">Animal</option>
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="poc_name">Point of Contact Name</label>
              <input
                type="text"
                className="input "
                name="poc_attributes[name]"
                value={poc_name || ""}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="poc_relationship">
                Point of Contact Relationship
              </label>
              <input
                type="text"
                className="input "
                name="poc_attributes[relationship]"
                value={poc_relationship || ""}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="poc_mobile_phone">
                Point of Contact Number - Mobile
              </label>
              <PhoneInput
                type="tel"
                className="input "
                name="poc_attributes[mobile_phone]"
                value={poc_mobile_phone || ""}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="poc_home_phone">
                Point of Contact Number - Home
              </label>
              <PhoneInput
                type="tel"
                className="input "
                name="poc_attributes[home_phone]"
                value={poc_home_phone || ""}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="first_name">Pioneer Id</label>
              <TextInput
                type="text"
                className={"input"}
                name="pioneer_id"
                value={pioneer_id || ""}
                readOnly={true}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-between pr-3 pl-3">
          {isSuperAdmin && (
            <div className="row flex-row pr-3 pl-3">
              <button
                type="button"
                className="btn btn-sm btn-success"
                disabled={
                  this.state.disableButton || this.state.processingPlaid
                }
                onClick={this.handleUserVerification}>
                {this.state.processingPlaid ? <ButtonSpinner /> : "Verify User"}
              </button>
            </div>
          )}

          <button
            type="submit"
            className="btn btn-sm btn-primary"
            disabled={!edited || processing}>
            {processing ? "Processing..." : "Save"}
          </button>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUserAction(user)),
});

export default connect(null, mapDispatchToProps)(ContactForm);
