import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CartModel } from '@models/cart';
import { AddressModel } from '@models/address';

import { updateCart as updateCartAction } from '@actions/panel/cart_view/carts';

import CustomInput from '@components/shared/panel/CustomInput';
import InlineEditButtons from '@components/shared/panel/InlineEditButtons';

import http from '@/utils/http';

class AddressItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: props.address,
      addressCopy: props.address,
      activeEdit: false,
      processing: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (_.isEqual(prevProps.address, this.props.address)) return;
    this.setState({ address: this.props.address });
  }

  handleInputChange = ({ target: { name, value } }) => {
    this.setState((prevState) => {
      const address = new AddressModel({ ...prevState.address });

      if (name === 'state') {
        address.set('state', value.toUpperCase());
      } else {
        address.set(name, value);
      }

      return { address, activeEdit: true };
    });
  };

  handleCheckboxChange = ({ target: { name, checked } }) => {
    this.setState((prevState) => {
      const address = new AddressModel({ ...prevState.address });
      address.set(name, checked);

      return { address, activeEdit: true };
    });
  };

  updateAddress = () => {
    const { address } = this.state;

    this.setState({ processing: true });

    http.put(AddressModel.routes.addressPath(address.id), { shipping_address: address.attributes })
      .then((response) => {
        const newAddress = new AddressModel(response.data);
        this.setState({ address: newAddress, addressCopy: newAddress, activeEdit: false });

        window.flashMessages.addMessage({ type: 'success', text: 'Address has been updated.' });
      })
      .catch((error) => {
        const invalidAddress = new AddressModel(error.response.data);
        this.setState({ address: invalidAddress });

        window.flashMessages.addMessage({ type: 'error', text: 'Address cannot be updated due to some errors.' });
      })
      .finally(() => this.setState({ processing: false }));
  }

  handleCancelEdit = () => {
    this.setState((prevState) => ({ address: prevState.addressCopy, activeEdit: false }));
  }

  handleAddressChange = () => {
    this.props.currentAddress.id ? this.assignAddress() : this.assignAddressTemplate();
  }

  assignAddressTemplate = () => {
    const { updateCart, currentCart } = this.props;
    const { address } = this.state;

    http.put(currentCart.routes.byId, { cart: { shipping_address_id: address.id } })
      .then((response) => {
        updateCart(response.data);

        window.flashMessages.addMessage({
          type: 'success',
          text: 'Cart has been updated.',
        });
      })
      .catch(() => {
        window.flashMessages.addMessage({
          type: 'error',
          text: 'Cart cannot be updated due to some errors.',
        });
      });
  }

  assignAddress = () => {
    const { currentCart, updateCurrentAddress } = this.props;
    const { address } = this.state;

    http.put(
      AddressModel.routes.addressPath(currentCart.shipping_address_id),
      { shipping_address: address.attributes },
    )
      .then((response) => {
        const newCurrentAddress = new AddressModel(response.data);

        updateCurrentAddress(newCurrentAddress);

        window.flashMessages.addMessage({
          type: 'success',
          text: 'Address has been updated.',
        });
      });
  }

  render() {
    const {
      address: {
        id,
        street_1,
        street_2,
        city,
        state,
        zip,
        type,
        verified,
        company,
        first_name,
        last_name,
        is_valid,
        errors,
      },
      activeEdit,
      processing,
    } = this.state;

    const { currentCart } = this.props;

    const isAddressSelected = id === currentCart.shipping_address_id;

    return (
      <tr className={`align-center ${isAddressSelected ? 'table-success' : ''}`}>
        <td>
          {activeEdit && (
            <InlineEditButtons
              approveAction={this.updateAddress}
              cancelAction={this.handleCancelEdit}
              processing={processing}
            />
          )}

          {!isAddressSelected && !activeEdit && currentCart.isPresent && (
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={this.handleAddressChange}
              disabled={!is_valid}
            >
              Use
            </button>
          )}
        </td>
        <td>
          <CustomInput
            type="text"
            className={`form-control form-control-sm ${errors.street_1 && 'is-invalid'}`}
            name="street_1"
            onChange={this.handleInputChange}
            value={street_1 || ''}
            error={errors.street_1 ? errors.street_1[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`form-control form-control-sm ${errors.street_2 && 'is-invalid'}`}
            name="street_2"
            onChange={this.handleInputChange}
            value={street_2 || ''}
            error={errors.street_2 ? errors.street_2[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`form-control form-control-sm ${errors.city && 'is-invalid'}`}
            name="city"
            onChange={this.handleInputChange}
            value={city || ''}
            error={errors.city ? errors.city[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`form-control form-control-sm ${errors.state && 'is-invalid'}`}
            name="state"
            maxLength="2"
            onChange={this.handleInputChange}
            value={state || ''}
            error={errors.state ? errors.state[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`form-control form-control-sm ${errors.zip && 'is-invalid'}`}
            name="zip"
            onChange={this.handleInputChange}
            value={zip || ''}
            error={errors.zip ? errors.zip[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`form-control form-control-sm ${errors.type && 'is-invalid'}`}
            name="type"
            onChange={this.handleInputChange}
            value={type || ''}
            error={errors.type ? errors.type[0] : null}
          />
        </td>
        <td>
          <input
            type="checkbox"
            name="verified"
            id={`${id}-verified`}
            onChange={this.handleCheckboxChange}
            checked={verified}
          />
          <label htmlFor={`${id}-verified`} />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`form-control form-control-sm ${errors.company && 'is-invalid'}`}
            name="company"
            onChange={this.handleInputChange}
            value={company || ''}
            error={errors.company ? errors.company[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`form-control form-control-sm ${errors.first_name && 'is-invalid'}`}
            name="first_name"
            onChange={this.handleInputChange}
            value={first_name || ''}
            error={errors.first_name ? errors.first_name[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`form-control form-control-sm ${errors.last_name && 'is-invalid'}`}
            name="last_name"
            onChange={this.handleInputChange}
            value={last_name || ''}
            error={errors.last_name ? errors.last_name[0] : null}
          />
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCart: new CartModel(state.carts.current_cart),
});

const mapDispatchToProps = (dispatch) => ({
  updateCart: (cart) => dispatch(updateCartAction(cart)),
});

AddressItem.propTypes = {
  currentCart: PropTypes.instanceOf(CartModel).isRequired,
  address: PropTypes.instanceOf(AddressModel).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressItem);
