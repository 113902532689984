import React, { Component } from 'react';

import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';

import StageChangesList from './StageChangesList';

class StageChangesContainer extends Component {
  render() {
    const { currentCartId } = this.props;

    return currentCartId ? (
      <div className="stage-changes-container user-cart-section">
        <CustomExpansionPanel
          id="stage-changes-panel"
          headerText="Stage history"
        >
          <StageChangesList />
        </CustomExpansionPanel>
      </div>
    ) : null;
  }
}

export default StageChangesContainer;
