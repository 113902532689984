import React from 'react';
import { IMaskInput } from 'react-imask';

import { IMasPhoneNumberConfig } from '@/utils/imask_config';

const PhoneInput = (props) => {
  const {
    errors,
    label,
    icon,
    customClass,
    onChange,
    name,
    ...attrs
  } = props;

  const haveIcon = icon ? 'input with-icon' : 'input';

  return (
    <div className={`form-group ${customClass}`}>
      {label
        ? (
          <label className="input-label" htmlFor={attrs.id}>
            {label}
          </label>
        ) : null}
      <div className={`input-group row mx-0 ${errors ? 'field_with_errors' : ''}`}>
        <IMaskInput
          className={haveIcon}
          mask={IMasPhoneNumberConfig.mask}
          onAccept={(value) => onChange({ target: { value, name } })}
          {...attrs}
        />
        { icon ? <span className={`input__icon ${icon}`} /> : null }
      </div>

      {errors && errors.length > 0 && (
        <span className="input__error">{errors[0]}</span>
      )}
    </div>
  );
};

export default PhoneInput;
