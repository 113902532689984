
import React from 'react';
import PropTypes from 'prop-types';

import { AddressesCollection } from '@models/address';

import AddressItem from './AddressItem';

const AddressesList = (props) => {
  const {
    addressTemplates,
    currentAddress,
    updateCurrentAddress,
  } = props;

  const addressesList = addressTemplates.models.map((address) => (
    <AddressItem
      key={address.id}
      address={address}
      currentAddress={currentAddress}
      updateCurrentAddress={updateCurrentAddress}
    />
  ));

  return (
    <div>
      <table className="table table-hover table-striped">
        <thead className="thead-light">
          <tr>
            <th />
            <th>Street</th>
            <th>Unit / Suite</th>
            <th>City</th>
            <th>State</th>
            <th>ZIP</th>
            <th>Address type</th>
            <th>Address verified</th>
            <th>Company</th>
            <th>Fisrt name</th>
            <th>Last name</th>
          </tr>
        </thead>
        <tbody>
          {addressesList}
        </tbody>
      </table>
    </div>
  );
};

AddressesList.propTypes = {
  addressTemplates: PropTypes.instanceOf(AddressesCollection).isRequired,
};

export default AddressesList;
