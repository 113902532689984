import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TextMessageModel } from '@models/text_message';
import { TextMessageTemplateModel } from '@models/text_message_template';
import { UserModel } from '@models/user';
import { searchNotes } from '@actions/panel/cart_view/notes';
import { addTextMessage as addTextMessageAction } from '@actions/panel/cart_view/text_messages';
import { updateNotes } from '@/components/panel/notes/NoteFilterService';
import PhoneInput from '@/components/shared/PhoneInput';
import TextAreaInput from '@/components/shared/TextAreaInput';
import http from '@/utils/http';

class TextMessageForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneOptions: this.preparePhoneOptions(),
      textSize: 0,
      messageLengthClass: 'green',
      textMessage: new TextMessageModel({
        to_phone_number: this.props.user.mobilePhone,
        user_id: this.props.user.id,
        cart_id: this.props.currentCart.id,
      }),
      textMessageTemplate: new TextMessageTemplateModel({
        user_id: this.props.user.id,
      }),
      processing: false,
      edited: false,
    };
  }

  preparePhoneOptions = () => {
    const options = [];
    options.push({
      name: this.props.user.fullNameWithPhoneVerification(),
      phone: this.props.user.mobilePhone,
      user_type: 'user',
      default: true,
    });

    if (this.props.user.poc_attributes.mobile_phone) {
      options.push({
        name: this.props.user.pocName(),
        phone: this.props.user.poc_attributes.mobile_phone,
        user_type: 'poc',
        default: false,
      });
    }

    return options;
  }

  handleInputChange = (event) => {
    this.updateTextMessage('to_phone_number', event.target.value);
  }

  handleUserChange = (event) => {
    this.updateTextMessage('to_phone_number', event.target.value);

    const user_type = event.target.selectedOptions[0].getAttribute('data-user-type');

    this.setState((prevState) => {
      const textMessageTemplate = new TextMessageTemplateModel({ ...prevState.textMessageTemplate });
      textMessageTemplate.set('user_type', user_type);

      return { textMessageTemplate, edited: true };
    }, () => {
      if (this.state.textMessage.to_phone_number && this.state.textMessageTemplate.id) {
        this.updateTextMessageBody();
      } else {
        this.updateTextMessage('body', '');
      }
    });
  };

  handleTemplateSelectChange = (event) => {
    const selectedTemplateId = event.target.value;

    const index = event.nativeEvent.target.selectedIndex;
    const label = event.nativeEvent.target[index].text;

    this.setState((prevState) => {
      const textMessageTemplate = new TextMessageTemplateModel({ ...prevState.textMessageTemplate });
      textMessageTemplate.set('id', selectedTemplateId);

      const { textMessage } = this.state;
      textMessage.trigger_key = label;

      return { textMessageTemplate, textMessage, edited: true };
    }, () => {
      const { id } = this.state.textMessageTemplate;
      if (id) {
        this.updateTextMessageBody();
      } else {
        this.updateTextMessage('body', '');
      }
    });
  };

  updateTextMessageBody = () => {
    const { textMessage, textMessageTemplate } = this.state;

    if (textMessage.to_phone_number !== '') {
      http.get(textMessageTemplate.routes.instance)
        .then((response) => {
          textMessageTemplate.prepared_body = response.data.text_message_template.prepared_body;
          this.updateTextMessage('body', textMessageTemplate.prepared_body);
        }).catch(() => {

        });
    } else {
      this.updateTextMessage('body', '');
    }
  };

  updateTextMessage = (attributeName, attributeValue) => {
    this.setState((prevState) => {
      const textMessage = new TextMessageModel({ ...prevState.textMessage });
      textMessage.set(attributeName, attributeValue);

      return { textMessage, edited: true };
    }, () => {
      if (attributeName === 'body') {
        this.setState({ textSize: attributeValue.length });
      }
    });
  };

  handleTextAreaChange = (event) => {
    const { value } = event.target;

    this.setState({ textSize: value.length, edited: true });

    if (value.length <= 249) {
      this.updateTextMessage('body', value);
    } else {
      this.indicateTextTooLong();
    }
  };

  indicateTextTooLong = () => {
    this.setState({ messageLengthClass: 'red' });
  }

  handleFormSubmit = (e) => {
    const { onSuccessSubmit, addTextMessage } = this.props;
    const { textMessage } = this.state;
    e.preventDefault();

    this.setState({ processing: true });

    http.post(textMessage.routes.all, { text_message: textMessage.attributes })
      .then((response) => {
        const newTextMessage = new TextMessageModel(response.data);
        onSuccessSubmit(newTextMessage);
        addTextMessage(newTextMessage);
        updateNotes(this.props.user.id,
          this.props.currentCart.id,
          this.props.searchNotes);

        window.flashMessages.addMessage({
          type: 'success',
          text: textMessage.createSuccessMessage(),
        });
      })
      .catch((error) => {
        this.setState({ textMessage: new TextMessageModel(error.response.data) });

        window.flashMessages.addMessage({
          type: 'error',
          text: this.state.textMessage.createErrorMessage(),
        });
      })
      .finally(() => this.setState({ processing: false }));
  }

  render() {
    const {
      phoneOptions,
      textMessage,
      messageLengthClass,
      processing,
      edited,
    } = this.state;

    const { textMessageTemplates, onCancel } = this.props;

    return (
      <div className="shipping-address-dialog">
        <form onSubmit={this.handleFormSubmit} className="shipping-address__form">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="contact_info" className="input-label">Contact info</label>
                  <select
                    name="phone_number_source"
                    onChange={this.handleUserChange}
                    defaultValue={textMessage.to_phone_number}
                    className="input input-select"
                  >
                    <option value="">Please select</option>
                    {phoneOptions.map((phone) => (
                      <option
                        key={phone.phone}
                        value={phone.phone}
                        data-user-type={phone.user_type}
                      >
                        {phone.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <PhoneInput
                  name="to_phone_number"
                  label="Text Mobile number"
                  type="text"
                  required={true}
                  onChange={this.handleInputChange}
                  value={textMessage.to_phone_number}
                  errors={textMessage.errors.to_phone_number}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="template" className="input-label">Templates</label>
                  <select
                    name="template"
                    onChange={this.handleTemplateSelectChange}
                    defaultValue={textMessage.to_phone_number}
                    className="input input-select bg-contain"
                  >
                    <option value="">Please select</option>
                    {textMessageTemplates.map((template) => (
                      <option key={template.id} value={template.id}>
                        {_.startCase(template.name)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="position-relative">
                  <TextAreaInput
                    value={textMessage.body}
                    name="body"
                    rows="8"
                    cols="30"
                    label="Text message"
                    required={true}
                    className="text_message__form_input w-100"
                    onChange={this.handleTextAreaChange}
                    placeholder="Text message"
                    errors=""
                  />
                  <span className={`text_message_length_info ${messageLengthClass}`}>{this.state.textSize}/250</span>
                </div>
              </div>
            </div>


            <div className="mx-0 row justify-content-end">
              <button
                type="reset"
                className="btn btn-sm btn-light mr-3"
                onClick={onCancel}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-sm btn-primary"
                disabled={!edited || processing}
              >
                {processing ? 'Sending...' : 'Send'}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

TextMessageForm.propTypes = {
  onSuccessSubmit: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(UserModel).isRequired,
  textMessageTemplates: PropTypes.instanceOf(Array).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  addTextMessage: (textMessage) => dispatch(addTextMessageAction(textMessage)),
  searchNotes: (notes) => dispatch(searchNotes(notes)),
});

export default connect(
  null,
  mapDispatchToProps,
)(TextMessageForm);
