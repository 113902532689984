import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CartModel } from '@models/cart';
import { PharmacyModel } from '@models/pharmacy';

import CustomInput from '@components/shared/panel/CustomInput';
import InlineEditButtons from '@components/shared/panel/InlineEditButtons';

import http from '@/utils/http';

class PharmacyItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pharmacy: props.pharmacy,
      pharmacyCopy: props.pharmacy,
      activeEdit: false,
      processing: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (_.isEqual(prevProps.pharmacy, this.props.pharmacy)) return;
    this.setState({ pharmacy: this.props.pharmacy });
  }

  handleInputChange = ({ target: { name, value } }) => {
    // it fixes IMaskInput issue
    const { phone, fax } = this.state.pharmacy;
    if (name === 'phone' && value === _.toString(phone)) return;
    if (name === 'fax' && value === _.toString(fax)) return;

    this.setState((prevState) => {
      const pharmacy = new PharmacyModel({ ...prevState.pharmacy });
      pharmacy.set(name, value.toUpperCase());

      return { pharmacy, activeEdit: true };
    });
  };

  savePharmacy = () => {
    const { pharmacy } = this.state;

    this.setState({ processing: true });

    http.put(pharmacy.routes.instance, { pharmacy: pharmacy.attributes })
      .then((response) => {
        const newPharmacy = new PharmacyModel(response.data);
        this.setState({ pharmacy: newPharmacy, pharmacyCopy: newPharmacy, activeEdit: false });

        window.flashMessages.addMessage({ type: 'success', text: 'Pharmacy has been updated.' });
      })
      .catch((error) => {
        const invalidPharmacy = new PharmacyModel(error.response.data);
        this.setState({ pharmacy: invalidPharmacy });

        window.flashMessages.addMessage({ type: 'error', text: 'Pharmacy cannot be updated due to some errors.' });
      })
      .finally(() => this.setState({ processing: false }));
  }

  handleCancelEdit = () => {
    this.setState((prevState) => ({ pharmacy: prevState.pharmacyCopy, activeEdit: false }));
  }

  handlePharmacyChange = () => {
    const { currentPharmacy, updateCurrentPharmacy, currentCart } = this.props;
    const { pharmacy } = this.state;

    const newCurrentPharmacy = new PharmacyModel({
      ...pharmacy.attributes,
      id: currentPharmacy.id,
      cart_id: currentCart.id,
      parent_id: pharmacy.attributes.id,
    });

    const httpMethod = newCurrentPharmacy.id ? 'put' : 'post';

    http({
      method: httpMethod,
      url: newCurrentPharmacy.routes.instance,
      data: { pharmacy: newCurrentPharmacy.attributes },
    }).then((response) => {
      const savedCurrentPharmacy = new PharmacyModel(response.data);
      updateCurrentPharmacy(savedCurrentPharmacy);

      window.flashMessages.addMessage({
        type: 'success',
        text: 'Pharmacy has been updated.',
      });
    });
  }

  render() {
    const {
      pharmacy: {
        name,
        store_number,
        phone,
        fax,
        street_1,
        street_2,
        city,
        state,
        zip,
        label,
        errors,
      },
      activeEdit,
      processing,
    } = this.state;

    const { isCurrentPharmacy, currentCart } = this.props;

    return (
      <tr className={`align-center  ${isCurrentPharmacy ? 'table-success' : ''}`}>
        <td>
          {activeEdit && (
            <InlineEditButtons
              approveAction={this.savePharmacy}
              cancelAction={this.handleCancelEdit}
              processing={processing}
            />
          )}

          {!isCurrentPharmacy && !activeEdit && currentCart.isPresent && (
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={this.handlePharmacyChange}
            >
              Use
            </button>
          )}
        </td>
        <td>
          <CustomInput
            type="text"
            className={`input bg-white ${errors.name && 'is-invalid'}`}
            name="name"
            onChange={this.handleInputChange}
            value={name || ''}
            error={errors.name ? errors.name[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`input bg-white ${errors.store_number && 'is-invalid'}`}
            name="store_number"
            onChange={this.handleInputChange}
            value={store_number || ''}
            error={errors.store_number ? errors.store_number[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="tel"
            className={`input bg-white ${errors.phone && 'is-invalid'}`}
            name="phone"
            onChange={this.handleInputChange}
            value={phone || ''}
            error={errors.phone ? errors.phone[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`input bg-white ${errors.street_1 && 'is-invalid'}`}
            name="street_1"
            onChange={this.handleInputChange}
            value={street_1 || ''}
            error={errors.street_1 ? errors.street_1[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`input bg-white ${errors.street_2 && 'is-invalid'}`}
            name="street_2"
            onChange={this.handleInputChange}
            value={street_2 || ''}
            error={errors.street_2 ? errors.street_2[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`input bg-white ${errors.city && 'is-invalid'}`}
            name="city"
            onChange={this.handleInputChange}
            value={city || ''}
            error={errors.city ? errors.city[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`input bg-white ${errors.state && 'is-invalid'}`}
            name="state"
            onChange={this.handleInputChange}
            value={state || ''}
            error={errors.state ? errors.state[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`input bg-white ${errors.zip && 'is-invalid'}`}
            name="zip"
            onChange={this.handleInputChange}
            value={zip || ''}
            error={errors.zip ? errors.zip[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="tel"
            className={`input bg-white ${errors.fax && 'is-invalid'}`}
            name="fax"
            onChange={this.handleInputChange}
            value={fax || ''}
            error={errors.fax ? errors.fax[0] : null}
          />
        </td>
        <td>
          <CustomInput
            type="text"
            className={`input bg-white ${errors.label && 'is-invalid'}`}
            name="label"
            onChange={this.handleInputChange}
            value={label || ''}
            error={errors.label ? errors.label[0] : null}
          />
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCart: new CartModel(state.carts.current_cart),
});

PharmacyItem.propTypes = {
  currentCart: PropTypes.instanceOf(CartModel).isRequired,
  pharmacy: PropTypes.instanceOf(PharmacyModel).isRequired,
};

export default connect(mapStateToProps)(PharmacyItem);
