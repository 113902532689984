import { NoteModel } from '@models/note';
import http from '@/utils/http';

const filterNotes = (note, searchQuery, successCallback) => {
  http.get(`${note.routes.all}${searchQuery}`)
    .then((response) => {
      successCallback(response.data);
    })
    .catch(() => {
      window.flashMessages.addMessage({
        type: 'error',
        text: 'Notes can not be filter due to some errors.',
      });
    });
};

const updateNotes = (userId, cartId, callbackMethod) => {
  const note = new NoteModel();
  const searchQuery = note.searchQuery({
    currentUserId: userId,
    cartId,
  });

  filterNotes(note, searchQuery, callbackMethod);
};

export { filterNotes, updateNotes };
