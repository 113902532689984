import http from '@/utils/http';

const updateStageChanges = (stageChanges) => ({
  type: 'UPDATE_STAGE_CHANGES',
  stageChanges,
});

const getStageChanges = (id) => (dispatch) => http
  .get(`/carts/${id}/cart_stage_changes`)
  .then((response) => {
    dispatch(updateStageChanges(response.data));
  })
  .catch((error) => {
    throw error;
  });

export {
  // eslint-disable-next-line import/prefer-default-export
  getStageChanges,
};
