import moment from 'moment';

import { BaseModel, BaseCollection } from './base';

class DiscountTemplateModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      landing_page_id: null,
      user_id: null,
      product_variant_id: null,
      type: '',
      name: '',
      code: '',
      expires_at: '',
      uses_total: '',
      uses_count: '',
      percent: '',
      price: '',
      tier: '',
      extra_amount: '',
      created_at: '',
      automatic: false,
    };
  }

  constructor(attributes = {}) {
    super(attributes);

    if (attributes.expires_at) {
      const expiresAt = new Date(attributes.expires_at).toISOString();
      this.expires_at = moment(expiresAt).format('L');
    }
  }

  get formattedCreatedAt() {
    return this.created_at ? moment(this.created_at).format('L') : '';
  }


  get isAutomatic() {
    return this.automatic ? 'Yes' : 'No';
  }

  get routes() {
    return {
      all: '/discount_templates',
      instance: this.id ? `/discount_templates/${this.id}` : '/discount_templates',
      deletePath: `/discount_templates/${this.id}`,
    };
  }

  get attributes() {
    const { errors, ...attrs } = this;
    attrs.expires_at = moment(this.expires_at, 'L').toISOString();

    return attrs;
  }
}

class DiscountsTemplateCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return DiscountTemplateModel;
  }
}

export { DiscountTemplateModel, DiscountsTemplateCollection };
