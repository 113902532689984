const addTextMessage = (textMessage) => ({
  type: 'ADD_TEXT_MESSAGE',
  textMessage,
});

const updateTextMessages = (textMessages) => ({
  type: 'UPDATE_TEXT_MESSAGES',
  textMessages,
});

export {
  addTextMessage,
  updateTextMessages,
};
