const initializeLayoutBehavior = () => {
  $('.nav .toggle-nav').on('click', () => {
    $('.nav .toggle-nav').toggleClass('is-active');
  });

  $('.js-dropdown').on('click', (e) => {
    e.preventDefault();
    const $this = $(e.target).closest('.js-dropdown');
    if (!$this.hasClass('is-open')) {
      $('.js-dropdown').removeClass('is-open');
    }
    $this.toggleClass('is-open');
  });

  document.addEventListener('click', (event) => {
    if (
      !$(event.target).closest('.with-dropdown').length
      && !$(event.target).is('.with-dropdown')
    ) {
      $('.js-dropdown').removeClass('is-open');
    }
  });

  const basedIn = document.querySelector('.based-in');
  const basedInMobile = document.querySelector('.mobile .based-in');
  if (basedIn) {
    document.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        basedIn.classList.add('hidden');
        basedInMobile.classList.add('hidden');
      } else {
        basedIn.classList.remove('hidden');
        basedInMobile.classList.remove('hidden');
      }
    });
  }
};

export default initializeLayoutBehavior;
