
import React from 'react';
import ReactDOM from 'react-dom';

import ContactFormContainer from '@components/panel/views/ContactFormContainer';
import { initSideBarToggle } from '@/utils/panel/panel';

const initializeViews = (view) => {
  $(() => {
    initSideBarToggle(view);

    const assingNewLocation = function () {
      const params = {};

      $('.view-filter').each((_index, el) => {
        params[el.name] = $(el).val();
      });

      const sortingColumn = $('.apply-sorting.active');
      if (sortingColumn.length > 0) {
        params.sort_column = sortingColumn.data('column');
        params.sort_direction = sortingColumn.data('direction');
      }

      const newLocation = `${window.location.pathname}?${$.param(params)}`;
      window.location.assign(newLocation);
    };

    $('form.view-filter-form').on('submit', (e) => {
      e.preventDefault();

      assingNewLocation();
    });

    $('.sortable-headres').on('click', '.apply-sorting', (e) => {
      e.preventDefault();
      const isActive = $(e.target).hasClass('active');
      $('.apply-sorting').removeClass('active');
      if (!isActive) $(e.target).addClass('active');

      assingNewLocation();
    });

    $('input#keyword').keyup((event) => {
      const keywordEmpty = !event.target.value;
      $('button.reset-keyword').prop('disabled', keywordEmpty);
    }).trigger('keyup');

    $('button.reset-keyword').click((event) => {
      event.preventDefault();

      $('input#keyword').val('');

      $(event.target).closest('form').submit();
    });

    $('input.order-checkbox').click(() => {
      const selectedCheckbox = $('.order-checkbox:checked').length > 0;
      $('#packing-slip-button').attr('disabled', !selectedCheckbox);
    });

    $('#packing-slip-button').click(() => {
      const selectedCheckbox = $('.order-checkbox:checked');
      const selectedOrderIds = [];
      selectedCheckbox.each((_index, el) => {
        selectedOrderIds.push($(el).val());
      });

      const el = $('#order-message');
      el.css('display', 'none');
      const ordersToSkip = $(el).data('orderids').toString().split(',');

      selectedOrderIds.forEach((orderId) => {
        if (ordersToSkip.includes(orderId.toString())) {
          el.css('display', 'block');
        }
      });
    });
  });
};

const initializeContactForm = (data, price) => {
  ReactDOM.render(
    <ContactFormContainer landingPages={data.landing_pages} prices={price.prices}/>,
    document.querySelector('#new-contact-container[data-behavior="react"]'),
  );
};

export {
  initializeViews, initializeContactForm,
};
