import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DoctorModel } from '@models/doctor';
import { UserModel } from '@models/user';

import NpiAutocomplete from '@components/shared/NpiAutocomplete';
import TextInput from '@components/shared/TextInput';
import PhoneInput from '@components/shared/PhoneInput';

import http from '@/utils/http';

class DoctorForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doctor: new DoctorModel({ user_id: props.user.id }),
      processing: false,
      edited: false,
    };
  }

  handleSearch = (data) => {
    this.setState((prevState) => {
      const doctor = new DoctorModel({ ...data, user_id: prevState.doctor.user_id });
      return { doctor, edited: true };
    });
  }

  handleInputChange = ({ target: { name, value } }) => {
    this.setState((prevState) => {
      const doctor = new DoctorModel({ ...prevState.doctor });
      doctor.set(name, value.toUpperCase());

      return { doctor, edited: true };
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    const { doctor } = this.state;
    const { onSuccessSubmit } = this.props;

    this.setState({ processing: true });

    http.post(doctor.routes.instance, { doctor: doctor.attributes })
      .then((response) => {
        const newDoctor = new DoctorModel(response.data);
        onSuccessSubmit(newDoctor);

        window.flashMessages.addMessage({
          type: 'success',
          text: 'New doctor has been created succesfully.',
        });
      })
      .catch((error) => {
        this.setState({ doctor: new DoctorModel(error.response.data) });
      })
      .finally(() => this.setState({ processing: false }));
  }

  render() {
    const {
      doctor: {
        first_name,
        last_name,
        phone,
        fax,
        street_1,
        street_2,
        city,
        state,
        zip,
        country,
        npi,
        errors,
      },
      processing,
      edited,
    } = this.state;

    return (
      <div className="doctor-dialog">
        <div className="row">
          <div className="doctor-dialog__search w-100">
            <NpiAutocomplete type="doctor" onSearch={this.handleSearch} />
          </div>
        </div>
        <form className="doctor-dialog__form" onSubmit={this.handleFormSubmit}>
          <div className="row">
            <div className="col">
              <TextInput
                name="first_name"
                label="First name"
                type="text"
                value={first_name || ''}
                errors={errors.first_name}
                onChange={this.handleInputChange}
                icon="user"
              />
            </div>
            <div className="col">
              <TextInput
                name="last_name"
                label="Last name"
                type="text"
                value={last_name || ''}
                errors={errors.last_name}
                onChange={this.handleInputChange}
                icon="user"
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <PhoneInput
                name="phone"
                label="Phone number"
                type="tel"
                value={phone || ''}
                errors={errors.phone}
                onChange={this.handleInputChange}
                icon="phone"
              />
            </div>
            <div className="col">
              <PhoneInput
                name="fax"
                label="Fax number"
                type="tel"
                value={fax || ''}
                errors={errors.fax}
                onChange={this.handleInputChange}
                icon="fax"
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <TextInput
                name="street_1"
                label="Street line 1"
                type="text"
                value={street_1 || ''}
                errors={errors.street_1}
                onChange={this.handleInputChange}
                icon="address"
              />
            </div>
            <div className="col">
              <TextInput
                name="street_2"
                label="Apt/Suite Number"
                type="text"
                value={street_2 || ''}
                errors={errors.street_2}
                onChange={this.handleInputChange}
                icon="address"
              />
            </div>

          </div>

          <div className="row">
            <div className="col">
              <TextInput
                name="city"
                label="City"
                type="text"
                value={city || ''}
                errors={errors.city}
                onChange={this.handleInputChange}
                icon="city"
              />
            </div>
            <div className="col">
              <TextInput
                name="state"
                label="State"
                type="text"
                value={state || ''}
                errors={errors.state}
                onChange={this.handleInputChange}
                icon="state"
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <TextInput
                name="zip"
                label="Zip"
                type="text"
                value={zip || ''}
                errors={errors.zip}
                onChange={this.handleInputChange}
                icon="zip"
              />
            </div>
            <div className="col">
              <TextInput
                name="npi"
                label="NPI"
                type="text"
                value={npi || ''}
                errors={errors.npi}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <TextInput
                name="country"
                label="Country"
                type="hidden"
                value={country || 'US'}
                errors={errors.country}
                onChange={this.handleInputChange}
                icon="country"
              />
            </div>
          </div>

          <div className="col row justify-content-end">
            <button
              type="reset"
              className="btn btn-sm btn-light mr-3"
              onClick={this.props.onCancel}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-sm btn-primary"
              disabled={!edited || processing}
            >
              {processing ? 'Processing...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: new UserModel(state.user),
});

DoctorForm.propTypes = {
  onSuccessSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(UserModel).isRequired,
};

export default connect(mapStateToProps)(DoctorForm);
