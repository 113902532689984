import React, { Component } from "react";
import { connect } from "react-redux";

import { CartModel } from "@models/cart";
import { UserModel } from "@models/user";

import CustomExpansionPanel from "@components/shared/panel/CustomExpansionPanel";
import ContactForm from "./ContactForm";

class ContactContainer extends Component {
  render() {
    const { currentCart, user, isSuperAdmin } = this.props;

    window.history.replaceState(
      null,
      "cart_view",
      currentCart.id !== null ? currentCart.routes.byId : user.routes.byId
    );

    return (
      <div className="contact-container user-cart-section">
        <div className="pt-2 pb-2">
          <CustomExpansionPanel id="contact-panel" headerText="Contact">
            <ContactForm 
              user={user}
              isSuperAdmin={isSuperAdmin}
            />
          </CustomExpansionPanel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: new UserModel(state.user),
  currentCart: new CartModel(state.carts.current_cart),
});

export default connect(mapStateToProps)(ContactContainer);
