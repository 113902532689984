import React, { Component } from "react";
import { connect } from "react-redux";

import { CartModel } from "@models/cart";
import { UserModel } from "@models/user";
import { OrdersCollection } from "@models/order";

import { createOrder, addCartsToOrder } from "@actions/panel/cart_view/orders";

import FormDialog from "@components/shared/FormDialog";

import CartForm from "./CartForm";
import OpenCartItem from "./OpenCartItem";

class OpenCartsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
      currentGroup: "open",
      cartDialogStatus: false,
      currentOpenCart: new CartModel({
        user_id: props.user.id,
        landing_page_id: props.user.landing_page_id,
      }),
    };
  }

  handleAllItemsSelection = ({ target: { checked } }) => {
    const carts = this.props.openCarts.filterByGroup(this.state.currentGroup);
    const selectedItems = checked
      ? carts
          .filter((cart) => cart.order_id === null)
          .map((cart) => Number(cart.id))
      : [];

    this.setState({ selectedItems });
  };

  handleSingleItemSelection = ({ target: { checked, value } }) => {
    let selectedItems;

    if (checked) {
      selectedItems = [...this.state.selectedItems, Number(value)];
    } else {
      selectedItems = this.state.selectedItems.filter(
        (id) => id !== Number(value)
      );
    }

    this.setState({ selectedItems });
  };

  handleOpenDialog = (dialogStatusName) => {
    this.setState({ [dialogStatusName]: true });
  };

  handleCloseDialog = (dialogStatusName) => {
    this.setState({ [dialogStatusName]: false });
  };

  handleGroupSelect = (e) => {
    this.setState({ currentGroup: e.target.value, selectedItems: [] });
  };

  handleNewOpenCart = () => {
    const { id, landing_page_id } = this.props.user;
    const currentOpenCart = new CartModel({ user_id: id, landing_page_id });

    this.setState(
      { currentOpenCart },
      this.handleOpenDialog("cartDialogStatus")
    );
  };

  handleOpenCartEdit = () => {
    const currentOpenCart = this.props.openCarts.models.find(
      (openCart) => openCart.id === this.state.selectedItems[0]
    );

    this.setState(
      { currentOpenCart },
      this.handleOpenDialog("cartDialogStatus")
    );
  };

  handleOpenOrderCreate = () => {
    const { selectedItems } = this.state;

    if (selectedItems.length === 0) return;

    this.props.createOrder(selectedItems);
    this.setState({ selectedItems: [] });
  };

  handleAddCartToOrder = (e) => {
    const { selectedItems } = this.state;
    const orderId = e.target.value;

    if (selectedItems.length === 0) return;

    this.props.addCartsToOrder(orderId, selectedItems);
    this.setState({ selectedItems: [] });
  };

  render() {
    const {
      props: {
        openCarts,
        openOrders,
        productVariants,
        deliveryOptions,
        onLostSelect,
      },
      state: { selectedItems, currentGroup, cartDialogStatus, currentOpenCart },
    } = this;

    const carts = openCarts.filterByGroup(currentGroup);
    const availableSelectsNumber = carts.filter(
      (cart) => cart.order_id === null
    ).length;

    return (
      <div className="open-carts-list">
        <div className="d-flex justify-content-end">
          <div className="open-carts-actions form-inline p-2">
            <select
              className="form-control form-control-sm mx-2"
              value={currentGroup}
              onChange={this.handleGroupSelect}
            >
              <option value="open">Open Carts</option>
              <option value="lost">Lost Carts</option>
            </select>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary mx-2"
              onClick={this.handleNewOpenCart}
            >
              New Cart
            </button>
            {selectedItems.length === 1 && (
              <button
                type="button"
                className="btn btn-sm btn-outline-primary mx-2"
                onClick={this.handleOpenCartEdit}
              >
                Edit Cart
              </button>
            )}
            {selectedItems.length > 0 && (
              <>
                <select
                  className="form-control form-control-sm mx-2"
                  defaultValue=""
                  onChange={this.handleAddCartToOrder}
                >
                  <option value="" disabled>
                    Add to Order
                  </option>
                  {openOrders.findByState("draft").map((draftOrder) => (
                    <option key={draftOrder.id} value={draftOrder.id}>
                      Order {draftOrder.invoice_number}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary mx-2"
                  onClick={this.handleOpenOrderCreate}
                >
                  Create New Order
                </button>
              </>
            )}
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="thead-light">
              <tr>
                <th scope="col" className="pl-3">
                  <input
                    type="checkbox"
                    checked={selectedItems.length === availableSelectsNumber}
                    onChange={this.handleAllItemsSelection}
                    className="custom-control-input"
                    id="all-open-carts"
                  />
                  <label
                    htmlFor="all-open-carts"
                    className="custom-control-label ml-4 mb-4"
                  />
                </th>
                <th scope="col" className="text-center">
                  #
                </th>
                <th scope="col">Created</th>
                <th scope="col">Drug</th>
                <th scope="col">Branch</th>
                <th scope="col">Stage</th>
                <th scope="col">Rx Type</th>
                <th scope="col">When Needed?</th>
                <th scope="col">Days in Stage</th>
                <th scope="col">Total Days</th>
                <th scope="col">Qty</th>
                <th scope="col">Max Rx Qty</th>
                <th scope="col">Value</th>
                <th scope="col">Disc</th>
                <th scope="col">Order#</th>
                <th scope="col">Delivery</th>
              </tr>
            </thead>
            <tbody>
              {carts.map((openCart) => (
                <OpenCartItem
                  key={openCart.id}
                  openCart={openCart}
                  deliveryOptions={deliveryOptions}
                  selected={selectedItems.indexOf(openCart.id) > -1}
                  onSelect={this.handleSingleItemSelection}
                  onLostSelect={onLostSelect}
                />
              ))}
            </tbody>
          </table>
        </div>

        <FormDialog
          title={
            selectedItems.length > 0 ? "Cart Details Form" : "New cart details"
          }
          isOpen={cartDialogStatus}
          onClose={() => this.handleCloseDialog("cartDialogStatus")}
          customClass="dialog__wide"
        >
          <CartForm
            currentOpenCart={currentOpenCart}
            productVariants={productVariants}
            deliveryOptions={deliveryOptions}
            onCancel={() => this.handleCloseDialog("cartDialogStatus")}
          />
        </FormDialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: new UserModel(state.user),
  openOrders: new OrdersCollection(state.orders),
});

const mapDispatchToProps = (dispatch) => ({
  createOrder: (cartsIds) => dispatch(createOrder(cartsIds)),
  addCartsToOrder: (orderId, cartsIds) =>
    dispatch(addCartsToOrder(orderId, cartsIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenCartsList);
