import { BaseModel, BaseCollection } from './base';

class DoctorModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      first_name: '',
      last_name: '',
      phone: '',
      phone_verified: false,
      fax: '',
      fax_from_user: '',
      fax_verified: false,
      street_1: '',
      street_2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US',
      npi: '',
      type: '',
      parent_id: null,
    };
  }

  get fullname() {
    return [this.first_name, this.last_name].join(' ');
  }

  get routes() {
    return {
      all: '/doctors',
      instance: this.id ? `/doctors/${this.id}` : '/doctors',
    };
  }
}

class DoctorsCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return DoctorModel;
  }
}

export { DoctorModel, DoctorsCollection };
