
import React from 'react';
import { IMaskInput } from 'react-imask';

import { IMaskDateConfig } from '@/utils/imask_config';

const DateInput = (props) => {
  const {
    value,
    errors,
    label,
    icon,
    customClass,

    ...attrs
  } = props;

  const haveIcon = icon ? `input__icon ${icon}` : '';

  return (
    <div className={`form-group ${customClass}`}>
      {label
        ? (
          <label className="input-label" htmlFor={attrs.id}>
            {label}
          </label>
        ) : null}

      <IMaskInput
        value={value}
        className={`input ${haveIcon}`}
        type="tel"
        mask={IMaskDateConfig.mask}
        blocks={IMaskDateConfig.blocks}
        {...attrs}
      />

      {errors && errors.length > 0 && (
        <span className="input__error">{errors[0]}</span>
      )}
    </div>
  );
};

export default DateInput;
