const initialState = {
  notes: [],
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_NOTES': {
      if (action.note.updated) {
        return [action.note, ...state.filter((note) => note.id !== action.note.id)];
      }
      return [action.note, ...state];
    }
    case 'SEARCH_NOTES': {
      return action.notes;
    }
    case 'DELETE_NOTE': {
      return state.filter((note) => note.id !== action.note.id);
    }
    default:
      return state;
  }
};

export default notesReducer;
