import React, { Component } from 'react';

import { DoctorsCollection } from '@models/doctor';

import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';
import FormDialog from '@components/shared/FormDialog';
import DoctorsList from './DoctorsList';
import DoctorForm from './DoctorForm';

class DoctorsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormDialogOpen: false,
      doctorTemplates: new DoctorsCollection(props.doctors.doctor_templates),
    };
  }

  handleAddNew = () => {
    this.setState({ isFormDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isFormDialogOpen: false });
  };

  handleSuccessSubmit = (newDoctor) => {
    this.setState((prevState) => {
      prevState.doctorTemplates.models.push(newDoctor);
      return { doctorTemplates: prevState.doctorTemplates, isFormDialogOpen: false };
    });
    this.setState({ isFormDialogOpen: false });
  }

  render() {
    const { isFormDialogOpen, doctorTemplates } = this.state;

    return (
      <div className="doctor-container user-cart-section">
        <CustomExpansionPanel
          id="doctor-panel"
          headerText="Doctors"
        >
          <div className="panel__content w-100">
            <div className="d-flex justify-content-end p-2">
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={this.handleAddNew}
              >
                New Doctor
              </button>
            </div>

            <DoctorsList
              key={doctorTemplates.models.length}
              doctorTemplates={doctorTemplates}
            />
          </div>
        </CustomExpansionPanel>
        <FormDialog
          title="Enter new doctor information"
          isOpen={isFormDialogOpen}
          onClose={this.handleCloseDialog}
          customClass="dialog__wide"
        >
          <DoctorForm
            onCancel={this.handleCloseDialog}
            onSuccessSubmit={this.handleSuccessSubmit}
          />
        </FormDialog>
      </div>
    );
  }
}

export default DoctorsContainer;
