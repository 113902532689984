import React, { Component } from 'react';
import ReactTable from 'react-table';

import FormDialog from '@components/shared/FormDialog';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import http from '@/utils/http';
import { DiscountTemplateModel } from '@models/discount_template';
import DiscountTemplateForm from './DiscountTemplateForm';

class DiscountTemplatesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormDialogOpen: false,
      editingDiscountTemplate: null,
      discountTemplates: props.discountTemplates,
    };
  }

  handleEditDiscountTemplate = ({ target }) => {
    this.setState((prevState) => {
      const discountTemplate = prevState.discountTemplates.findById(target.dataset.itemId);

      return {
        isFormDialogOpen: true,
        editingDiscountTemplate: discountTemplate,
      };
    });
  }

  handleCloseDialog = () => {
    this.setState({ isFormDialogOpen: false });
  };

  handleDiscountTemplateSuccessSubmit = (discountTemplate) => {
    this.setState((prevState) => {
      const { discountTemplates } = prevState;
      const index = _.findIndex(discountTemplates.models, (temp) => temp.id === discountTemplate.id);
      discountTemplates.models[index] = discountTemplate;

      return {
        isFormDialogOpen: false,
        editingDiscountTemplate: null,
        discountTemplates,
      };
    });
  };

  destroy = (discountId) => {
    const { discountTemplates } = this.state;
    const { handleDiscountTemplateDeletion } = this.props;
    const discountTemplate = _.find(discountTemplates.models, (discount) => discount.id === discountId);

    http
      .delete(discountTemplate.routes.deletePath)
      .then((response) => {
        const deletedDiscountTemplate = new DiscountTemplateModel({ ...response.data });
        handleDiscountTemplateDeletion(deletedDiscountTemplate);
      })
      .catch((error) => {
        throw error;
      });
  }

  getTrProps = (_state, rowInfo) => {
    if (rowInfo) {
      return { className: (rowInfo.original.discarded_at === null ? 'active' : 'inactive') };
    }
    return {};
  }

  render() {
    const { globalDiscountTemplates, productVariants, adminRole } = this.props;

    const { isFormDialogOpen, editingDiscountTemplate, discountTemplates } = this.state;

    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Drug',
        accessor: 'product_name',
      },
      {
        Header: 'Coupon',
        accessor: 'value',
      },
      {
        Header: 'Frequency',
        accessor: 'uses_total',
      },
      {
        Header: 'Expiration',
        accessor: 'expires_at',
      },
      {
        Header: 'Coupon Code',
        accessor: 'code',
      },
      {
        Header: 'Uses count',
        accessor: 'uses_count',
      },
      {
        Header: 'Automatic',
        accessor: 'isAutomatic',
      },
      {
        Header: 'Creation date',
        accessor: 'formattedCreatedAt',
      },
    ];

    if (adminRole === 'superadmin') {
      columns.unshift({
        Header: '',
        Cell: ({ original }) => {
          if (adminRole === 'superadmin' && !original.discarded_at) {
            return (
              <>
                <button
                  className="btn btn-sm btn-outline-primary"
                  data-item-id={original.id}
                  onClick={this.handleEditDiscountTemplate}
                >
                  Edit
                </button>
                <button
                  className="btn btn-outline-danger btn-sm mx-1"
                  onClick={() => this.destroy(original.id)}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </button>
              </>
            );
          }
          return '';
        },
        sortable: false,
        resizable: false,
        width: 105,
      });
    }

    return (
      <div>
        <FormDialog
          title="Edit discount template"
          isOpen={isFormDialogOpen}
          onClose={this.handleCloseDialog}
          customClass="dialog__wide"
        >
          <DiscountTemplateForm
            globalDiscountTemplates={globalDiscountTemplates}
            productVariants={productVariants}
            discountTemplate={editingDiscountTemplate}
            onCancel={this.handleCloseDialog}
            handleDiscountTemplateSuccessSubmit={this.handleDiscountTemplateSuccessSubmit}
          />
        </FormDialog>

        <ReactTable
          minRows={1}
          columns={columns}
          data={discountTemplates.models}
          defaultPageSize={10}
          pageSizeOptions={[10, 30, 50]}
          className="-striped -highlight"
          getTrProps={this.getTrProps}
        />
      </div>
    );
  }
}

export default DiscountTemplatesList;
