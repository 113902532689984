const scrollTo = (el) => {
  const id = el.attributes.href.value;
  $('html, body').animate(
    {
      scrollTop: $(id).offset().top - 190,
    },
    400,
  );
};

const scrollInit = () => {
  const cartSections = document.querySelectorAll('[data-js="scrollto"]');
  cartSections.forEach((el) => el.addEventListener('click', () => scrollTo(el)));
};

const initSideBarToggle = (view) => {
  const targetNode = document.getElementsByClassName('cart-layout')[0];
  const config = {
    attributes: true,
    attributeOldValue: true,
    childList: false,
    subtree: false,
  };

  if (view) {
    const ls = localStorage.getItem(view);
    if (ls) {
      targetNode.className = '';
      ls.split(' ').forEach((item) => {
        targetNode.classList.add(item);
      });
    }
  }

  const callback = function (mutationsList) {
    for (let i = 0; i < mutationsList.length; i += 1) {
      const mutation = mutationsList[i];
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        localStorage.setItem(view, mutation.target.classList.value);
      }
    }
  };

  const observer = new MutationObserver(callback);
  observer.observe(targetNode, config);

  function hideTooltip() {
    $('[data-toggle="tooltip"]').tooltip('hide');
  }

  // event handler function
  function handler(e) {
    hideTooltip();
    if (e.shiftKey && e.ctrlKey && e.keyCode === 219) { // shift + ctrl + [
      $('.cart-layout').toggleClass('hide-left');
    }
    if (e.shiftKey && e.ctrlKey && e.keyCode === 221) { // shift + ctrl + ]
      $('.cart-layout').toggleClass('hide-right');
    }
    if (e.shiftKey && e.ctrlKey && e.keyCode === 220) { // shift + ctrl + |
      $('.cart-layout').toggleClass('hide-left hide-right');
    }
  }

  $('.sidebar-toggle').on('click', (ev) => {
    const { side } = ev.target.dataset;
    hideTooltip();
    $('.cart-layout').toggleClass(`hide-${side}`);
  });

  $('[data-toggle="tooltip"]').tooltip();

  // attach handler to the keydown event of the document
  if (document.attachEvent) document.attachEvent('onkeydown', handler);
  else document.addEventListener('keydown', handler);
};

const scrollSpy = () => {
  const sections = $('section');
  const nav = $('.sidebar-sticky .nav');

  function scroller() {
    const cur_pos = $(this).scrollTop();

    sections.each(function () {
      const top = $(this).offset().top - (0.25 * window.innerHeight);
      const bottom = top + $(this).outerHeight();

      if (cur_pos >= top && cur_pos <= bottom) {
        nav.find('li a').removeClass('active');
        nav.find(`[href="#${$(this).attr('id')}"]`).addClass('active');
      }
    });
  }

  $(window).on('scroll', scroller);
  $(window).on('touchmove', scroller);
};

const formatDate = (date) => {
  const tmpDate = new Date(Date.parse(date));
  if (tmpDate.toString() !== 'Invalid Date') {
    return `${tmpDate.toLocaleDateString()} ${tmpDate.toLocaleTimeString()}`;
  }
  return '';
};

export {
  scrollInit,
  initSideBarToggle,
  scrollSpy,
  formatDate,
};
