const showNoteForm = (issueId, note) => ({
  type: 'SHOW_NOTE_FORM',
  issueId,
  note,
});

const hideNoteForm = () => ({
  type: 'HIDE_NOTE_FORM',
});

export {
  showNoteForm,
  hideNoteForm,
};
