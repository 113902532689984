import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import { updateIssue as updateIssueAction } from '@actions/panel/cart_view/issues';

import InlineEditButtons from '@components/shared/panel/InlineEditButtons';
import Icon from '@material-ui/core/Icon';
import NoteIcon from '@material-ui/icons/Note';
import { filterNotes } from '@components/panel/notes/NoteFilterService';
import { searchNotes } from '@actions/panel/cart_view/notes';
import { showNoteForm, hideNoteForm } from '@actions/panel/cart_view/triggers';

import { NoteModel } from '@models/note';

import { resolveRequestStageLabel } from '@/utils/panel';

class IssuesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      originalIssues: _.cloneDeep(props.issues),
    };

    this.noteRef = React.createRef();
  }

  onChangeView = (event, rowIndex) => {
    this.updateIssues('view', event.target.value, rowIndex);
  }

  onChangeStage = (event, rowIndex) => {
    this.updateIssues('stage', event.target.value, rowIndex);
    const defaultView = this.defaultView(event.target.value);

    this.updateIssues('view', defaultView, rowIndex);
    this.updateIssues('name', this.defaultIssue(defaultView), rowIndex);
  }

  onChangeStatus = (event, rowIndex) => {
    this.updateIssues('status', event.target.value, rowIndex);
  }

  updateIssues = (attrName, newValue, rowIndex) => {
    const { issues } = this.props;

    issues.models[rowIndex][attrName] = newValue;
    this.editForIssueName(rowIndex);
  }

  defaultView = (stage) => {
    const { views } = this.props.issueOptions;
    const viewOptionNames = views[_.snakeCase(stage.toLowerCase())];

    return _.snakeCase(viewOptionNames[0]);
  }

  defaultIssue = (viewName) => {
    const issuesForView = this.props.issueOptions.issues[_.snakeCase(viewName.toLowerCase())];

    return issuesForView[0];
  }

  editForIssueName = (rowIndex) => {
    this.props.issues.models[rowIndex].activeEdit = true;

    const editRow = true;
    this.setState({ editRow });
  }

  issueNameCellContent = (cellInfo) => {
    const { issues } = this.props;
    let htmlContent = issues.models[cellInfo.index].name;


    if (issues.models[cellInfo.index].activeEdit) {
      const viewName = this.props.issues.models[cellInfo.index].view;
      const issuesNames = this.props.issueOptions.issues;

      htmlContent = (
        <select
          key={`names-${issues.models[cellInfo.index].id}`}
          onChange={(event) => { this.updateIssues('name', event.target.value, cellInfo.index); }}
          value={issues.models[cellInfo.index].name}
          className="input input-select bg-cover"
        >
          {issuesNames[_.snakeCase(viewName.toLowerCase())].map((issueName) => (
            <option key={issueName} value={issueName}>
              {issueName}
            </option>
          ))}
        </select>
      );
    }

    return (
      <div key={`issue-names-${issues.models[cellInfo.index].id}`}>
        {htmlContent}
      </div>
    );
  }

  submitButtons = (cellInfo) => {
    let htmlContent = '';

    if (this.props.issues.models[cellInfo.index].activeEdit) {
      htmlContent = (
        <InlineEditButtons
          key={`buttons-${cellInfo.index}`}
          approveAction={() => { this.saveIssue(cellInfo.index); }}
          cancelAction={() => { this.handleCancelEdit(cellInfo.index); }}
        />
      );
    }

    return (
      <div key={`submit-${cellInfo.index}`}>
        {this.props.issues.models[cellInfo.index].cart_id}
        {htmlContent}
      </div>
    );
  }

  saveIssue = (rowIndex) => {
    const { issues, updateIssue } = this.props;

    updateIssue(issues.models[rowIndex]);
  }

  handleCancelEdit = (rowIndex) => {
    const { issues } = this.props;
    const issueModel = _.cloneDeep(this.state.originalIssues.models[rowIndex]);

    issues.models[rowIndex] = issueModel;
    issues.models[rowIndex].activeEdit = false;

    this.setState({ editRow: false });
  }

  issueCreatedAtCellContent = (cellInfo) => this.htmlForIssueDate(cellInfo.index, 'created_at')

  issueUpdatedAtCellContent = (cellInfo) => this.htmlForIssueDate(cellInfo.index, 'updated_at')

  htmlForIssueDate = (index, dateType) => {
    const issue = this.props.issues.models[index];
    const issueDate = issue[dateType].split('|');

    return (
      <div key={`date-${dateType}-${issue.id}`}>
        {issueDate[0]}
        <br />
        {issueDate[1]}
      </div>
    );
  }

  newNoteCellContent = (cellInfo) => {
    const { issues } = this.props;
    const showForm = issues.models[cellInfo.index].showForm || false;

    return (
      <div key={`note-form-${cellInfo.index}`}>
        <span
          ref={this.noteRef}
          onClick={(event) => {
            event.preventDefault();
            this.noteFormShow(cellInfo.index);
          }}
          className={`note__toggle_add ${showForm ? 'is-open' : ''}`}
        >
          <Icon>add</Icon>
        </span>
      </div>
    );
  }

  noteFormShow = (issueIndex) => {
    const { issues } = this.props;
    const { editRow } = this.state;
    let issueId;

    if (editRow) {
      return;
    }

    if (issues.models[issueIndex].showForm) {
      issues.models[issueIndex].showForm = false;
      this.props.hideNoteForm();
    } else {
      issues.models[issueIndex].showForm = true;
      issueId = issues.models[issueIndex].id;
      this.props.showNoteForm(issueId);
    }
  }

  hideForm = () => {
    const { issues } = this.props;
    issues.models.forEach((issue) => {
      // eslint-disable-next-line no-param-reassign
      issue.showForm = false;
    });

    this.props.hideNoteForm();
  }

  viewNotesCellContent = (cellInfo) => {
    const currentIssue = this.props.issues.models.find((issue, index) => index === cellInfo.index);

    return (
      <span key={`view-notes-${currentIssue.id}`} onClick={() => { this.notesListShow(currentIssue.id); }}>
        <NoteIcon />
        {currentIssue.notes_count}
      </span>
    );
  }

  notesListShow = (issueId) => {
    const note = new NoteModel();
    const searchQuery = note.searchQuery({
      issueId,
      currentUserId: this.props.currentUserId,
      cartId: this.props.currentCartId,
      noteType: 'issue',
    });

    filterNotes(note, searchQuery, this.props.searchNotes);
  }

  issueViewCellContent = (cellInfo) => {
    const { issueOptions } = this.props;
    const { issues } = this.props;
    const { views } = issueOptions;
    let viewKey = _.snakeCase(issues.models[cellInfo.index].stage.toLowerCase());

    if (viewKey === 'fulfillment') {
      viewKey = 'order_fulfillment';
    }

    if (viewKey === 'open_leads') {
      viewKey = 'open';
    }

    return (
      <div key={`view-${cellInfo.index}`}>
        <select
          value={cellInfo.value}
          onChange={(e) => { this.onChangeView(e, cellInfo.index); }}
          className="input input-select bg-cover"
        >
          {views[viewKey].map((view) => (
            <option key={view} value={view}>
              {view}
            </option>
          ))}
        </select>
      </div>
    );
  }

  selectedStage = (index) => {
    const { issues } = this.props;
    const issue = issues.models[index];
    let selectedStage = this.titleize(issue.stage);

    if (issue.stage === 'Fulfillment' || issue.stage === 'order_fulfillment') {
      selectedStage = 'Order Fulfillment';
    }

    return selectedStage;
  }

  titleize = (string) => {
    const stringElements = [];
    string.split(' ').forEach((s) => {
      stringElements.push(_.capitalize(s));
    });

    return stringElements.join(' ');
  }

  render() {
    const { issueOptions, issues, currentCart } = this.props;
    const { statuses, stages } = issueOptions;

    const columns = [
      {
        Header: 'Cart',
        accessor: 'cart_id',
        Cell: this.submitButtons,
      },
      {
        Header: 'Issue',
        accessor: 'name',
        Cell: this.issueNameCellContent,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props) => (
          <div key={`status-${props.index}`}>
            <select
              value={props.value}
              onChange={(e) => { this.onChangeStatus(e, props.index); }}
              className="input input-select bg-cover"
            >
              {statuses.map((status) => (
                <option key={status.name} value={status.name}>
                  {_.startCase(status.name)}
                </option>
              ))}
            </select>
          </div>
        ),
      },
      {
        Header: 'Stage',
        accessor: 'stage',
        Cell: (props) => (
          <div key={`stage-${props.value}`}>
            <select
              value={this.selectedStage(props.index)}
              onChange={(e) => { this.onChangeStage(e, props.index); }}
              className="input input-select bg-cover"
              disabled={this.selectedStage(props.index)
                        !== resolveRequestStageLabel(currentCart.stage, currentCart.branch)}
            >
              {stages.map((stage) => (
                <option key={stage.name} value={stage.name}>
                  {resolveRequestStageLabel(stage.name, props.original.branch)}
                </option>
              ))}
            </select>
          </div>
        ),
      },
      {
        Header: 'View',
        accessor: 'view',
        Cell: this.issueViewCellContent,
      },
      {
        Header: 'Issue days',
        accessor: 'issue_days',
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: this.issueCreatedAtCellContent,
      },
      {
        Header: 'Modified',
        accessor: 'updated_at',
        Cell: this.issueUpdatedAtCellContent,
      },
      {
        Header: 'Note',
        accessor: 'updated_at',
        Cell: this.newNoteCellContent,
      },
      {
        Header: 'View notes',
        accessor: 'updated_at',
        Cell: this.viewNotesCellContent,
      },
    ];

    return (
      <div>
        <ReactTable
          minRows={1}
          columns={columns}
          data={issues.models}
          defaultPageSize={10}
          pageSizeOptions={[10, 30, 50]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  searchNotes: (notes) => dispatch(searchNotes(notes)),
  showNoteForm: (issueId) => dispatch(showNoteForm(issueId)),
  hideNoteForm: () => dispatch(hideNoteForm()),
  updateIssue: (issue) => dispatch(updateIssueAction(issue)),
});

const mapStateToProps = (state) => ({
  notes: state.notes,
  triggers: state.triggers,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IssuesList);
