import React, { Component } from 'react';
import { connect } from 'react-redux';

import { UserModel } from '@models/user';
import { CartModel } from '@models/cart';

import FormDialog from '@components/shared/FormDialog';
import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';
import CommunicationList from '../communications/CommunicationList';
import TextMessageForm from './TextMessageForm';

class TextMessagesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormDialogOpen: false,
    };
  }

  handleNewText = () => {
    this.setState({ isFormDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isFormDialogOpen: false });
  };

  handleSuccessSubmit = () => {
    this.setState({ isFormDialogOpen: false });
  }

  render() {
    const { isFormDialogOpen } = this.state;
    const { textMessageTemplates } = this.props;

    return (
      <div className="textmessage-container user-cart-section mt-3">
        <CustomExpansionPanel
          id="text-panel"
          headerText="Text"
        >
          <div>
            <div className="d-flex justify-content-end p-2">
              <button
                type="button"
                className="btn btn-sm btn-outline-primary mx-2 mb-3"
                onClick={this.handleNewText}
              >
                New text
              </button>
            </div>
            <FormDialog
              title="Enter new text message"
              isOpen={isFormDialogOpen}
              onClose={this.handleCloseDialog}
              customClass="dialog__wide"
            >
              <TextMessageForm
                user={new UserModel(this.props.user)}
                currentCart={this.props.currentCart}
                onSuccessSubmit={this.handleSuccessSubmit}
                textMessageTemplates={textMessageTemplates}
                onCancel={this.handleCloseDialog}
              />
            </FormDialog>
            <CommunicationList />
          </div>
        </CustomExpansionPanel>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  currentCart: new CartModel(state.carts.current_cart),
});

export default connect(mapStateToProps)(TextMessagesContainer);
