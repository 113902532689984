/* eslint max-len: 0 */
import React, { useCallback, useState, useRef } from 'react';
import IMask from 'imask';

import 'react-virtualized/styles.css';
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';

const NpiAutocomplete = (props) => {
  const [value, updateValue] = useState('');
  const [apiList, updateApiList] = useState([]);
  const [loading, updateLoading] = useState(false);

  // eslint-disable-next-line no-use-before-define
  const debouncedFetchData = useCallback(_.debounce(fetchData, 500), []);

  const cache = new CellMeasurerCache({
    defaultHeight: 50,
    fixedWidth: true,
  });

  const options = {
    npi_idv: {
      fields: [
        'npi',
        'last_name',
        'first_name',
        'street_1',
        'street_2',
        'city',
        'state',
        'zip',
        'country',
        'phone',
        'fax',
      ],
      url: 'https://clinicaltables.nlm.nih.gov/api/npi_idv/v3/search?df=NPI,name.last,name.first,addr_practice.line1,addr_practice.line2,addr_practice.city,addr_practice.state,addr_practice.zip,addr_practice.country,addr_practice.phone,addr_practice.fax&sf=addr_practice.phone&maxList=500&terms=',
    },
    npi_org: {
      fields: [
        'npi',
        'name',
        'street_1',
        'street_2',
        'city',
        'state',
        'zip',
        'country',
        'phone',
        'fax',
      ],
      url: 'https://clinicaltables.nlm.nih.gov/api/npi_org/v3/search?df=NPI,name.full,addr_practice.line1,addr_practice.line2,addr_practice.city,addr_practice.state,addr_practice.zip,addr_practice.country,addr_practice.phone,addr_practice.fax&sf=addr_practice.phone&maxList=500&terms=',
    },
  };

  const list = useRef(null);
  const item = useRef(null);
  const input = useRef(null);

  const source = props.type === 'doctor' ? 'npi_idv' : 'npi_org';

  const phoneMask = IMask.createMask({
    mask: '(000) 000-0000',
  });

  window.addEventListener('resize', () => {
    if (apiList.length === 0 || !list.current) return;

    list.current.measureAllRows();
    list.current.recomputeRowHeights();
    cache.clearAll();
  });

  function fetchData(val) {
    const maskedValue = phoneMask.resolve(val);
    const searchPhoneIndex = props.type === 'doctor' ? 9 : 8;

    if (maskedValue.length >= 3) {
      updateLoading(true);

      fetch(options[source].url + encodeURIComponent(maskedValue))
        .then((resp) => resp.json())
        .then((result) => updateApiList(result[3].filter((el) => el[searchPhoneIndex].indexOf(maskedValue) !== -1)))
        .finally(() => updateLoading(false));

      input.current.classList.add('is-open');
    } else {
      input.current.classList.remove('is-open');
    }
  }

  const handleSelection = (event) => {
    const values = event.currentTarget.dataset.value.split(' - ');
    const result = Object.assign(
      {},
      ...options[source].fields.map((field, index) => ({ [field]: values[index] })),
    );

    props.onSearch(result);
    input.current.classList.remove('is-open');
  };

  const noRowsRenderer = () => <div className="npi-list__empty">No results</div>;

  const rowRenderer = ({
    index, key, parent, style,
  }) => {
    const data = apiList[index].join(' - ');
    let listItemContent;

    switch (props.type) {
      case 'doctor':
        listItemContent = (
          <>
            <strong> Name: </strong> {`${apiList[index][1]} ${apiList[index][2]}`}
            <strong> Phone: </strong> {apiList[index][9]}
            <strong> Address: </strong> {apiList[index].slice(3, 9).join(' ')}
          </>
        );

        break;
      case 'pharmacy':
        listItemContent = (
          <>
            <strong> Phone: </strong> {apiList[index][8]}
            <strong> Address: </strong> {apiList[index].slice(2, 8).join(' ')}
          </>
        );

        break;
      default: undefined;
    }

    return (
      <CellMeasurer
        ref={item}
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <button
          onClick={(e) => handleSelection(e)}
          className="npi-list__row dropdown__row"
          style={style}
          data-value={data}
        >
          {listItemContent}
        </button>
      </CellMeasurer>
    );
  };

  return (
    <div className="doctor-search form-group with-dropdown">
      <label htmlFor="search" className="input-label">Search by Phone</label>
      <input
        type="text"
        name="search"
        className="input input__icon search js-dropdown"
        value={value}
        onChange={(e) => updateValue(phoneMask.resolve(e.target.value))}
        onKeyUp={(e) => debouncedFetchData(e.target.value)}
        ref={input}
        autoComplete="off"
      />
      <div className="dropdown-wrapper">
        <div className="dropdown select-dropdown npi-dropdown">
          {loading
            ? <span className="p-3">Loading...</span>
            : (
              <List
                ref={list}
                className="npi-list"
                height={300}
                overscanRowCount={10}
                noRowsRenderer={noRowsRenderer}
                rowCount={apiList.length}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                width={2000}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default NpiAutocomplete;
