import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateCart as updateCartAction } from '@actions/panel/cart_view/carts';
import { createIssue as createIssueAction } from '@actions/panel/cart_view/issues';

import { IssueModel, IssuesCollection } from '@models/issue';

import http from '@/utils/http';

class LostReasonForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIssues: [],
      errors: [],
      issues: new IssuesCollection(),
      newIssue: new IssueModel({
        status: 'open',
        stage: props.lostCart.stage,
        view: this.resolveDefaultView(),
        cart_id: props.lostCart.id,
        lost_reason: false,
      }),
    };
  }

  componentDidMount = () => {
    http.get(IssueModel.routes.cartIssuesPath(this.props.lostCart.id))
      .then((response) => {
        this.setState({ issues: new IssuesCollection(response.data) });
      });
  }

  resolveDefaultView = () => {
    const { issueOptions } = this.props;

    return _.snakeCase(issueOptions.views[this.stageKey()][0]);
  }

  stageKey = () => {
    const { lostCart } = this.props;

    if (lostCart.stage === 'fulfillment') {
      return 'order_fulfillment';
    } if (lostCart.branch === 'mailed' && lostCart.stage === 'request') {
      return 'mailed';
    } if (lostCart.branch === 'transfer' && lostCart.stage === 'request') {
      return 'transfer';
    }
    return lostCart.stage;
  }

  handleIssueCreate = (e) => {
    e.preventDefault();

    const { newIssue } = this.state;

    http.post(IssueModel.routes.issuesPath, { issue: newIssue.attributes })
      .then((response) => {
        window.flashMessages.addMessage({
          type: 'success',
          text: newIssue.createSuccessMessage(),
        });

        this.setState((prevState) => ({
          issues: new IssuesCollection([...prevState.issues.models, response.data]),
        }));
      })
      .catch(() => {
        window.flashMessages.addMessage({
          type: 'error',
          text: 'Issue cannot be created due to some errors.',
        });
      });
  }

  handleIssueAttributeChange = ({ target: { name, value } }) => {
    this.setState((prevState) => {
      const newIssue = new IssueModel({ ...prevState.newIssue });
      newIssue.set(name, value);

      return { newIssue };
    });
  }

  handleIssueSelect = ({ target: { checked, value } }) => {
    let selectedIssues;

    if (checked) {
      selectedIssues = [...this.state.selectedIssues, Number(value)];
    } else {
      selectedIssues = this.state.selectedIssues.filter((id) => id !== Number(value));
    }

    this.setState({ selectedIssues });
  }

  handleLostTransition = (e) => {
    e.preventDefault();

    const {
      lostCart,
      updateCart,
      onCancel,
      currentCartId,
    } = this.props;

    const { selectedIssues } = this.state;

    if (selectedIssues.length === 0) return;

    http.put(lostCart.routes.lostReasonPath, { issue_ids: selectedIssues })
      .then((response) => {
        Number(lostCart.id) === Number(currentCartId)
          ? window.location = lostCart.routes.userPath
          : updateCart(response.data);

        onCancel();
      })
      .catch((error) => {
        this.setState({ errors: error.response.data.errors });
      });
  }

  render() {
    const {
      issueOptions,
      onCancel,
    } = this.props;

    const {
      newIssue: {
        name,
        view,
      },
      selectedIssues,
      issues,
      errors,
    } = this.state;

    const issueNames = issueOptions.issues[_.snakeCase(view)] || [];

    return (
      <div className="lost-reason-form">
        <p className="alert alert-danger">{errors.join(' ')}</p>

        <div className="row flex-column">
          {issues.models.length > 0
            ? issues.models.map((issue) => (
              <div className="form-group form-check pl-0 pr-3" key={issue.id}>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`issue-${issue.id}`}
                  value={issue.id}
                  onChange={this.handleIssueSelect}
                  checked={selectedIssues.indexOf(issue.id) > -1}
                />

                <label
                  className="input-label checkbox-label"
                  htmlFor={`issue-${issue.id}`}
                >
                  {issue.name}
                </label>
              </div>
            ))
            : (
              <p>Please add an issue firstly to mark it as a lost reason.</p>
            )}
        </div>

        <form onSubmit={this.handleIssueCreate} className="form-row align-items-end mb-4">
          <div className="col-auto">
            <div className="form-group">
              <label className="input-label">View</label>

              <select
                value={view}
                onChange={this.handleIssueAttributeChange}
                name="view"
                className="input input-select bg-cover"
              >
                <option value="">Please select</option>
                {issueOptions.views[this.stageKey()].map((issueView) => (
                  <option key={issueView} value={issueView}>
                    {issueView}
                  </option>
                ))}

                <option key="lost" value="lost">
                  lost
                </option>
              </select>
            </div>
          </div>

          <div className="col-auto">
            <div className="form-group">
              <label className="input-label">Issue</label>
              <select
                value={name}
                name="name"
                className="input input-select bg-cover"
                onChange={this.handleIssueAttributeChange}
              >
                <option value="">Please select</option>
                {issueNames.map((issueName) => (
                  <option value={issueName} key={issueName}>
                    {issueName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-auto">
            <button type="submit" className="btn btn-primary btn-sm mb-3">Add Issue</button>
          </div>
        </form>

        <div className="row justify-content-end">
          <button
            type="reset"
            className="btn btn-sm btn-light mr-3"
            onClick={onCancel}
          >
            Cancel
          </button>

          <button
            type="submit"
            className="btn btn-sm btn-primary mr-3"
            onClick={this.handleLostTransition}
            disabled={selectedIssues.length === 0}
          >
            Move to Lost
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCart: (cart) => dispatch(updateCartAction(cart)),
  createIssue: (issue) => dispatch(createIssueAction(issue)),
});

export default connect(null, mapDispatchToProps)(LostReasonForm);
