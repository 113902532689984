import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PharmacyModel } from '@models/pharmacy';

import NpiAutocomplete from '@components/shared/NpiAutocomplete';
import PhoneInput from '@/components/shared/PhoneInput';
import TextInput from '@/components/shared/TextInput';

import http from '@/utils/http';

class PharmacyForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pharmacy: props.pharmacy,
      processing: false,
      edited: false,
    };
  }

  handleInputChange = ({ target: { name, value } }) => {
    this.setState((prevState) => {
      const pharmacy = new PharmacyModel({ ...prevState.pharmacy });
      pharmacy.set(name, value.toUpperCase());

      return { pharmacy, edited: true };
    });
  };

  handleSearch = (data) => {
    this.setState((prevState) => {
      const name = prevState.pharmacy.phone === data.phone ? prevState.pharmacy.name : '';
      const pharmacy = new PharmacyModel({ ...data, user_id: prevState.pharmacy.user_id, name });

      return { pharmacy, edited: true };
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();

    const { onSuccessSubmit } = this.props;
    const pharmacy = new PharmacyModel(this.state.pharmacy);

    this.setState({ processing: true });

    http.post(pharmacy.routes.instance, { pharmacy: pharmacy.attributes })
      .then((response) => {
        const newPharmacy = new PharmacyModel(response.data);
        onSuccessSubmit(newPharmacy);

        window.flashMessages.addMessage({ type: 'success', text: 'New pharmacy has been created successfully.' });
      })
      .catch((error) => {
        this.setState({ pharmacy: new PharmacyModel(error.response.data) });
      })
      .finally(() => this.setState({ processing: false }));
  }

  render() {
    const {
      pharmacy: {
        name,
        phone,
        label,
        fax,
        street_1: street1,
        street_2: street2,
        city,
        state,
        zip,
        store_number: storeNumber,
        errors,
      },
      processing,
      edited,
    } = this.state;

    return (
      <div className="pharmacy-dialog">
        <div className="row">
          <div className="pharmacy-dialog__search w-100">
            <NpiAutocomplete type="pharmacy" onSearch={this.handleSearch} />
          </div>
        </div>

        <form onSubmit={this.handleFormSubmit} className="pharmacy__form">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <TextInput
                  name="name"
                  label="Name"
                  type="text"
                  value={name || ''}
                  errors={errors.name}
                  onChange={this.handleInputChange}
                  icon="pharmacy"
                />
              </div>

              <div className="col-6">
                <PhoneInput
                  name="phone"
                  label="Phone"
                  type="tel"
                  value={phone || ''}
                  errors={errors.phone}
                  onChange={this.handleInputChange}
                  icon="phone"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <TextInput
                  name="label"
                  label="Label"
                  type="text"
                  value={label || ''}
                  errors={errors.label}
                  onChange={this.handleInputChange}
                  icon="date"
                />
              </div>

              <div className="col-6">
                <PhoneInput
                  name="fax"
                  label="Fax"
                  type="tel"
                  value={fax || ''}
                  errors={errors.fax}
                  onChange={this.handleInputChange}
                  icon="fax"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <TextInput
                  name="street_1"
                  label="Street"
                  type="text"
                  value={street1 || ''}
                  errors={errors.street_1}
                  onChange={this.handleInputChange}
                  icon="state"
                />
              </div>

              <div className="col-6">
                <TextInput
                  name="street_2"
                  label="Unit / Suite Number"
                  type="text"
                  value={street2 || ''}
                  errors={errors.street_2}
                  onChange={this.handleInputChange}
                  icon="address"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <TextInput
                  name="city"
                  label="City"
                  type="text"
                  value={city || ''}
                  errors={errors.city}
                  onChange={this.handleInputChange}
                  icon="city"
                />
              </div>

              <div className="col-6">
                <TextInput
                  name="state"
                  label="State"
                  type="text"
                  value={state || ''}
                  maxLength="2"
                  errors={errors.state}
                  onChange={this.handleInputChange}
                  icon="country"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <TextInput
                  name="zip"
                  label="ZIP"
                  type="text"
                  value={zip || ''}
                  errors={errors.zip}
                  onChange={this.handleInputChange}
                  icon="zip"
                />
              </div>

              <div className="col-6">
                <TextInput
                  name="store_number"
                  label="Store number"
                  type="text"
                  value={storeNumber || ''}
                  errors={errors.store_number}
                  onChange={this.handleInputChange}
                  icon="zip"
                />
              </div>
            </div>
          </div>


          <div className="col row justify-content-end">
            <button
              type="reset"
              className="btn btn-sm btn-light mr-3"
              onClick={this.props.onCancel}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-sm btn-primary"
              disabled={!edited || processing}
            >
              {processing ? 'Processing...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

PharmacyForm.propTypes = {
  onSuccessSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  pharmacy: PropTypes.instanceOf(PharmacyModel).isRequired,
};

export default PharmacyForm;
