import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CartModel } from '@models/cart';

import { updateCart } from '@actions/panel/cart_view/carts';

import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';
import CustomInput from '@components/shared/panel/CustomInput';

import http from '@/utils/http';

class QuestionsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCart: props.currentCart,
      submitDisabled: true,
    };
  }

  handleAttributeChange = ({ target }) => {
    this.setState((prevState) => {
      const currentCart = new CartModel({ ...prevState.currentCart });
      currentCart.set(target.name, target.type === 'checkbox' ? target.checked : target.value);

      return { currentCart, submitDisabled: false };
    });
  }

  submitUserInterview = () => {
    const { currentCart } = this.state;

    const params = {
      cart: {
        user_interview_attributes: currentCart.user_interview_attributes,
      },
    };

    http.put(currentCart.routes.byId, params)
      .then((response) => {
        this.props.updateCart(response.data);

        window.flashMessages.addMessage({
          type: 'success',
          text: 'Questions have been updated.',
        });

        this.setState({ submitDisabled: true });
      })
      .catch(() => {
        window.flashMessages.addMessage({
          type: 'error',
          text: 'Questions cannot be updated due to some errors.',
        });
      });
  }

  render() {
    const {
      currentCart: {
        user_interview_attributes,
      },
      submitDisabled,
    } = this.state;

    let info = '';

    if (this.props.currentCart.branch !== 'pharmacy_request') {
      info = <b>No questions for this cart</b>;
    }

    return (
      <div>
        <div className="questions-container">
          <CustomExpansionPanel
            id="questions-panel"
            headerText="Questions"
          >
            {info}
            {this.props.currentCart.branch === 'pharmacy_request' && (
            <div className="table-responsive">
              <table className="table table-striped">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Question</th>
                    <th scope="col">Answer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Have you spoken to your current doctor about erectile dysfunction?</td>
                    <td>
                      <input
                        type="checkbox"
                        name="user_interview_attributes[disfunction_consulted]"
                        checked={user_interview_attributes.disfunction_consulted || false}
                        onChange={this.handleAttributeChange}
                        id="disfunction_consulted"
                      />
                      <label htmlFor="disfunction_consulted" />
                    </td>
                  </tr>
                  <tr>
                    <td>Have you seen your doctor within a past year?</td>
                    <td>
                      <input
                        type="checkbox"
                        name="user_interview_attributes[consultation_within_last_year]"
                        checked={user_interview_attributes.consultation_within_last_year || false}
                        onChange={this.handleAttributeChange}
                        id="consultation_within_last_year"
                      />
                      <label htmlFor="consultation_within_last_year" />
                    </td>
                  </tr>
                  <tr>
                    <td>Which ED medication have you received a prescription for in the past year?</td>
                    <td>
                      <CustomInput
                        type="text"
                        className="form-control form-control-sm"
                        name="user_interview_attributes[previous_drugs]"
                        value={user_interview_attributes.previous_drugs || ''}
                        onChange={this.handleAttributeChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="row flex-row-reverse pr-3 pl-3">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  disabled={submitDisabled}
                  onClick={this.submitUserInterview}
                >
                  Save
                </button>
              </div>
            </div>
            )}

          </CustomExpansionPanel>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCart: new CartModel(state.carts.current_cart),
});

const mapDispatchToProps = (dispatch) => ({
  updateCart: (cart) => dispatch(updateCart(cart)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionsContainer);
