import moment from 'moment';

import { resolveRequestStageLabel } from '@/utils/panel';
import { BaseModel, BaseCollection } from './base';

class CartModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      product_name: '',
      branch: null,
      stage: 'open',
      step: 'base',
      rx_type: 'fresh',
      when_needed: 'now',
      quantity: 0,
      final_quantity: 0,
      max_quantity: null,
      price: 0,
      final_price: 0,
      total_price: 0,
      refunded_value: 0,
      have_discounts: false,
      terms_of_service: false,
      skip_payment: true,
      discountable: true,
      delivery_type: null,
      delivery_price: 0,
      final_delivery_price: 0,
      shipping_address_id: null,
      doctor_id: null,
      pharmacy_id: null,
      user_id: null,
      product_variant_id: null,
      order_id: null,
      landing_page_id: null,
      created_at: '',
      stage_set_at: '',
      submitted_at: '',
      manual_text_messages_count: 0,
      automated_text_messages_count: 0,
      discounts: [],
    };
  }

  get isPresent() {
    return this.id !== null;
  }

  get isBranchChangePossible() {
    return this.stage === 'open' && this.order_id === null;
  }

  get isEditable() {
    return this.order_id === null;
  }

  get extraQuantity() {
    return this.final_quantity - this.quantity;
  }

  get daysInStage() {
    return moment().endOf('day').diff(this.stage_set_at, 'days');
  }

  get discountPriceWithCurrency() {
    return `- $${(Number(this.price) - Number(this.final_price)).toFixed(2)}`;
  }

  get isFullyRefunded() {
    return Number(this.refunded_value) === Number(this.final_price);
  }

  get priceWithCurrency() {
    return `$${Number(this.price).toFixed(2)}`;
  }

  get finalDeliveryPriceWithCurrency() {
    return `$${Number(this.final_delivery_price).toFixed(2)}`;
  }

  get finalPriceWithCurrency() {
    return `$${Number(this.final_price).toFixed(2)}`;
  }

  get totalPriceWithCurrency() {
    return `$${Number(this.total_price).toFixed(2)}`;
  }

  get formattedCreatedAt() {
    return moment(this.created_at).format('L');
  }

  get isValidBeforeCreate() {
    return this.product_variant_id && this.delivery_type && this.quantity > 0 && this.branch;
  }

  get optionText() {
    return [
      this.id,
      this.product_name,
      this.branchName,
      resolveRequestStageLabel(this.stage, this.branch),
    ].join(' • ');
  }

  get totalDays() {
    return this.submitted_at ? moment().endOf('day').diff(this.submitted_at, 'days') : '';
  }

  get branchName() {
    return _.startCase(this.branch);
  }

  get stageName() {
    return _.startCase(this.stage);
  }

  get additionalQuantity() {
    return this.final_quantity - this.quantity;
  }

  get routes() {
    const baseCartPath = `/users/${this.user_id}/carts/${this.id}`;

    return {
      all: `/users/${this.user_id}/carts`,
      byId: baseCartPath,
      changeStage: `${baseCartPath}/change_stage`,
      lostReasonPath: `${baseCartPath}/lost_reason`,
      userPath: `/users/${this.user_id}`,
      shipping_cost: `/users/${this.user_id}/carts/shipping_cost`,
    };
  }
}

class CartsCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return CartModel;
  }

  filterByOrderId(id) {
    return this.models.filter((cart) => cart.order_id === Number(id));
  }

  filterByGroup(group) {
    switch (group) {
      case 'open':
        return this.models.filter((cart) => !['completed', 'lost'].includes(cart.stage));
      case 'lost':
        return this.models.filter((cart) => cart.stage === 'lost');
      case 'discountable':
        return this.models.filter((cart) => cart.discountable);
      default:
        return this.models;
    }
  }
}

export { CartModel, CartsCollection };
