import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CartModel, CartsCollection } from '@models/cart';

import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';
import FormDialog from '@components/shared/FormDialog';
import CartBranchSelector from './CartBranchSelector';
import CartStagesTimeline from './CartStagesTimeline';
import OpenCartsList from './OpenCartsList';
import LostReasonForm from './LostReasonForm';

class OpenCartsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      localCart: props.currentCart,
      lostReasonDialog: false,
      telemedicineLostDialog: false,
    };
  }

  openLostReasonDialog = (cart) => {
    let telemedicineConfirmation = true;

    if (cart.stage === 'completed' && !cart.isFullyRefunded) {
      window.flashMessages.addMessage({
        type: 'error',
        text: 'Cart must be fully refunded before stage transition to lost',
      });

      return;
    }

    // Show the confirmation alert only if its a telemedicine cart at request
    if (cart.branch === "telemedicine" && cart.stage === "request") {
      this.setState({ telemedicineLostDialog: true });
    }

    else if (telemedicineConfirmation) {
      this.setState({ localCart: cart, lostReasonDialog: true });
    }
  }

  onConfirmTelemedicineLost = () => {
    this.setState({ lostReasonDialog: true, telemedicineLostDialog: false });
  }

  closeTelemedicinedialog = () => {
    this.setState({ telemedicineLostDialog: false });
  }

  closeLostReasonDialog = () => {
    this.setState({ lostReasonDialog: false });
  }

  render() {
    const {
      currentCart,
      openCarts,
      productVariants,
      deliveryOptions,
      issueOptions,
    } = this.props;

    const {
      localCart,
      lostReasonDialog,
      telemedicineLostDialog,
    } = this.state;

    return (
      <div className="open-carts-container user-cart-section">
        {currentCart.isPresent && (
          <>
            <div className="mb-1">
              <CartBranchSelector currentCart={currentCart} />
            </div>

            <div className="mb-1">
              <CartStagesTimeline
                currentCart={currentCart}
                onLostSelect={() => this.openLostReasonDialog(currentCart)}
              />
            </div>
          </>
        )}

        <div className="mb-1">
          <CustomExpansionPanel
            id="open-carts-panel"
            headerText="Open Carts"
          >
            <OpenCartsList
              openCarts={openCarts}
              productVariants={productVariants}
              deliveryOptions={deliveryOptions}
              onLostSelect={(cart) => this.openLostReasonDialog(cart)}
            />
          </CustomExpansionPanel>
        </div>

        <FormDialog
          title={"Are you sure you want to change this cart to Lost?"}
          isOpen={telemedicineLostDialog}
          onClose={() => this.closeLostReasonDialog()}
        >
          <div className="row justify-content-end">
            <button
              className="btn btn-sm btn-light mr-3"
              onClick={() => this.closeTelemedicinedialog()}
            >
              Cancel
            </button>
            <button
              className="btn btn-sm btn-primary mr-3"
              onClick={() => this.onConfirmTelemedicineLost()}
              >
              Confirm
            </button>
          </div>
        </FormDialog>

        <FormDialog
          title={`Please provide lost reason for Cart #${localCart.id}`}
          isOpen={lostReasonDialog}
          onClose={() => this.closeLostReasonDialog()}
        >
          <LostReasonForm
            lostCart={localCart}
            currentCartId={currentCart.id}
            issueOptions={issueOptions}
            onCancel={() => this.closeLostReasonDialog()}
          />
        </FormDialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCart: new CartModel(state.carts.current_cart),
  openCarts: new CartsCollection(state.carts.all_carts),
  issueOptions: state.issues.issue_options,
});

export default connect(mapStateToProps)(OpenCartsContainer);
