import React, { Component } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

class InlineEditButtons extends Component {
  render() {
    const {
      approveAction,
      cancelAction,
      processing,
    } = this.props;

    const approveBtnColor = processing ? 'secondary' : 'success';

    return (
      <span className="inline-edit-buttons">
        <button
          className={`btn btn-outline-${approveBtnColor} btn-sm mx-1`}
          onClick={approveAction}
          disabled={processing}
        >
          {processing ? <NotInterestedIcon fontSize="small" /> : <CheckIcon fontSize="small" />}
        </button>
        <button
          className="btn btn-outline-danger btn-sm mx-1"
          onClick={cancelAction}
        >
          <CloseIcon fontSize="small" />
        </button>
      </span>
    );
  }
}

export default InlineEditButtons;
