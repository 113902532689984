import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UserModel } from '@models/user';
import { CartModel } from '@models/cart';
import { PharmacyModel, PharmaciesCollection } from '@models/pharmacy';
import PharmacistDetail from '@models/pharmacist_detail';

import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';

import FormDialog from '@components/shared/FormDialog';

import PharmaciesList from './PharmaciesList';
import PharmacyForm from './PharmacyForm';
import PharmacistDetailForm from './PharmacistDetailForm';

class PharmaciesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormDialogOpen: false,
      pharmacyTemplates: new PharmaciesCollection(props.pharmacies.pharmacy_templates),
      currentPharmacy: new PharmacyModel(props.pharmacies.current_pharmacy),
    };
  }

  handleAddNew = () => {
    this.setState({ isFormDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isFormDialogOpen: false });
  };

  updateCurrentPharmacy = (updatedCurrentPharmacy) => {
    this.setState({ currentPharmacy: updatedCurrentPharmacy });
  }

  handleSuccessSubmit = (newPharmacy) => {
    this.setState((prevState) => {
      prevState.pharmacyTemplates.models.push(newPharmacy);
      return { pharmacyTemplates: prevState.pharmacyTemplates, isFormDialogOpen: false };
    });
    this.setState({ isFormDialogOpen: false });
  }

  render() {
    const {
      user,
      productVariants,
      pharmacistDetail,
      currentCart,
      admins,
    } = this.props;

    const {
      isFormDialogOpen,
      pharmacyTemplates,
      currentPharmacy,
    } = this.state;

    const pharmacistDetailInstance = _.isEmpty(pharmacistDetail)
      ? new PharmacistDetail({
        cart_id: currentCart.id,
        product_id: currentCart.prescribed_product_id,
        product_variant_id: currentCart.prescribed_product_variant_id,
      })
      : new PharmacistDetail(pharmacistDetail);

    return (
      <div className="pharmacies-container user-cart-section">
        <CustomExpansionPanel
          id="pharmacy-panel"
          headerText="Pharmacies"
        >
          <div className="panel__content w-100">
            <div className="d-flex justify-content-end p-2">
              <button
                type="button"
                className="btn btn-sm btn-outline-primary mx-2"
                onClick={this.handleAddNew}
              >
                New pharmacy
              </button>
            </div>

            <PharmaciesList
              pharmacyTemplates={pharmacyTemplates}
              currentPharmacy={currentPharmacy}
              updateCurrentPharmacy={this.updateCurrentPharmacy}
            />

            {currentCart.id && currentCart.branch === 'transfer' && (
              <>
                <h4 className="text-red mt-3 mb-2">Pharmacist Section</h4>
                <PharmacistDetailForm
                  pharmacistDetail={pharmacistDetailInstance}
                  productVariants={productVariants}
                  admins={admins}
                />
              </>
            )}
          </div>
        </CustomExpansionPanel>

        <FormDialog
          title="Enter new pharmacy"
          isOpen={isFormDialogOpen}
          onClose={this.handleCloseDialog}
          customClass="dialog__wide"
        >
          <PharmacyForm
            pharmacy={new PharmacyModel({ user_id: user.id })}
            onCancel={this.handleCloseDialog}
            onSuccessSubmit={this.handleSuccessSubmit}
          />
        </FormDialog>
      </div>
    );
  }
}

PharmaciesContainer.propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
};

const mapStateToProps = (state) => ({
  user: new UserModel(state.user),
  currentCart: new CartModel(state.carts.current_cart),
});

export default connect(
  mapStateToProps,
)(PharmaciesContainer);
