import React, { Component } from 'react';

class CustomSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      displayName: '',
    };
  }

  componentDidMount = () => {
    const {
      options,
      value,
      name,
      showName,
    } = this.props;

    document.addEventListener('click', (event) => {
      if ((
        !$(event.target).closest(`[name="${name}"]`).length
        && !$(event.target).is(`[name="${name}"]`)
      ) && (
        !$(event.target).closest(`[name="${name}_name"]`).length
        && !$(event.target).is(`[name="${name}"]_name`)
      )) {
        this.setState({ isOpen: false });
      }
    });
    if (showName) {
      const defaultName = options.filter((item) => item.value === value);
      this.setState({
        displayName: defaultName[0].name,
      });
    }
  };

  handleSelectToggle = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleOptionSelect = (e) => {
    e.preventDefault();

    this.props.onSelect(e.target.dataset.value, this.props.name);
    this.setState({ isOpen: false, displayName: e.target.dataset.name });
  };

  renderInput = () => {
    const {
      props: {
        name, value, isRequired, disabled, showName = false,
      },
      state: { isOpen, displayName },
    } = this;

    if (showName) {
      return (
        <>
          <input
            name={name}
            required={isRequired}
            value={value}
            readOnly
            type="hidden"
          />
          <input
            name={`${name}_name`}
            className={`input input-select ${isOpen ? 'touched' : ''}`}
            readOnly
            onFocus={() => this.setState({ isOpen: true })}
            onClick={() => this.handleSelectToggle}
            defaultValue={displayName}
            required={isRequired}
            disabled={disabled}
          />
        </>
      );
    }
    return (
      <input
        name={name}
        className={`input input-select ${isOpen ? 'touched' : ''}`}
        readOnly
        onFocus={() => this.setState({ isOpen: true })}
        onClick={() => this.handleSelectToggle}
        value={value}
        required={isRequired}
        disabled={disabled}
      />
    );
  }

  render() {
    const {
      props: {
        errors, options, label, name, customClass,
      },
    } = this;

    return (
      <div className={`select custom-dropdown ${customClass}`}>
        {label && (
          <label className="input-label dropdown-label" htmlFor={name}>
            {label}
          </label>
        )}

        {this.renderInput()}
        <div className="select-dropdown">
          {options.map((option) => (
            <button
              key={option.name}
              onClick={this.handleOptionSelect}
              data-value={option.value || option.name}
              data-name={option.name}
            >
              {option.name}
            </button>
          ))}
        </div>
        {errors && errors.length > 0 && (
          <span className="input__error">{errors[0]}</span>
        )}
      </div>
    );
  }
}

export default CustomSelect;
