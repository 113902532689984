import http from '@/utils/http';

export function setupRequestHeaders(apiKey) {
  return { headers: { Authorization: apiKey } };
}

export function continueToNextStep(apiKey, nextStepPath, processingFlag) {
  http
    .put(nextStepPath, {}, setupRequestHeaders(apiKey))
    .then((res) => {
      processingFlag(true);
      window.location.href = res.data.redirect_url;
    })
}

export function getCameraPermissions (setPermModal) {
  let localStream = null;

  setPermModal(true);

  navigator.mediaDevices.getUserMedia({ video: true }).then(response => {
    localStream = response;
  })
  .catch(error => console.log(error))
  .finally(() => {
    setPermModal(false);
  });
}
