import React, { Component } from 'react';

import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';
import ReactTable from 'react-table';

class LogsContainer extends Component {
  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
  }

  render() {
    const {
      userVersions,
    } = this.props;

    function changesCell({ value }) {
      return (
        <span
          style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
          data-toggle="tooltip"
          data-side="top"
          data-html="true"
          data-placement="auto"
          title={value}
        >
          {value}
        </span>
      );
    }

    function modifiedByCell({ value }) {
      return(
        <span>{value || 'AUM Robot'}</span>
      )
    }

    function createdAtCell({ value }) {
      const createdAtDate = new Date(value);
      return (
        <span>
          {createdAtDate.getMonth() + 1}/{createdAtDate.getDate()}/{createdAtDate.getFullYear()}
        </span>
      );
    }

    const columns = [
      {
        Header: 'Section',
        accessor: 'item_type',
      },
      {
        Header: 'Action',
        accessor: 'event',
      },
      {
        Header: 'Change',
        accessor: 'changes',
        Cell: changesCell,
      },
      {
        Header: 'Modified by',
        accessor: 'whodunnit',
        Cell: modifiedByCell,
      },
      {
        Header: 'Modified time',
        accessor: 'created_at',
        Cell: createdAtCell,
      },
    ];

    return (
      <div className="logs-container user-cart-section">
        <CustomExpansionPanel
          id="logs-panel"
          headerText="Logs"
        >

          <ReactTable
            minRows={1}
            columns={columns}
            data={userVersions}
            defaultPageSize={10}
            pageSizeOptions={[10, 30, 50]}
            className="-striped -highlight"
          />

        </CustomExpansionPanel>
      </div>
    );
  }
}

export default LogsContainer;
