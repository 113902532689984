import React, { Component } from "react";
import moment from "moment";
import CustomExpansionPanel from "@components/shared/panel/CustomExpansionPanel";

const Utm = ({ utmData }) => {
  return (
    <div className="utm-info-container user-cart-section">
      <CustomExpansionPanel id="open-recordings-panel" headerText="UTM Info">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="thead-light">
              <tr>
                <th scope="col">Source</th>
                <th scope="col">Ad ID</th>
                <th scope="col">Ad Name</th>
                <th scope="col">Campaign ID</th>
                <th scope="col">Campaign Name</th>
                <th scope="col">Ad Set ID</th>
                <th scope="col">Site Source</th>
                <th scope="col">Placement</th>
              </tr>
            </thead>
            <tbody>
              {utmData && (
                <tr>
                  <td>{utmData.source}</td>
                  <td>{utmData.ad_id}</td>
                  <td>{utmData.ad_name}</td>
                  <td>{utmData.ad_campaign_id}</td>
                  <td>{utmData.campaign_name}</td>
                  <td>{utmData.campaign_name}</td>
                  <td>{utmData.ad_set_id}</td>
                  <td>{utmData.placement}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CustomExpansionPanel>
    </div>
  );
};

export default Utm;
