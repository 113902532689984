// https://tc39.github.io/ecma262/#sec-array.prototype.find
const Polyfills = () => {
  if ('NodeList' in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
      // eslint-disable-next-line no-param-reassign
      thisArg = thisArg || window;
      for (let i = 0; i < this.length; i += 1) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }
};

export default Polyfills;
