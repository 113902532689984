import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ProductVariantsCollection } from '@models/product_variant';
import PharmacistDetailModel from '@models/pharmacist_detail';

import DateInput from '@components/shared/DateInput';
import TextInput from '@/components/shared/TextInput';

import http from '@/utils/http';

class PharmacistDetailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pharmacistDetail: props.pharmacistDetail,
      allVariants: new ProductVariantsCollection(props.productVariants),
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const { pharmacistDetail } = prevState;
      pharmacistDetail.set(name, value);
      return { pharmacistDetail };
    });
  };

  handleProductChange = (event) => {
    const { target } = event;
    const selectedOption = target.options[target.selectedIndex];

    this.setState((prevState) => {
      const { pharmacistDetail } = prevState;
      pharmacistDetail.set('product_id', selectedOption.getAttribute('data-product-id'));
      pharmacistDetail.set('product_variant_id', selectedOption.getAttribute('data-product-variant-id'));

      return { pharmacistDetail };
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    const { pharmacistDetail } = this.state;
    const httpMethod = pharmacistDetail.id ? 'put' : 'post';

    http({
      method: httpMethod,
      url: pharmacistDetail.routes.instance,
      data: { pharmacist_detail: pharmacistDetail.attributes },
    }).then((response) => {
      const newPharmacistDetail = new PharmacistDetailModel(response.data);
      this.setState({ pharmacistDetail: newPharmacistDetail });
      window.flashMessages.addMessage({ type: 'success', text: 'Pharmacist section has been updated' });
    }).catch((error) => {
      const invalidPharmacistDetail = new PharmacistDetailModel(error.response.data);
      this.setState({ pharmacistDetail: invalidPharmacistDetail });
    });
  }

  render() {
    const {
      pharmacistDetail: {
        transfer_status,
        product_id,
        product_variant_id,
        quantity,
        refills,
        original_refills,
        total_fills,
        directions,
        rx,
        from_pharmacist,
        to_pharmacist_id,
        original_date_at,
        first_fill,
        last_fill,
        errors,
      },
      allVariants,
    } = this.state;

    const { admins } = this.props;
    const drug_id = _.compact([product_id, product_variant_id]).join('_');

    return (
      <form onSubmit={this.handleFormSubmit} className="pharmacist_detail__form">
        <div>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label htmlFor="transfer-status">Transfer Status</label>
                <select
                  className={`input input-select bg-contain ${errors.transfer_status && 'is-invalid'}`}
                  name="transfer_status"
                  value={transfer_status || ''}
                  onChange={this.handleInputChange}
                  required
                >
                  <option value="open">Open</option>
                  <option value="in_progress">In Progress</option>
                  <option value="faxed">Faxed</option>
                  <option value="completed">Completed</option>
                  <option value="issue">Issue</option>
                  <option value="rejected">Rejected</option>
                </select>
                {errors.transfer_status && <div className="invalid-feedback">{errors.transfer_status[0]}</div>}
              </div>
            </div>

            <div className="col-4">
              <label className="input-label" htmlFor="product_variant_id">
                Drug
              </label>
              <select
                className={`input input-select bg-contain ${errors.product_variant_id && 'is-invalid'}`}
                name="product_variant_id"
                value={drug_id}
                onChange={this.handleProductChange}
                required={transfer_status === 'completed'}
              >
                <option value="" />
                {allVariants.selectableProducts.map((product) => (
                  <option
                    key={`product_${product.id}`}
                    data-product-id={product.id}
                    value={product.id}
                  >
                    {product.name}
                  </option>
                ))}
                {allVariants.models.map((productVariant) => (
                  <option
                    key={`variant_${productVariant.id}`}
                    data-product-variant-id={productVariant.id}
                    data-product-id={productVariant.product_id}
                    value={`${productVariant.product_id}_${productVariant.id}`}
                  >
                    {productVariant.fullname}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-4">
              <TextInput
                name="quantity"
                label="Quantity"
                type="number"
                value={_.isNil(quantity) ? '' : quantity}
                errors={errors.quantity}
                onChange={this.handleInputChange}
                isRequired={transfer_status === 'completed'}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <TextInput
                name="refills"
                label="Refills remaining"
                type="number"
                value={_.isNil(refills) ? '' : refills}
                errors={errors.refills}
                onChange={this.handleInputChange}
                isRequired={transfer_status === 'completed'}
              />
            </div>

            <div className="col-4">
              <TextInput
                name="original_refills"
                label="Original Refills"
                type="number"
                value={_.isNil(original_refills) ? '' : original_refills}
                errors={errors.original_refills}
                onChange={this.handleInputChange}
              />
            </div>

            <div className="col-4">
              <TextInput
                name="total_fills"
                label="Total fills"
                type="number"
                value={_.isNil(total_fills) ? '' : total_fills}
                errors={errors.total_fills}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <TextInput
                name="directions"
                label="Directions"
                type="text"
                value={directions || ''}
                errors={errors.directions}
                onChange={this.handleInputChange}
                isRequired={transfer_status === 'completed'}
              />
            </div>

            <div className="col-4">
              <TextInput
                name="rx"
                label="Rx #"
                type="number"
                value={_.isNil(rx) ? '' : rx}
                errors={errors.rx}
                onChange={this.handleInputChange}
                isRequired={transfer_status === 'completed'}
              />
            </div>

            <div className="col-4">
              <TextInput
                name="from_pharmacist"
                label="From pharmacist"
                type="text"
                value={from_pharmacist || ''}
                errors={errors.from_pharmacist}
                onChange={this.handleInputChange}
                isRequired={transfer_status === 'completed'}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <label className="input-label" htmlFor="to_pharmacist_id">
                To pharmacist
              </label>
              <select
                className={`input input-select bg-contain ${errors.to_pharmacist_id && 'is-invalid'}`}
                name="to_pharmacist_id"
                value={to_pharmacist_id || ''}
                onChange={this.handleInputChange}
                required={transfer_status === 'completed'}
              >
                <option value="" />
                {admins.map((admin) => (
                  <option
                    key={admin.id}
                    value={admin.id}
                  >
                    {admin.fullname}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-4">
              <DateInput
                name="original_date_at"
                label="Original date"
                value={original_date_at || ''}
                errors={errors.original_date_at}
                onChange={this.handleInputChange}
                required={transfer_status === 'completed'}
              />
            </div>

            <div className="col-4">
              <DateInput
                name="first_fill"
                label="1st fill"
                value={first_fill || ''}
                errors={errors.first_fill}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <DateInput
                name="last_fill"
                label="Last fill"
                value={last_fill || ''}
                errors={errors.last_fill}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="form-actions text-right">
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </form>
    );
  }
}

PharmacistDetailForm.propTypes = {
  pharmacistDetail: PropTypes.instanceOf(PharmacistDetailModel).isRequired,
};

export default PharmacistDetailForm;
