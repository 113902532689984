import React, { Component } from 'react';

import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { MailsCollection } from '@models/mail';

import { resolveRequestStageLabel } from '@/utils/panel';

class MailsList extends Component {
  render() {
    const { mails } = this.props;

    const columns = [
      {
        Header: 'To',
        accessor: 'user_fullname',
      },
      {
        Header: 'Subject',
        accessor: 'subject',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Category',
        accessor: 'branch',
      },
      {
        Header: 'Stage',
        accessor: 'stage',
        Cell: (props) => (
          <span title={props.value}>
            {resolveRequestStageLabel(props.value, props.original.branch)}
          </span>
        ),
      },
      {
        Header: 'Date',
        accessor: 'send_date',
      },

      {
        Header: 'Time',
        accessor: 'send_hour',
      },
      {
        Header: 'Sent by',
        accessor: 'admin_email',
        Cell: (props) => (
          <span title={props.value}>{props.value}</span>
        ),
      },
      {
        Header: 'End Pt',
        accessor: 'email',
        Cell: (props) => (
          <span title={props.value}>{props.value}</span>
        ),
      },
      {
        Header: 'By',
        accessor: 'admin_fullname',
      },
    ];

    return (
      <ReactTable
        minRows={1}
        columns={columns}
        data={mails.models}
        defaultPageSize={10}
        pageSizeOptions={[10, 30, 50]}
        className="-striped -highlight"
      />
    );
  }
}

MailsList.propTypes = {
  mails: PropTypes.instanceOf(MailsCollection).isRequired,
};

export default MailsList;
