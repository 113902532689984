import React, { Component } from "react";
import { connect } from "react-redux";

import { CartModel } from "@models/cart";

import Icon from "@material-ui/core/Icon";
import { showNoteForm, hideNoteForm } from "@actions/panel/cart_view/triggers";
import { searchNotes } from "@actions/panel/cart_view/notes";
import { NoteModel } from "@models/note";
import NoteSearch from "./NoteSearch";
import NoteForm from "./NoteForm";
import NotesList from "./NotesList";
import { filterNotes } from "./NoteFilterService";

class NotesContainer extends Component {
  constructor(props) {
    super(props);

    const noteType = this.props.triggers.issueId ? "issue" : "all";
    this.showNotes(noteType);
    this.state = {
      noteType,
    };
  }

  toggleForm = () => {
    if (this.props.triggers.showNoteForm) {
      this.props.hideNoteForm();
    } else {
      this.props.showNoteForm();
    }
  };

  hideForm = () => {
    this.props.hideNoteForm();
  };

  getNotes = (noteType) => {
    this.setState({ noteType });
    this.showNotes(noteType);
  };

  showNotes = (noteType) => {
    const note = new NoteModel();
    const searchQuery = note.searchQuery({
      currentUserId: this.props.currentUserId,
      cartId: this.props.currentCart.id,
      noteType,
    });

    filterNotes(note, searchQuery, this.props.searchNotes);
  };

  render() {
    const { id: currentCartId } = this.props.currentCart;
    const { currentUserId, triggers } = this.props;
    const { noteType } = this.state;
    const showForm = triggers.showNoteForm;

    return (
      <>
        <NoteSearch
          currentUserId={currentUserId}
          cartId={currentCartId}
          noteType={noteType}
        />
        <div className="row note__header mb-2">
          <div>Notes</div>

          <div
            onClick={this.toggleForm}
            className={`note__toggle_add ${showForm ? "is-open" : ""}`}>
            {!showForm && <Icon>{showForm ? "close" : "add"}</Icon>}
          </div>

          <div id="note__form_wrapper" />

          {showForm && (
            <NoteForm
              onClose={this.hideForm}
              onSuccessSubmitForm={this.hideForm}
              cartId={currentCartId}
            />
          )}
        </div>
        <div className="row">
          <button
            className={`btn btn-note ${
              this.state.noteType === "note" && "active"
            }`}
            onClick={() => this.getNotes("note")}>
            Note
          </button>
          <button
            className={`btn btn-call ${
              this.state.noteType === "call" && "active"
            }`}
            onClick={() => this.getNotes("call")}>
            Call
          </button>
          <button
            className={`btn btn-issue ${
              this.state.noteType === "issue" && "active"
            }`}
            onClick={() => this.getNotes("issue")}>
            Issue
          </button>
          <button
            className={`btn btn-email ${
              this.state.noteType === "email" && "active"
            }`}
            onClick={() => this.getNotes("email")}>
            Email
          </button>
          <button
            className={`btn btn-text ${
              this.state.noteType === "text" && "active"
            }`}
            onClick={() => this.getNotes("text")}>
            Text
          </button>
          <button
            className={`btn btn-fax ${
              this.state.noteType === "fax" && "active"
            }`}
            onClick={() => this.getNotes("fax")}>
            Fax
          </button>
          <button
            className={`btn btn-all ${
              this.state.noteType === "all" && "active"
            }`}
            onClick={() => this.getNotes("all")}>
            All
          </button>
        </div>
        <NotesList />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  searchNotes: (notes) => dispatch(searchNotes(notes)),
  showNoteForm: () => dispatch(showNoteForm()),
  hideNoteForm: () => dispatch(hideNoteForm()),
});

const mapStateToProps = (state) => ({
  currentCart: new CartModel(state.carts.current_cart),
  triggers: state.triggers,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesContainer);
