class BaseModel {
  errors = {};

  constructor(attributes = {}) {
    _.defaultsDeep(this, attributes, { ...this.defaults() });
  }

  get attributes() {
    const { errors, ...attrs } = this;
    return attrs;
  }

  set(property, value) {
    _.set(this, property, value);
  }

  createSuccessMessage() {
    return `New ${this.modelName()} has been created successfully.`;
  }

  createErrorMessage() {
    return `New ${this.modelName()} cannot be created due to some errors.`;
  }
}

class BaseCollection {
  constructor(items = []) {
    this.models = items.map((item) => new (this.model())(item));
  }

  findById(id) {
    return this.models.find((item) => item.id === Number(id));
  }
}

export { BaseModel, BaseCollection };
