import React, { Component } from 'react';
import { connect } from 'react-redux';

import { DiscountModel } from '@models/discount';
import { DiscountTemplateModel } from '@models/discount_template';
import { UserModel } from '@models/user';

import DiscountForm from './DiscountForm';
import DiscountTemplateForm from './DiscountTemplateForm';

class FromContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formType: props.formType,
    };
  }

  setFormType(type) {
    this.setState({ formType: type });
  }

  render() {
    const { formType } = this.state;
    const {
      globalDiscountTemplates,
      discountTemplates,
      productVariants,
      onCancel,
      user,
      currentCart,
      handleDiscountSuccessSubmit,
      handleDiscountTemplateSuccessSubmit,
      adminRole,
      refreshCart,
      discountableCarts,
    } = this.props;

    const CartId = currentCart.id || (discountableCarts[0] && discountableCarts[0].id);
    const discountModel = new DiscountModel({ cart_id: CartId });

    const discountTemplateModel = new DiscountTemplateModel({
      user_id: user.id,
      landing_page_id: user.landing_page_id,
    });

    return (
      <>
        { adminRole === 'superadmin' && (
          <div className="py-2 row">
            <button
              className={`w-50 btn btn-sm btn-primary  mr-2 ${formType === 'discount' && 'active'}`}
              onClick={() => this.setFormType('discount')}
            >
              Discount
            </button>

            <button
              className={`w-50 btn btn-sm btn-primary ${formType === 'discount_template' && 'active'}`}
              onClick={() => this.setFormType('discount_template')}
            >
              Discount template
            </button>
          </div>
        )}
        <div className="container-discount-dialog mb-2">
          {formType === 'discount' && (
            <DiscountForm
              globalDiscountTemplates={globalDiscountTemplates}
              discountTemplates={discountTemplates}
              discount={discountModel}
              onCancel={onCancel}
              handleDiscountSuccessSubmit={handleDiscountSuccessSubmit}
              refreshCart={refreshCart}
              discountableCarts={discountableCarts}
            />
          )}
          {formType === 'discount_template' && (
            <DiscountTemplateForm
              globalDiscountTemplates={globalDiscountTemplates}
              productVariants={productVariants}
              discountTemplate={discountTemplateModel}
              onCancel={onCancel}
              handleDiscountTemplateSuccessSubmit={handleDiscountTemplateSuccessSubmit}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: new UserModel(state.user),
  currentCart: state.carts.current_cart,
});

export default connect(mapStateToProps)(FromContainer);
