import React from 'react';

import { resolveRequestStageLabel } from '@/utils/panel';

const SummaryItem = (props) => {
  const {
    cart: {
      id,
      product_name,
      branch,
      branchName,
      stage,
      manual_text_messages_count,
      manual_user_mails_count,
      manual_faxes_count,
      automated_text_messages_count,
      automated_user_mails_count,
      automated_faxes_count,
      daysInStage,
      totalDays,
      finalPriceWithCurrency,
    },
  } = props;

  return (
    <tr>
      <td>{id}</td>
      <td>{product_name}</td>
      <td>{branchName}</td>
      <td>{resolveRequestStageLabel(stage, branch)}</td>
      <td>0</td>
      <td>{manual_text_messages_count}</td>
      <td>{manual_user_mails_count}</td>
      <td>{manual_faxes_count}</td>
      <td>0</td>
      <td>{automated_text_messages_count}</td>
      <td>{automated_user_mails_count}</td>
      <td>{automated_faxes_count}</td>
      <td>{daysInStage}</td>
      <td>{totalDays}</td>
      <td>{finalPriceWithCurrency}</td>
    </tr>
  );
};

export default SummaryItem;
