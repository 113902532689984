import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UserModel } from '@models/user';
import { CartModel } from '@models/cart';
import { MailModel } from '@models/mail';
import { searchNotes } from '@actions/panel/cart_view/notes';
import TextAreaInput from '@/components/shared/TextAreaInput';
import TextInput from '@/components/shared/TextInput';
import { updateNotes } from '@/components/panel/notes/NoteFilterService';

import http from '@/utils/http';

class MailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mail: this.prepareMail(),
      emailOptions: this.prepareEmailOptions(),
      processing: false,
      edited: false,
    };
  }

  prepareEmailOptions = () => {
    const emailOptions = [];
    emailOptions.push({
      name: this.props.user.fullNameWithEmailVerification(),
      email: this.props.user.email,
    });

    return emailOptions;
  }

  prepareMail = () => new MailModel({
    cart_id: this.props.cart.id,
    user_id: this.props.user.id,
    stage: 'open',
    email: this.props.user.email,
  })

  handleChange = ({ target: { name, value } }) => {
    this.setState((prevState) => {
      const mail = new MailModel({ ...prevState.mail.attributes });
      mail.set(name, value);

      return { mail, edited: true };
    });
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    const { mail, template_name } = this.state;
    const { onSuccessSubmit } = this.props;

    this.setState({ processing: true });

    const mail_attributes = mail.attributes;
    mail_attributes.email_type = template_name;

    http.post(mail.routes.all, { user_mail: mail_attributes })
      .then((response) => {
        const newMail = new MailModel(response.data);
        onSuccessSubmit(newMail);
        updateNotes(this.props.user.id,
          this.props.currentCart.id,
          this.props.searchNotes);
        window.flashMessages.addMessage({
          type: 'success',
          text: mail.createSuccessMessage(),
        });
      }).catch((error) => {
        this.setState({ mail: new MailModel(error.response.data) });

        window.flashMessages.addMessage({
          type: 'error',
          text: mail.createErrorMessage(),
        });
      }).finally(() => this.setState({ processing: false }));
  }

  handleTemplateChange = ({ target: { value } }) => {
    const { mail } = this.state;
    const { user } = this.props;

    this.setState({ template_name: value });

    if (value === '') {
      mail.mail_content = '';
      mail.subject = '';
      this.setState({ mail, edited: true });

      return;
    }

    http.get(mail.routes.preview, { params: { template: value, user_id: user.id } })
      .then((response) => {
        mail.mail_content = response.data;
        mail.subject = this.prepareSubject(value);
        this.setState({ mail, edited: true });
      }).catch(() => {
        window.flashMessages.addMessage({
          type: 'error',
          text: 'We could not prepare mail template - try again',
        });
      });
  }

  prepareSubject = (templateName) => {
    let subject = '';

    const mailTemplate = this.props.mailTemplates.find((template) => template.name === templateName);
    subject = mailTemplate.title;

    return subject;
  }

  render() {
    const { emailOptions, processing, edited } = this.state;
    const { mailTemplates, onCancel } = this.props;

    const {
      mail: {
        subject,
        mail_content,
        email,
        errors,
      },
    } = this.state;


    let mailTemplatesToDisplay = mailTemplates;
    if (this.props.cart.id === null) {
      mailTemplatesToDisplay = mailTemplates.filter((t) => t.name === 'welcome_email');
    }

    return (
      <div>
        <form onSubmit={this.handleFormSubmit} className="mail__form">
          <div className="container px-0">

            <div className="form-group">
              <label htmlFor="contact_info" className="input-label">Contact info</label>
              <select
                name="email"
                onChange={this.handleChange}
                defaultValue={email}
                className="input input-select"
              >
                <option value="">Please select</option>
                {emailOptions.map((emailAddress) => (
                  <option
                    key={emailAddress.email}
                    value={emailAddress.email}
                  >
                    {emailAddress.name}
                  </option>
                ))}
              </select>

              <label htmlFor="email" className="input-label">Email address</label>
              <TextInput
                name="email"
                type="email"
                required={true}
                value={email}
                onChange={this.handleChange}
                className="input"
                errors={errors.email}
              />
            </div>

            <div className="form-group">
              <label htmlFor="contact_info" className="input-label">Templates</label>
              <select
                name="template"
                onChange={this.handleTemplateChange}
                defaultValue=""
                className="input input-select"
              >
                <option value="">Please select</option>
                {mailTemplatesToDisplay.map((template) => (
                  <option
                    key={template.name}
                    value={template.name}
                  >
                    {template.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-12 px-0 form-group">
              <label htmlFor="mail_subject" className="input-label">Title</label>
              <input
                name="subject"
                required={true}
                value={subject}
                onChange={this.handleChange}
                className="input"
              />
            </div>

            <TextAreaInput
              value={mail_content}
              name="mail_content"
              rows="10"
              cols="25"
              label="Email"
              required={true}
              className="text_message__form_input"
              onChange={this.handleChange}
              placeholder="Email content"
              errors=""
            />

            <div className="col row justify-content-end">
              <button
                type="reset"
                className="btn btn-sm btn-light mr-3"
                onClick={onCancel}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-sm btn-primary"
                disabled={!edited || processing}
              >
                {processing ? 'Sending...' : 'Send'}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

MailForm.propTypes = {
  onSuccessSubmit: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(UserModel).isRequired,
  cart: PropTypes.instanceOf(CartModel).isRequired,
  mailTemplates: PropTypes.instanceOf(Array).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  searchNotes: (notes) => dispatch(searchNotes(notes)),
});

const mapStateToProps = (state) => ({
  currentCart: new CartModel(state.carts.current_cart),
  user: new UserModel(state.user),
});

export default connect(mapStateToProps, mapDispatchToProps)(MailForm);
