import React, { Component } from 'react';
import moment from 'moment';
import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';

const calculateLastActivity = (time) => {
  const lastDateTime = moment(time);
  const currentDateTime = moment(new Date());
  const duration = moment.duration(lastDateTime.diff(currentDateTime))
  return duration.humanize(true)
}

const calculateDuration = (recordedAt, lastActivity) => {
  const diff = moment.duration(lastActivity.diff(recordedAt))
  const hours = `${diff.hours() > 0 ? `${diff.hours()},` : ""}`;
  const min = `${diff.minutes() > 0 ? `${diff.minutes()} min,`: ""}`;
  const sec = `${diff.seconds() > 0 ? `${diff.seconds()} sec`: ""}`
  const duration = `${hours} ${min} ${sec}`;
  return duration;
}

const MouseFlowRecording = ({ analytics }) => {

  return (
    <div className="recordings-container user-cart-section">
      <CustomExpansionPanel
        id="open-recordings-panel"
        headerText="MouseFlow Recording"
      >
        <>
          {analytics && (
            <>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Created</th>
                      <th scope="col">Pages</th>
                      <th scope="col">Referrer</th>
                      <th scope="col">Last Activity</th>
                      <th scope="col">Duration</th>
                      <th scope="col">Location</th>
                      <th scope="col">Device</th>
                      <th scope="col">Playback Url</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{analytics.mouseflow_id}</td>
                      <td>{moment(analytics.recorded_at).format("YYYY-MM-DD HH:MM:SS")}</td>
                      <td>{analytics.pages}</td>
                      <td>{analytics.referrer}</td>
                      <td>{calculateLastActivity(analytics.last_activity)}</td>
                      <td>{calculateDuration(moment(analytics.recorded_at), moment(analytics.last_activity))}</td>
                      <td>{`${analytics.city}, ${analytics.country}`}</td>
                      <td>
                        <ul>
                          <li>{analytics.device}</li>
                          <li>{analytics.os}</li>
                          <li>{analytics.browser}</li>
                        </ul>
                      </td>
                      <td>
                        <a href={analytics.playback_url} target="_blank">Play</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      </CustomExpansionPanel>
    </div>
  );
}

export default MouseFlowRecording;
