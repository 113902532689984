import React, { Component } from 'react';

import FormDialog from '@components/shared/FormDialog';
import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';
import { FaxModel, FaxesCollection } from '@models/fax';
import FaxesList from './FaxesList';
import FaxForm from './FaxForm';

class FaxesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormDialogOpen: false,
      faxes: new FaxesCollection(props.faxes),
      faxToResend: new FaxModel(),
    };
  }

  handleNewFaxClick = () => {
    this.setState({ isFormDialogOpen: true, faxToResend: new FaxModel() });
  };

  handleReFaxClick = () => {
    this.setState({ isFormDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isFormDialogOpen: false });
  };

  handleSuccessSubmit = (newFax) => {
    this.setState((prevState) => {
      const newFaxesModels = prevState.faxes.models;
      const newFaxes = new FaxesCollection([newFax].concat(newFaxesModels));

      return { faxes: newFaxes, isFormDialogOpen: false };
    });
  }

  handleSelectFax = (faxId) => {
    const faxes = this.state.faxes.models.filter((fax) => fax.id.toString() === faxId);
    const faxToResend = faxes[0];
    this.setState({ faxToResend });
  }

  render() {
    const { isFormDialogOpen, faxes, faxToResend } = this.state;

    return (
      <div className="fax-container user-cart-section">
        <CustomExpansionPanel
          id="text-panel"
          headerText="Faxes"
        >
          <div className="panel__content w-100">
            <div className="d-flex justify-content-end p-2">
              {faxToResend.cart_id && (
              <button
                type="button"
                className="btn btn-sm btn-outline-primary mx-2"
                onClick={this.handleReFaxClick}
              >
                Refax
              </button>
              )}

              <button
                type="button"
                className="btn btn-sm btn-outline-primary mx-2"
                onClick={this.handleNewFaxClick}
              >
                New fax
              </button>
            </div>

            <FaxesList
              selectedFaxId={faxToResend.id}
              faxes={faxes}
              onSelectFax={this.handleSelectFax}
              key={`faxes-${faxes.models.length}`}
            />
          </div>

          <FormDialog
            title="New fax"
            isOpen={isFormDialogOpen}
            onClose={this.handleCloseDialog}
            customClass="dialog__wide"
          >
            <FaxForm
              onSuccessSubmit={this.handleSuccessSubmit}
              onCancel={this.handleCloseDialog}
              faxToResend={faxToResend}
            />
          </FormDialog>
        </CustomExpansionPanel>
      </div>
    );
  }
}

export default FaxesContainer;
