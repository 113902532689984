const updateCart = (cart) => ({
  type: 'UPDATE_CART',
  cart,
});

const assignDoctorToCart = (cart) => ({
  type: 'ASSIGN_DOCTOR_TO_CURRENT_CART',
  cart,
});

export {
  updateCart,
  assignDoctorToCart,
};
