import React, { Component } from 'react';

import SummaryItem from './SummaryItem';

class SummaryList extends Component {
  render() {
    const { carts } = this.props;

    const cartsList = carts.map((cart) => (
      <SummaryItem key={cart.id} cart={cart} />
    ));

    return (
      <div>
        <table className="table table-hover table-striped">
          <thead className="thead-light">
            <tr>
              <td colSpan={4}>Outbound</td>
              <td colSpan={4}>Manual</td>
              <td colSpan={4}>Automated</td>
              <td colSpan={3} />
            </tr>
            <tr>
              <th>#</th>
              <th>Drug</th>
              <th>Category</th>
              <th>Stage</th>
              <th className="manual"><div className="icon phone" /></th>
              <th className="manual"><div className="icon msg" /></th>
              <th className="manual"><div className="icon mail" /></th>
              <th className="manual"><div className="icon fax" /></th>
              <th className="auto"><div className="icon phone" /></th>
              <th className="auto"><div className="icon msg" /></th>
              <th className="auto"><div className="icon mail" /></th>
              <th className="auto"><div className="icon fax" /></th>
              <th>Days in Stage</th>
              <th>Total Days</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {cartsList}
          </tbody>
        </table>
      </div>
    );
  }
}

export default SummaryList;
