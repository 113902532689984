import { BaseModel, BaseCollection } from './base';

class AddressModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      user_id: null,
      parent_id: null,
      street_1: '',
      street_2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US',
      first_name: '',
      last_name: '',
      phone: '',
      type: '',
      verified: false,
      company: '',
      is_valid: false,
    };
  }

  static get routes() {
    return {
      addressesPath: '/shipping_addresses',
      addressPath: (id) => `/shipping_addresses/${id}`,
    };
  }
}

class AddressesCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return AddressModel;
  }
}

export { AddressModel, AddressesCollection };
