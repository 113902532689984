import { BaseModel, BaseCollection } from './base';

class FaxModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      doctor_fullname: '',
      doctor_phone: '',
      doctor_fax: '',
      doctor_street: '',
      doctor_city: '',
      doctor_state: '',
      doctor_zip: '',
      user_phone: '',
      user_street: '',
      user_city: '',
      user_state: '',
      user_zip: '',
      cart_id: null,
      doctor_id: null,
      send_date: '',
      send_hour: '',
      drug_name: '',
      fax_top_note: '',
      fax_note: '',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  get routes() {
    return {
      all: '/faxes',
      preview: '/faxes/preview',
      show: `/faxes/${this.id}`,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  modelName() {
    return 'fax';
  }

  validFaxNumber() {
    const rawFax = this.doctor_fax.replace(/\D/g, '');
    const valid = rawFax.length === 10;

    return valid;
  }

  // eslint-disable-next-line class-methods-use-this
  errorMessages() {
    return {
      fax_number: "The prescriber's fax number is too short",
    };
  }

  previewErrorMessage() {
    return `Preview ${this.modelName()} cannot be generated due to some errors.`;
  }
}

class FaxesCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return FaxModel;
  }
}

export { FaxModel, FaxesCollection };
