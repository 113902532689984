import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CartModel } from '@models/cart';
import { DiscountTemplateModel } from '@models/discount_template';
import { ProductVariantsCollection } from '@models/product_variant';

import DateInput from '@components/shared/DateInput';
import TextInput from '@/components/shared/TextInput';

import http from '@/utils/http';

class DiscountTemplateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      discountTemplate: this.props.discountTemplate,
      allVariants: new ProductVariantsCollection(props.productVariants),
      processing: false,
      edited: false,
    };
  }

  handleInputChange = (event) => {
    const {
      name,
      type,
      value,
      checked,
    } = event.target;

    const newValue = type === 'checkbox' ? checked : value;

    this.setState((prevState) => {
      const { discountTemplate } = prevState;
      discountTemplate.set(name, newValue);

      return { discountTemplate, edited: true };
    });
  };

  handleTemplateChange = (event) => {
    const { globalDiscountTemplates } = this.props;
    const { value: discount_template_id } = event.target;
    const globalDiscountTemplate = globalDiscountTemplates.find((temp) => temp.id === Number(discount_template_id));

    const params = _.pick(globalDiscountTemplate, [
      'name',
      'type',
      'percent',
      'price',
      'tier',
      'extra_amount',
    ]);

    this.setState((prevState) => {
      const discountTemplate = new DiscountTemplateModel({ ...prevState.discountTemplate, ...params });
      discountTemplate.set('discount_template_id', discount_template_id);
      discountTemplate.set('errors', {});

      return { discountTemplate, edited: true };
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    const { discountTemplate } = this.state;
    const { handleDiscountTemplateSuccessSubmit } = this.props;
    const httpMethod = discountTemplate.id ? 'put' : 'post';

    this.setState({ processing: true });

    http({
      method: httpMethod,
      url: discountTemplate.routes.instance,
      data: { discount_template: discountTemplate.attributes },
    }).then((response) => {
      const newDiscountTemplate = new DiscountTemplateModel(response.data);
      handleDiscountTemplateSuccessSubmit(newDiscountTemplate);
      window.flashMessages.addMessage({ type: 'success', text: 'Discount template has been created successfully.' });
    }).catch((error) => {
      this.setState({ discountTemplate: new DiscountTemplateModel(error.response.data) });
    }).finally(() => this.setState({ processing: false }));
  }

  render() {
    const {
      discountTemplate: {
        id,
        discount_template_id,
        name,
        type,
        percent,
        price,
        tier,
        extra_amount,
        uses_total,
        code,
        expires_at,
        product_variant_id,
        automatic,
        errors,
      },
      discountTemplateId,
      allVariants,
      processing,
      edited,
    } = this.state;

    const {
      globalDiscountTemplates,
      onCancel,
    } = this.props;

    const edition = !!id;

    return (
      <div className="discount-dialog mt-2">
        <form onSubmit={this.handleFormSubmit} className="discount_template__form">
          <div className="container p-0">
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label className="input-label" htmlFor="discount_template_id">Coupon</label>
                  <select
                    className="input input-select bg-contain"
                    name="discount_template_id"
                    value={discount_template_id || ''}
                    onChange={this.handleTemplateChange}
                    disabled={edition}
                  >
                    <option disabled value="">Please select</option>

                    {globalDiscountTemplates.map((globalDiscountTemplate) => (
                      <option
                        key={globalDiscountTemplate.id}
                        value={globalDiscountTemplate.id}
                      >
                        {globalDiscountTemplate.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <TextInput
                  name="name"
                  label="Name"
                  type="text"
                  value={name || ''}
                  errors={errors.name}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label className="input-label" htmlFor="type">Type</label>
                  <select
                    className="input input-select bg-contain"
                    name="type"
                    value={type || ''}
                    onChange={this.handleInputChange}
                    disabled={edition}
                  >
                    <option disabled value="">Please select</option>
                    <option value="percentage">Percentage</option>
                    <option value="delivery_percentage">Delivery percentage</option>
                    <option value="predefined">Predefined price</option>
                    <option value="few_extra">A few extra</option>
                  </select>
                  {errors.type && (
                    <span className="input__error">{errors.type[0]}</span>
                  )}
                </div>
              </div>

              {['percentage', 'delivery_percentage'].includes(type) && (
                <div className="col-6">
                  <TextInput
                    name="percent"
                    label="Percent"
                    type="number"
                    min="0"
                    value={percent || ''}
                    errors={errors.percent}
                    onChange={this.handleInputChange}
                  />
                </div>
              )}
              {type === 'predefined' && (
                <div className="col-6">
                  <TextInput
                    name="price"
                    label="Price"
                    type="number"
                    min="0"
                    step="0.01"
                    value={price || ''}
                    errors={errors.price}
                    onChange={this.handleInputChange}
                  />
                </div>
              )}
              {type === 'few_extra' && (
                <>
                  <div className="col-3">
                    <TextInput
                      name="tier"
                      label="Tier"
                      type="number"
                      min="0"
                      value={tier || ''}
                      errors={errors.tier}
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="col-3">
                    <TextInput
                      name="extra_amount"
                      label="Quantity"
                      type="number"
                      min="0"
                      value={extra_amount || ''}
                      errors={errors.extra_amount}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="row">
              <div className="col-6">
                <TextInput
                  name="uses_total"
                  label="Frequency"
                  type="number"
                  min="0"
                  value={uses_total || ''}
                  errors={errors.uses_total}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-6">
                <TextInput
                  name="code"
                  label="Code"
                  type="text"
                  value={code || ''}
                  errors={errors.code}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <DateInput
                  name="expires_at"
                  label="Expiration date"
                  value={expires_at || ''}
                  errors={errors.expires_at}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="col-6">
                <label className="input-label" htmlFor="product_variant_id">
                  Drug
                </label>
                <select
                  className="input input-select bg-contain"
                  name="product_variant_id"
                  value={product_variant_id || ''}
                  onChange={this.handleInputChange}
                >
                  <option value="" />
                  {allVariants.models.map((productVariant) => (
                    <option
                      key={productVariant.id}
                      value={productVariant.id}
                    >
                      {productVariant.fullname}
                    </option>
                  ))}
                </select>
                {}
                {errors.product_variant_id && (
                  <span className="input__error">{errors.product_variant_id[0]}</span>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <input
                    type="checkbox"
                    id="automatic"
                    name="automatic"
                    className="form-check-input"
                    defaultChecked={automatic}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="automatic" className="form-check-label with-text">
                    Automatic
                  </label>
                  {errors.user_id && (
                    <span className="input__error">{errors.user_id[0]}</span>
                  )}
                </div>
              </div>
            </div>
          </div>


          <div className="col row justify-content-end">
            <button
              type="reset"
              className="btn btn-sm btn-light mr-3"
              onClick={onCancel}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-sm btn-primary"
              disabled={discountTemplateId === '' || !edited || processing}
            >
              {processing ? 'Processing...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCart: new CartModel(state.carts.current_cart),
});

export default connect(mapStateToProps)(DiscountTemplateForm);
