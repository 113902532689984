import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { NotesCollection } from '@models/note';
import NoteItem from './NoteItem';

const NotesList = (props) => {
  const { notes } = props;

  return (
    <div className="d-flex flex-column">
      {notes.models.length === 0
        ? 'There are no notes.'
        : (
          notes.models.map((note) => (
            <NoteItem key={note.id} note={note} />
          ))
        )}
    </div>
  );
};

NotesList.propTypes = {
  notes: PropTypes.instanceOf(NotesCollection).isRequired,
};

const mapStateToProps = (state) => ({
  notes: new NotesCollection(state.notes),
});

export default connect(mapStateToProps)(NotesList);
