import React, { Component } from 'react';

class Message extends Component {
  componentDidMount() {
    if (document.querySelector('.nav .based-in')) {
      document.querySelector('.nav .based-in').classList.add('hidden');
    }
    this.timer = setTimeout(this.props.onClose, this.props.timeout);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  messageClass = (type) => {
    const classes = {
      error: 'message--danger',
      alert: 'message--warning',
      notice: 'message--info',
      success: 'message--success',
    };
    return classes[type] || classes.success;
  };

  render() {
    const { message } = this.props;

    return (
      <div
        className={`message ${this.messageClass(message.type)}`}
        style={{ position: 'relative', top: '100px', zIndex: 10000 }}
      >
        <button className="message__close" onClick={this.props.onClose}>
          {' '}
          &times;
          {' '}
        </button>
        {message.text}
      </div>
    );
  }
}

export default Message;
