import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateCart as updateCartAction } from '@actions/panel/cart_view/carts';
import { refreshOrder as refreshOrderAction } from '@actions/panel/cart_view/orders';

import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';
import FormDialog from '@components/shared/FormDialog';

import { CartsCollection } from '@models/cart';
import { DiscountsCollection } from '@models/discount';
import { DiscountsTemplateCollection } from '@models/discount_template';

import http from '@/utils/http';
import DiscountsList from './DiscountsList';
import DiscountTemplatesList from './DiscountTemplatesList';

import FromContainer from './FromContainer';


class DiscountsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      discounts: new DiscountsCollection(props.discounts),
      discountTemplates: new DiscountsTemplateCollection(props.discountTemplates),
      isFormDialogOpen: false,
      formDialogType: '',
    };
  }

  handleAddNew = (type) => {
    this.setState({ isFormDialogOpen: true, formDialogType: type });
  };

  handleCloseDialog = (type) => {
    this.setState({ isFormDialogOpen: false, formDialogType: type });
  };

  handleDiscountSuccessSubmit = (newDiscount) => {
    const { currentCart } = this.props;

    if (currentCart.id && currentCart.id !== newDiscount.cart_id) {
      this.setState({ isFormDialogOpen: false });
      return;
    }

    this.setState((prevState) => {
      prevState.discounts.models.push(newDiscount);
      return { discounts: prevState.discounts, isFormDialogOpen: false };
    });
  }

  handleDiscountTemplateSuccessSubmit = (newDiscountTemplate) => {
    this.setState((prevState) => {
      prevState.discountTemplates.models.push(newDiscountTemplate);
      return { discountTemplates: prevState.discountTemplates, isFormDialogOpen: false };
    });
  }

  handleDiscountTemplateDeletion = (discountTemplate) => {
    this.setState((prevState) => {
      const { models } = prevState.discountTemplates;
      const index = _.findIndex(models, (dt) => dt.id === discountTemplate.id);
      models[index] = discountTemplate;
      return { discountTemplates: new DiscountsTemplateCollection(models) };
    });
  }

  refreshCart = (cart_id) => {
    const { updateCart, refreshOrder } = this.props;
    http.get(`/carts/${cart_id}/refresh`)
      .then((response) => {
        updateCart(response.data);
        if (response.data.order_id) refreshOrder(response.data.order_id);
      });
  }

  render() {
    const {
      discounts,
      isFormDialogOpen,
      discountTemplates,
      formDialogType,
    } = this.state;

    const {
      globalDiscountTemplates,
      productVariants,
      adminRole,
      discountableCarts,
    } = this.props;

    return (
      <>
        <FormDialog
          title="Create new discount"
          isOpen={isFormDialogOpen}
          onClose={this.handleCloseDialog}
          customClass="dialog__wide"
        >
          <FromContainer
            globalDiscountTemplates={globalDiscountTemplates}
            discountTemplates={discountTemplates}
            productVariants={productVariants}
            formType={formDialogType}
            onCancel={this.handleCloseDialog}
            handleDiscountSuccessSubmit={this.handleDiscountSuccessSubmit}
            handleDiscountTemplateSuccessSubmit={this.handleDiscountTemplateSuccessSubmit}
            adminRole={adminRole}
            refreshCart={this.refreshCart}
            discountableCarts={discountableCarts}
          />
        </FormDialog>

        <div className="discount-container user-cart-section">
          <CustomExpansionPanel
            id="discount-panel"
            headerText="Discounts"
          >
            <div className="panel__content w-100">
              <div className="d-flex justify-content-end p-2">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary mx-2"
                  onClick={() => this.handleAddNew('discount')}
                >
                  New discount
                </button>
              </div>

              <DiscountsList
                discounts={discounts}
                globalDiscountTemplates={globalDiscountTemplates}
                discountTemplates={discountTemplates}
                adminRole={adminRole}
                refreshCart={this.refreshCart}
                discountableCarts={discountableCarts}
              />
            </div>
          </CustomExpansionPanel>
        </div>
        <div className="user-discount-templates-container user-cart-section">
          <CustomExpansionPanel
            id="discount-panel"
            headerText="Discount Templates"
          >
            <div className="panel__content w-100">
              <div className="d-flex justify-content-end p-2">
                {adminRole === 'superadmin' && (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary mx-2"
                    onClick={() => this.handleAddNew('discount_template')}
                  >
                    New discount template
                  </button>
                )}
              </div>

              <DiscountTemplatesList
                discountTemplates={discountTemplates}
                globalDiscountTemplates={globalDiscountTemplates}
                productVariants={productVariants}
                adminRole={adminRole}
                handleDiscountTemplateDeletion={this.handleDiscountTemplateDeletion}
              />
            </div>
          </CustomExpansionPanel>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCart: state.carts.current_cart,
  discountableCarts: new CartsCollection(state.carts.all_carts).filterByGroup('discountable'),
});

const mapDispatchToProps = (dispatch) => ({
  updateCart: (cart) => dispatch(updateCartAction(cart)),
  refreshOrder: (orderId) => dispatch(refreshOrderAction(orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscountsContainer);
