import React, { Component } from 'react';

import FormDialog from '@components/shared/FormDialog';
import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';
import { UserModel } from '@models/user';
import { MailsCollection } from '@models/mail';
import MailsList from './MailsList';
import MailForm from './MailForm';

class MailsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormDialogOpen: false,
      mails: new MailsCollection(props.mails),
    };
  }

  handleNewMailClick = () => {
    this.setState({ isFormDialogOpen: true });
  };


  handleCloseDialog = () => {
    this.setState({ isFormDialogOpen: false });
  };

  handleSuccessSubmit = (mail) => {
    this.setState((prevState) => {
      const mailsModels = prevState.mails.models;
      const newMails = new MailsCollection([mail].concat(mailsModels));

      return { mails: newMails, isFormDialogOpen: false };
    });
  }

  render() {
    const { isFormDialogOpen, mails } = this.state;
    const {
      user, cart, mailTemplates,
    } = this.props;

    return (
      <div className="mail-container user-cart-section mt-3">
        <CustomExpansionPanel
          id="mail-panel"
          headerText="Email"
        >
          <div>
            <div className="d-flex justify-content-end p-2">
              <button
                type="button"
                className="btn btn-sm btn-outline-primary mx-2 mb-3"
                onClick={this.handleNewMailClick}
              >
                New mail
              </button>
            </div>
            <FormDialog
              title="Enter new email message"
              isOpen={isFormDialogOpen}
              onClose={this.handleCloseDialog}
              customClass="dialog__wide"
            >
              <MailForm
                user={new UserModel(user)}
                cart={cart}
                mailTemplates={mailTemplates}
                onSuccessSubmit={this.handleSuccessSubmit}
                onCancel={this.handleCloseDialog}
              />
            </FormDialog>
            <MailsList mails={mails} />
          </div>
        </CustomExpansionPanel>
      </div>
    );
  }
}

export default MailsContainer;
