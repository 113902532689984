import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateCart as updateCartAction } from '@actions/panel/cart_view/carts';

import http from '@/utils/http';

class CartBranchSelector extends Component {
  handleBranchChange = ({ target }) => {
    const { currentCart, updateCart } = this.props;
    const selectedBranchRadio = target;

    if (!currentCart.isBranchChangePossible) {
      selectedBranchRadio.checked = false;
      window.flashMessages.addMessage({
        type: 'notice',
        text: 'Please, move cart to \'Open\' stage to change branch.',
      });

      return;
    }

    http.put(currentCart.routes.byId, { cart: { branch: target.value } })
      .then((response) => {
        updateCart(response.data);
        selectedBranchRadio.className = 'active';
        window.flashMessages.addMessage({
          type: 'success',
          text: 'Cart has been updated.',
        });
      })
      .catch((r) => {
        window.flashMessages.addMessage({
          type: 'error',
          text: `Cart cannot be updated due to some errors. ${this.errorMessages(r.response.data.errors)}`,
        });
      });
  }

  errorMessages = (errors) => {
    let error_message = '';
    const keys = Object.keys(errors);

    keys.forEach((key) => {
      error_message += `${key.replace('_', ' ')} ${errors[key]} `;
    });

    return error_message;
  }

  setLabelClassName = (branch) => {
    const classes = ['btn', 'btn-outline-primary'];
    if (this.props.currentCart.branch === branch) classes.push('active');

    return classes.join(' ');
  }

  render() {
    return (
      <div className="cart-branch-selector">
        <div className="btn-group btn-group-toggle">
          <label className={this.setLabelClassName('pharmacy_request')}>
            <input
              type="radio"
              name="branch"
              autoComplete="off"
              value="pharmacy_request"
              onChange={this.handleBranchChange}
            />
            Pharmacy Request
          </label>

          <label className={this.setLabelClassName('patient_request')}>
            <input
              type="radio"
              name="branch"
              autoComplete="off"
              value="patient_request"
              onChange={this.handleBranchChange}
            />
            Patient Request
          </label>

          <label className={this.setLabelClassName('transfer')}>
            <input
              type="radio"
              name="branch"
              autoComplete="off"
              value="transfer"
              onChange={this.handleBranchChange}
            />
            Transfer
          </label>

          <label className={this.setLabelClassName('mailed')}>
            <input
              type="radio"
              name="branch"
              autoComplete="off"
              value="mailed"
              onChange={this.handleBranchChange}
            />
            Mailed
          </label>

          <label className={this.setLabelClassName('no_doctor')}>
            <input
              type="radio"
              name="branch"
              autoComplete="off"
              value="no_doctor"
              onChange={this.handleBranchChange}
            />
            No doctor
          </label>

          <label className={this.setLabelClassName('telemedicine')}>
            <input
              type="radio"
              name="branch"
              autoComplete="off"
              value="telemedicine"
              onChange={this.handleBranchChange}
            />
            Telemedicine
          </label>

          <label className={this.setLabelClassName('refill_request')}>
            <input
              type="radio"
              name="branch"
              autoComplete="off"
              value="refill_request"
              onChange={this.handleBranchChange}
            />
            Refill Request
          </label>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCart: (cart) => dispatch(updateCartAction(cart)),
});

export default connect(null, mapDispatchToProps)(CartBranchSelector);
