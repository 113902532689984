const initialState = {
  stage_changes: {},
};

const stageChangesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_STAGE_CHANGES': {
      return action.stageChanges;
    }
    default:
      return state;
  }
};

export default stageChangesReducer;
