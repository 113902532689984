// React
import React from "react";
import ReactDOM from "react-dom";

// Redux
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

// Reducers
import cartViewReducer from "@reducers/panel/cart_view";

// Components
import ContactOverview from "@components/panel/carts/contact/ContactOverview";
import ContactContainer from "@components/panel/carts/contact/ContactContainer";
import DoctorsContainer from "@components/panel/carts/doctors/DoctorsContainer";
import OpenCartsContainer from "@components/panel/carts/open_carts/OpenCartsContainer";
import OrdersContainer from "@components/panel/carts/orders/OrdersContainer";
import AddressesContainer from "@components/panel/carts/addresses/AddressesContainer";
import DiscountsContainer from "@components/panel/carts/discounts/DiscountsContainer";
import PharmaciesContainer from "@components/panel/carts/pharmacy/PharmaciesContainer";
import NotesContainer from "@components/panel/notes/NotesContainer";
import QuestionsContainer from "@components/panel/carts/questions/QuestionsContainer";
import CommunicationContainer from "@components/panel/carts/communications/CommunicationContainer";
import StageChangesContainer from "@components/panel/carts/stage_changes/StageChangesContainer";
import LogsContainer from "@components/panel/carts/logs/LogsContainer";
import IssuesContainer from "@components/panel/carts/issues/IssuesContainer";
import PrescriptionsContainer from "@components/panel/carts/prescriptions/PrescriptionsContainer";
import RecordingsContainer from "@components/panel/carts/recordings";
import UtmInfoContainer from "@components/panel/carts/utm";

import { scrollInit, initSideBarToggle, scrollSpy } from "@/utils/panel/panel";

const initializeCartView = (data, view) => {
  const {
    admin_role,
    carts,
    user,
    addresses,
    pharmacies,
    doctors,
    pharmacist_detail,
    product_variants,
    delivery_options,
    admins,
    stage_changes,
    notes,
    orders,
    tags,
    discounts,
    discount_templates,
    global_discount_templates,
    text_message_templates,
    text_messages,
    faxes,
    user_versions,
    issues,
    mails,
    mail_templates,
    prescriptions,
    prices,
    is_super_admin,
  } = data;

  const triggers = { showNoteForm: false };
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    cartViewReducer,
    {
      carts,
      user,
      stage_changes,
      notes,
      orders,
      text_messages,
      doctors,
      triggers,
      issues,
    },
    composeEnhancers(applyMiddleware(thunk))
  );

  scrollInit();
  initSideBarToggle(view);
  scrollSpy();

  ReactDOM.render(
    <Provider store={store}>
      <ContactOverview tags={tags} prices={prices} />
    </Provider>,
    document.querySelector('#contact-overview[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <ContactContainer isSuperAdmin={data.is_super_admin} />
    </Provider>,
    document.querySelector('#contact-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <AddressesContainer addresses={addresses} />
    </Provider>,
    document.querySelector('#addresses-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <OpenCartsContainer
        productVariants={product_variants}
        deliveryOptions={delivery_options}
      />
    </Provider>,
    document.querySelector('#open-carts-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <OrdersContainer
        ordersGroup="open"
        sectionHeader="Open Orders"
        deliveryOptions={delivery_options}
      />
    </Provider>,
    document.querySelector('#open-orders-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <OrdersContainer
        ordersGroup="history"
        sectionHeader="Orders History"
        deliveryOptions={delivery_options}
      />
    </Provider>,
    document.querySelector('#orders-history-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <DiscountsContainer
        discounts={discounts}
        discountTemplates={discount_templates}
        globalDiscountTemplates={global_discount_templates}
        productVariants={product_variants}
        adminRole={admin_role}
      />
    </Provider>,
    document.querySelector('#discounts-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <PharmaciesContainer
        pharmacies={pharmacies}
        pharmacistDetail={pharmacist_detail}
        productVariants={product_variants}
        admins={admins}
      />
    </Provider>,
    document.querySelector('#pharmacies-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <DoctorsContainer doctors={doctors} />
    </Provider>,
    document.querySelector('#doctors-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <QuestionsContainer />
    </Provider>,
    document.querySelector('#questions-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <IssuesContainer userId={user.id} currentCartId={carts.current_cart.id} />
    </Provider>,
    document.querySelector('#issues-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <CommunicationContainer
        faxes={faxes}
        mails={mails}
        textMessageTemplates={text_message_templates}
        mailTemplates={mail_templates}
      />
    </Provider>,
    document.querySelector('#communication-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <StageChangesContainer currentCartId={carts.current_cart.id} />
    </Provider>,
    document.querySelector('#stage-changes-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <PrescriptionsContainer prescriptions={prescriptions} />
    </Provider>,
    document.querySelector('#prescriptions-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <LogsContainer userVersions={user_versions} />,
    document.querySelector('#logs-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <RecordingsContainer analytics={carts.current_cart.mouseflow_recording} />,
    document.querySelector('#recordings-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <UtmInfoContainer utmData={carts.current_cart.utm} />,
    document.querySelector('#utm-info-container[data-behavior="react"]')
  );

  ReactDOM.render(
    <Provider store={store}>
      <NotesContainer currentUserId={user.id} />
    </Provider>,
    document.querySelector('#notes-container[data-behavior="react"]')
  );
};

export default initializeCartView;
