import React, { useState } from 'react';

const CartSelect = (props) => {
  const { currentCartId, user, allCarts } = props;
  const [isOpen, setIsOpen] = useState(false);
  const mainClass = 'main-cart-select';

  const handleSelectToggle = () => {
    setIsOpen(!isOpen);
  };

  let name = null;

  allCarts.models.find((cart) => {
    if (cart.id === currentCartId) {
      name = cart.optionText;
    }
  });

  document.addEventListener('click', (event) => {
    if (!$(event.target).closest(`.${mainClass}`).length && !$(event.target).is(`.${mainClass}`)) {
      setIsOpen(false);
    }
  });

  const openCarts = allCarts.filterByGroup('open');

  return (
    <div className={`select custom-dropdown ${mainClass}`}>
      <button
        className={`input input-select ${isOpen ? 'touched' : ''} pr-5`}
        onClick={handleSelectToggle}
      >
        {!currentCartId ? 'All' : name}
      </button>
      <div className="select-dropdown fixed">
        <a
          href={`/users/${user.id}`}
          hidden={!currentCartId}
          data-value="all"
        >
          All
        </a>
        {openCarts.map((cart) => (
          <a
            key={cart.id}
            href={cart.routes.byId}
            data-value={cart.id}
            hidden={cart.id === currentCartId}
          >
            {cart.optionText}
          </a>
        ))}
      </div>
    </div>
  );
};

export default CartSelect;
