import React, { Component } from "react";
import { connect } from "react-redux";

import { updateCart as updateCartAction } from "@actions/panel/cart_view/carts";

import { CartModel } from "@models/cart";
import { ProductVariantsCollection } from "@models/product_variant";

import { calculatePrices } from "@/utils";
import { cartStagesByBranch, resolveRequestStageLabel } from "@/utils/panel";
import http from "@/utils/http";

class CartForm extends Component {
  constructor(props) {
    super(props);

    const allVariants = new ProductVariantsCollection(props.productVariants);

    const currentVariant = allVariants.models.find(
      (variant) => variant.id === props.currentOpenCart.product_variant_id
    );

    this.state = {
      cart: new CartModel({ ...props.currentOpenCart }),
      allVariants,
      filteredVariants: currentVariant
        ? allVariants.filterByProductId(currentVariant.product_id)
        : [],
      selectedProductId: currentVariant ? currentVariant.product_id : null,
      processing: false,
      deliveryOptions: props.deliveryOptions,
    };
  }

  handleInputChange = ({ target: { name, value } }) => {
    this.setState((prevState) => {
      const cart = new CartModel({ ...prevState.cart });
      cart.set(name, value);
      return { cart };
    }, this.updateDeliveryOptions);
  };

  updateDeliveryOptions = () => {
    const { cart } = this.state;

    if (
      parseInt(cart.quantity, 10) >= 0 &&
      parseInt(cart.product_variant_id, 10) > 0
    ) {
      http
        .post(this.state.cart.routes.shipping_cost, {
          cart: this.pickCartAttributes(),
        })
        .then((response) => {
          this.setState((prevState) => {
            const { deliveryOptions } = prevState;

            deliveryOptions.map((option, index) => {
              switch (option.delivery_type) {
                case "standard":
                  deliveryOptions[index].price_value = response.data.standard;
                  break;
                case "express":
                  deliveryOptions[index].price_value = response.data.express;
                  break;
                default:
              }
            });

            return { deliveryOptions };
          });
        })
        .catch(() => {
          window.flashMessages.addMessage({
            type: "error",
            text: "Shipping cost cannot be updated to some errors.",
          });
        });
    }
  };

  handleCheckboxChange = ({ target: { name, checked } }) => {
    this.setState((prevState) => {
      const cart = new CartModel({ ...prevState.cart });
      cart.set(name, checked);

      return { cart };
    });
  };

  pickCartAttributes = () =>
    _.pick(this.state.cart, [
      "product_variant_id",
      "landing_page_id",
      "user_id",
      "quantity",
      "max_quantity",
      "branch",
      "stage",
      "rx_type",
      "when_needed",
      "delivery_type",
      "skip_payment",
    ]);

  handleCartCreate = () => {
    const { onCancel } = this.props;
    http
      .post(this.state.cart.routes.all, { cart: this.pickCartAttributes() })
      .then((response) => {
        onCancel();
        window.flashMessages.addMessage({
          type: "success",
          text: "Cart has been created.",
        });

        const newCart = new CartModel(response.data);
        window.location = newCart.routes.byId;
      })
      .catch((error) => {
        this.setState({
          processing: false,
          cart: new CartModel(error.response.data),
        });

        window.flashMessages.addMessage({
          type: "error",
          text: "Cart cannot be created due to some errors.",
        });
      });
  };

  handleCartUpdate = () => {
    const { updateCart, onCancel } = this.props;

    http
      .put(this.state.cart.routes.byId, { cart: this.pickCartAttributes() })
      .then((response) => {
        updateCart(response.data);
        onCancel();

        window.flashMessages.addMessage({
          type: "success",
          text: "Cart has been updated.",
        });
      })
      .catch((error) => {
        this.setState({ cart: new CartModel(error.response.data) });

        window.flashMessages.addMessage({
          type: "error",
          text: "Cart cannot be updated due to some errors.",
        });
      })
      .finally(() => this.setState({ processing: false }));
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    this.setState({ processing: true });
    this.state.cart.id === null
      ? this.handleCartCreate()
      : this.handleCartUpdate();
  };

  handleProductChange = ({ target }) => {
    this.setState((prevState) => {
      const filteredVariants = prevState.allVariants.filterByProductId(
        target.value
      );

      const cart = new CartModel({ ...prevState.cart });
      cart.set("product_variant_id", null);

      return { cart, filteredVariants, selectedProductId: target.value };
    });
  };

  handlePricesUpdate = () => {
    const {
      cart: { product_variant_id, quantity },
      allVariants,
    } = this.state;

    const selectedVariant = allVariants.models.find(
      (variant) => variant.id === Number(product_variant_id)
    );

    if (selectedVariant) {
      return calculatePrices(selectedVariant, quantity);
    }

    return {
      basePrice: 0.0,
      price: 0.0,
      priceDifference: 0.0,
      discount: 0.0,
    };
  };

  render() {
    const {
      cart: {
        branch,
        stage,
        quantity,
        max_quantity,
        product_variant_id,
        rx_type,
        when_needed,
        delivery_type,
        skip_payment,
        errors,
        isValidBeforeCreate,
      },
      allVariants,
      selectedProductId,
      filteredVariants,
      processing,
      deliveryOptions,
    } = this.state;

    const { basePrice, price, priceDifference, discount } =
      this.handlePricesUpdate();

    const availableStages = cartStagesByBranch[branch || "root"];

    const errorMsgs = [];
    Object.keys(errors).forEach((e) => {
      if (e === "base") {
        errorMsgs.push("Cart exists with this product and dosage.");
      } 
      else if (e === "prescription_id") {
          errorMsgs.push("Refill Request cart can only be created by patient not by an admin.");
      }
    });

    return (
      <form onSubmit={this.handleFormSubmit} className="cart__form">
        <div className="container p-0">
          {errorMsgs.length > 0 && (
            <div className="row">
              <span className="col-12 error">{errorMsgs.join("")}</span>
            </div>
          )}
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="input-label mb-0" htmlFor="drug">
                  Drug
                </label>
                <select
                  className="input input-select bg-contain"
                  name="drug"
                  defaultValue={selectedProductId || ""}
                  onChange={this.handleProductChange}
                >
                  <option>Please select</option>
                  {allVariants.selectableProducts.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <label className="input-label mb-0" htmlFor="dosage">
                  Dosage
                </label>
                <select
                  className="input input-select bg-contain"
                  name="product_variant_id"
                  defaultValue={product_variant_id || ""}
                  onChange={this.handleInputChange}
                >
                  <option>Please select</option>

                  {filteredVariants.map((variant) => (
                    <option key={variant.id} value={variant.id}>
                      {variant.dosage}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="row align-items-start">
            <div className="col-3">
              <div className="form-group">
                <label className="input-label mb-0" htmlFor="quantity">
                  Quantity
                </label>
                <input
                  className={`input ${errors.quantity && "is-invalid"}`}
                  type="number"
                  name="quantity"
                  value={quantity}
                  onChange={this.handleInputChange}
                  min={0}
                />
                {errors.quantity && (
                  <div className="invalid-feedback">{errors.quantity[0]}</div>
                )}
              </div>
            </div>

            <div className="col-3">
              <div className="form-group">
                <label className="input-label mb-0" htmlFor="quantity">
                  Max Rx Quantity
                </label>
                <input
                  className={`input ${errors.max_quantity && "is-invalid"}`}
                  type="number"
                  name="max_quantity"
                  value={max_quantity || ""}
                  onChange={this.handleInputChange}
                  min={0}
                />
                {errors.max_quantity && (
                  <div className="invalid-feedback">
                    {errors.max_quantity[0]}
                  </div>
                )}
              </div>
            </div>

            <div className="col-6 row align-items-end savings">
              <div className="form-group">
                <div className="costs-info mr-3">
                  <label className="input-label">Cost</label>
                  <span className="input">
                    {basePrice !== price && <strike>${basePrice}</strike>}
                    <em className="font-weight-bold ml-1">${price}</em>
                  </span>
                </div>
              </div>
              <div className="form-group">
                <div className="saving-info">
                  <label className="input-label">You saved </label>
                  <p className="mb-0">
                    <strong>${priceDifference}</strong> ({discount}% off)
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="input-label mb-0" htmlFor="branch">
                  Branch
                </label>
                <select
                  className="input input-select bg-contain"
                  name="branch"
                  value={branch || ""}
                  onChange={this.handleInputChange}
                  disabled={stage !== "open"}
                >
                  <option>Please select</option>
                  <option value="pharmacy_request">Pharmacy Request</option>
                  <option value="refill_request">Refill Request</option>
                  <option value="patient_request">Patient Request</option>
                  <option value="transfer">Transfer</option>
                  <option value="mailed">Mailed</option>
                  <option value="no_doctor">No doctor</option>
                  <option value="telemedicine">Telemedicine</option>
                </select>
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <label className="input-label mb-0" htmlFor="branch">
                  Stage
                </label>
                <select
                  className="input input-select bg-contain"
                  name="stage"
                  value={stage || 0}
                  onChange={this.handleInputChange}
                  disabled
                >
                  {availableStages.map((stageName) => (
                    <option value={stageName} key={stageName}>
                      {resolveRequestStageLabel(stageName, branch)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="input-label mb-0" htmlFor="rx_type">
                  Rx Type
                </label>
                <select
                  className="input input-select bg-contain"
                  name="rx_type"
                  value={rx_type || ""}
                  onChange={this.handleInputChange}
                >
                  <option value="fresh">New</option>
                  <option value="refill">Refill</option>
                </select>
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <label className="input-label mb-0" htmlFor="when_needed">
                  When Needed
                </label>
                <select
                  className="input input-select bg-contain"
                  name="when_needed"
                  value={when_needed || ""}
                  onChange={this.handleInputChange}
                >
                  <option value="now">Now</option>
                  <option value="hold">Hold</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <label
                htmlFor="delivery_type"
                className="py-1 mb-2 delivery-label"
              >
                Choose your delivery options
              </label>
              {deliveryOptions.map((option) => (
                <div
                  className="checkout-option"
                  key={`${option.id}-${option.price_value}`}
                >
                  <input
                    type="radio"
                    key={`input-${option.id}-${option.price_value}`}
                    id={`delivery-${option.delivery_type}`}
                    name="delivery_type"
                    value={option.delivery_type}
                    className="checkout-option__input"
                    onChange={this.handleInputChange}
                    checked={delivery_type === option.delivery_type}
                  />

                  <label
                    htmlFor={`delivery-${option.delivery_type}`}
                    className="checkout-option__label"
                  >
                    <span className="checkout-option__title">
                      <span>{option.label}</span>{" "}
                      <span>{`$${Number(option.price_value).toFixed(2)}`}</span>
                    </span>
                    1 business day
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="field form-check pl-0">
                <input
                  type="checkbox"
                  id="payment_skipped"
                  name="skip_payment"
                  defaultChecked={skip_payment}
                  onChange={this.handleCheckboxChange}
                  className="custom-control-input"
                />
                <label
                  htmlFor="payment_skipped"
                  className="input-label checkbox-label"
                >
                  Skip automated payment process, I will manually process the
                  payment
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="col row justify-content-end">
          <button
            type="reset"
            className="btn btn-sm btn-light mr-3"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>

          <button
            type="submit"
            className="btn btn-sm btn-primary"
            disabled={!isValidBeforeCreate || processing}
          >
            {processing ? "Processing..." : "Save"}
          </button>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCart: (cart) => dispatch(updateCartAction(cart)),
});

export default connect(null, mapDispatchToProps)(CartForm);
