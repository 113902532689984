import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { AddressesCollection, AddressModel } from '@models/address';
import { UserModel } from '@models/user';

import FormDialog from '@components/shared/FormDialog';

import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';
import AddressesList from './AddressesList';
import AddressForm from './AddressForm';

class AddressesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormDialogOpen: false,
      addressTemplates: new AddressesCollection(props.addresses.address_templates),
      currentAddress: new AddressModel(props.addresses.current_address),
    };
  }

  handleAddNew = () => {
    this.setState({ isFormDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isFormDialogOpen: false });
  };

  updateCurrentAddress = (updatedCurrentAddress) => {
    this.setState({ currentAddress: updatedCurrentAddress });
  }

  handleSuccessSubmit = (newAddres) => {
    this.setState((prevState) => {
      prevState.addressTemplates.models.push(newAddres);
      return { addressTemplates: prevState.addressTemplates, isFormDialogOpen: false };
    });
  }

  render() {
    const { user } = this.props;
    const { isFormDialogOpen, addressTemplates, currentAddress } = this.state;

    return (
      <div className="address-container user-cart-section">
        <CustomExpansionPanel
          id="address-panel"
          headerText="Addresses"
        >
          <div className="panel__content w-100">
            <div className="d-flex justify-content-end p-2">
              <button
                type="button"
                className="btn btn-sm btn-outline-primary mx-2"
                onClick={this.handleAddNew}
              >
                New address
              </button>
            </div>

            <FormDialog
              title="Enter new shipping address"
              isOpen={isFormDialogOpen}
              onClose={this.handleCloseDialog}
              customClass="dialog__wide"
            >
              <AddressForm
                address={new AddressModel({ user_id: user.id })}
                onCancel={this.handleCloseDialog}
                onSuccessSubmit={this.handleSuccessSubmit}
              />
            </FormDialog>
          </div>
          <AddressesList
            addressTemplates={addressTemplates}
            currentAddress={currentAddress}
            updateCurrentAddress={this.updateCurrentAddress}
          />
        </CustomExpansionPanel>
      </div>
    );
  }
}

AddressesContainer.propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
};

const mapStateToProps = (state) => ({
  user: new UserModel(state.user),
});

export default connect(
  mapStateToProps,
)(AddressesContainer);
