import { BaseModel, BaseCollection } from './base';

class MailModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      subject: '',
      mail_content: '',
      user_fullname: '',
      admin_fullname: '',
      admin_email: '',
      cart: '',
      send_date: '',
      send_hour: '',
      errors: {},
    };
  }

  // eslint-disable-next-line class-methods-use-this
  get routes() {
    return {
      all: '/user_mails',
      preview: '/user_mails/preview',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  modelName() {
    return 'mail';
  }
}

class MailsCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return MailModel;
  }
}

export { MailModel, MailsCollection };
