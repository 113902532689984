// import SimpleMDE from 'simplemde';
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt('commonmark');

const initializeMarkdownEditors = () => {
  $('.form-group__markdown textarea').map((index, el) => {
    const simpleMDE = new SimpleMDE({
      element: el,
      html: true,
      breaks: true,
      previewRender: (text) => md.render(text),
      toolbar: [
        'bold',
        'italic',
        'heading',
        '|',
        'link',
        'quote',
        'unordered-list',
        'ordered-list',
        '|',
        'preview',
        'side-by-side',
        'fullscreen',
        '|',
        'guide',
      ],
    });
    return simpleMDE;
  });
};

export default initializeMarkdownEditors;
