import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CartModel, CartsCollection } from '@models/cart';

import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';
import SummaryList from './SummaryList';
import TextMessagesContainer from '../text_messages/TextMessagesContainer';
import FaxesContainer from '../faxes/FaxesContainer';
import MailsContainer from '../mails/MailsContainer';

class CommunicationContainer extends Component {
  render() {
    const {
      textMessageTemplates, currentCart, openCarts, faxes, user, mails, mailTemplates,
    } = this.props;

    const summaryCarts = currentCart.id ? [currentCart] : openCarts.models;

    return (
      <div className="communication-container user-cart-section">
        <CustomExpansionPanel
          id="stage-changes-panel"
          headerText="Communication"
        >
          <SummaryList carts={summaryCarts} />

          <FaxesContainer faxes={faxes} />
          <TextMessagesContainer textMessageTemplates={textMessageTemplates} />
          <MailsContainer
            user={user}
            cart={currentCart}
            mails={mails}
            mailTemplates={mailTemplates}
          />
        </CustomExpansionPanel>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  currentCart: new CartModel(state.carts.current_cart),
  openCarts: new CartsCollection(state.carts.all_carts),
});

export default connect(mapStateToProps)(CommunicationContainer);
