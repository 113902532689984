import React from 'react';
import ReactTable from 'react-table';
import CustomExpansionPanel from '@components/shared/panel/CustomExpansionPanel';
import moment from 'moment';

const PrescriptionsContainer = ({ prescriptions }) => {

  const columns = [
    {
      Header: 'Rx ID',
      accessor: 'rx_id',
    },
    {
      Header: 'Transaction Date',
      accessor: 'transaction_date',
    },
    {
      Header: 'Drug',
      accessor: 'product_variant',
    },
    {
      Header: 'Quantity Dispensed',
      accessor: 'quantity_dispensed',
    },
    {
      Header: 'Remaining Quantity',
      accessor: 'rx_total_quantity_remaining',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Expiration Date',
      accessor: 'expiration_date',
      Cell: ({ value }) => moment(value).format('MMM D, YYYY')
    },
  ];

  return (
    <div className="prescriptions-container user-cart-section">
      <CustomExpansionPanel
        id="prescriptions-panel"
        headerText="Prescriptions"
      >

        <ReactTable
          minRows={1}
          columns={columns}
          data={prescriptions}
          defaultPageSize={10}
          pageSizeOptions={[10, 30, 50]}
          className="-striped -highlight"
        />

      </CustomExpansionPanel>
    </div>
  );
};

export default PrescriptionsContainer;
