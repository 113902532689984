import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import { StageChangesCollection } from '@models/stage_change';

import { resolveRequestStageLabel } from '@/utils/panel';

const StageChangesList = (props) => {
  const { stageChanges } = props;

  const columns = [
    {
      Header: 'Stage',
      accessor: 'stage_from',
      Cell: (cellProps) => (
        <span>
          {resolveRequestStageLabel(cellProps.value, cellProps.original.branch)}
        </span>
      ),
    },
    {
      Header: 'Amount',
      accessor: 'priceWithCurrency',
    },
    {
      Header: 'Days In Stage',
      accessor: 'days_in_stage',
    },
    {
      Header: 'Creating Date',
      accessor: 'formattedCreatedAt',
    },
    {
      Header: 'Closing Date',
      accessor: 'formattedClosedAt',
    },
    {
      Header: 'Modified By',
      accessor: 'admin_name',
    },
  ];

  return (
    <ReactTable
      minRows={1}
      columns={columns}
      data={stageChanges.models}
      defaultPageSize={10}
      pageSizeOptions={[10, 30, 50]}
      className="-striped -highlight"
    />
  );
};

const mapStateToProps = (state) => ({
  stageChanges: new StageChangesCollection(state.stage_changes),
});

export default connect(mapStateToProps)(StageChangesList);
