import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const FormDialog = (props) => (
  <Dialog
    open={props.isOpen}
    onClose={props.onClose}
    aria-labelledby="form-dialog__title"
    className={`form-dialog ${props.customClass}`}
    disableBackdropClick={true}
  >
    <DialogTitle id="form-dialog__title">
      {props.title}
    </DialogTitle>

    <DialogContent>
      {props.children}
    </DialogContent>
  </Dialog>
);

export default FormDialog;
