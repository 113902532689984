import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AddressModel } from '@models/address';

import PhoneInput from '@components/shared/PhoneInput';
import TextInput from '@/components/shared/TextInput';
import SmartyAutocomplete from '@/components/shared/SmartyAutocomplete';

import http from '@/utils/http';

class AddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.address,
      processing: false,
      edited: false,
      searchAddress: '',
      searchSuite: '',
    };
  }

  handleInputChange = ({ target: { name, value } }) => {
    this.setState((prevState) => {
      const address = new AddressModel({ ...prevState.address });

      if (name === 'state') {
        address.set('state', value.toUpperCase());
      } else {
        address.set(name, value);
      }

      if (!['first_name', 'last_name', 'phone'].includes(name)) {
        address.set('verified', false);
      }

      return { address, edited: true };
    });
  };

  handleSearchChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  }

  handleAddressSelect = (address) => {
    this.setState((prevState) => {
      const newAddress = new AddressModel({ ...prevState.address, ...address, verified: true });
      return {
        address: newAddress,
        edited: true,
        searchAddress: newAddress.street_1,
        searchSuite: newAddress.street_2,
      };
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    const { onSuccessSubmit } = this.props;
    const { address } = this.state;

    this.setState({ processing: true });

    http.post(AddressModel.routes.addressesPath, { shipping_address: address.attributes })
      .then((response) => {
        const newAddress = new AddressModel(response.data);
        onSuccessSubmit(newAddress);

        window.flashMessages.addMessage({ type: 'success', text: 'New address has been created successfully.' });
      })
      .catch((error) => {
        this.setState({ address: new AddressModel(error.response.data) });
      })
      .finally(() => this.setState({ processing: false }));
  }

  render() {
    const {
      address: {
        first_name,
        last_name,
        street_1,
        street_2,
        city,
        state,
        zip,
        phone,
        errors,
      },
      searchAddress,
      searchSuite,
      processing,
      edited,
    } = this.state;

    return (
      <div className="shipping-address-dialog">
        <div className="row">
          <div className="shipping-address-dialog__search w-100">
            <SmartyAutocomplete
              value={searchAddress}
              onSelect={this.handleAddressSelect}
            >
              <TextInput
                key="address_line"
                name="searchAddress"
                label="Search"
                value={searchAddress}
                onChange={this.handleSearchChange}
                type="text"
              />

              <TextInput
                key="suite_line"
                name="searchSuite"
                label="Suite/Apt"
                value={searchSuite}
                onChange={this.handleSearchChange}
                type="hidden"
              />
            </SmartyAutocomplete>
          </div>
        </div>

        <form onSubmit={this.handleFormSubmit} className="shipping-address__form">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <TextInput
                  name="first_name"
                  label="First name"
                  type="text"
                  value={first_name || ''}
                  errors={errors.first_name}
                  onChange={this.handleInputChange}
                  icon="user"
                />
              </div>

              <div className="col-6">
                <TextInput
                  name="last_name"
                  label="Last name"
                  type="text"
                  value={last_name || ''}
                  errors={errors.last_name}
                  onChange={this.handleInputChange}
                  icon="user"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <TextInput
                  name="street_1"
                  label="Street line 1"
                  type="text"
                  value={street_1 || ''}
                  errors={errors.street_1}
                  onChange={this.handleInputChange}
                  icon="address"
                />
              </div>

              <div className="col-6">
                <TextInput
                  name="street_2"
                  label="Unit/Suite Number"
                  type="text"
                  value={street_2 || ''}
                  errors={errors.street_2}
                  onChange={this.handleInputChange}
                  icon="address"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <TextInput
                  name="city"
                  label="City"
                  type="text"
                  value={city || ''}
                  errors={errors.city}
                  onChange={this.handleInputChange}
                  icon="city"
                />
              </div>

              <div className="col-6">
                <TextInput
                  name="state"
                  label="State / Province / Region"
                  type="text"
                  value={state || ''}
                  maxLength="2"
                  errors={errors.state}
                  onChange={this.handleInputChange}
                  icon="state"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <TextInput
                  name="zip"
                  label="ZIP"
                  type="text"
                  value={zip || ''}
                  errors={errors.zip}
                  onChange={this.handleInputChange}
                  icon="zip"
                />
              </div>

              <div className="col-6">
                <PhoneInput
                  name="phone"
                  label="Phone number (For your privacy mobile is preferred)"
                  type="tel"
                  value={phone || ''}
                  errors={errors.phone}
                  onChange={this.handleInputChange}
                  icon="phone"
                />
              </div>
            </div>
          </div>


          <div className="col row justify-content-end">
            <button
              type="reset"
              className="btn btn-sm btn-light mr-3"
              onClick={this.props.onCancel}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-sm btn-primary"
              disabled={!edited || processing}
            >
              {processing ? 'Processing...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

AddressForm.propTypes = {
  onSuccessSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  address: PropTypes.instanceOf(AddressModel).isRequired,
};

export default AddressForm;
