const updateNotes = (note) => ({
  type: 'UPDATE_NOTES',
  note,
});

const searchNotes = (notes) => ({
  type: 'SEARCH_NOTES',
  notes,
});

const deleteNote = (note) => ({
  type: 'DELETE_NOTE',
  note,
});

const editNote = (note) => ({
  type: 'EDIT_NOTE',
  note,
});

export {
  updateNotes,
  searchNotes,
  deleteNote,
  editNote,
};
