import React, { Component } from "react";
import { connect } from "react-redux";

import { CartModel } from "@models/cart";
import { updateCart as updateCartAction } from "@actions/panel/cart_view/carts";
import { getStageChanges as getStageChangesAction } from "@actions/panel/cart_view/stage_changes";
import InlineEditButtons from "@components/shared/panel/InlineEditButtons";
import CustomInput from "@components/shared/panel/CustomInput";

import http from "@/utils/http";
import {
  cartStagesByBranch,
  allowedStagesMap,
  resolveRequestStageLabel,
} from "@/utils/panel";

class OpenCartItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openCart: props.openCart,
      openCartCopy: props.openCart,
      activeEdit: false,
      processing: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (_.isEqual(prevProps.openCart, this.props.openCart)) return;
    this.setState({
      openCart: this.props.openCart,
      openCartCopy: this.props.openCart,
    });
  }

  handleAttributeChange = ({ target: { name, value } }) => {
    this.setState((prevState) => {
      const openCart = new CartModel({ ...prevState.openCart });
      openCart.set(name, value);

      return { openCart, activeEdit: true };
    });
  };

  handleCancelEdit = () => {
    this.setState((prevState) => ({
      openCart: prevState.openCartCopy,
      activeEdit: false,
    }));
  };

  saveCart = () => {
    const { updateCart } = this.props;
    const { openCart } = this.state;

    http
      .put(openCart.routes.byId, { cart: openCart.attributes })
      .then((response) => {
        updateCart(response.data);

        window.flashMessages.addMessage({
          type: "success",
          text: "Cart has been updated.",
        });

        const newCart = new CartModel(response.data);
        this.setState({
          activeEdit: false,
          openCart: newCart,
          openCartCopy: newCart,
        });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          const newCart = new CartModel(error.response.data);
          this.setState({ openCart: newCart });
        } else {
          window.flashMessages.addMessage({
            type: "error",
            text: "Cart cannot be updated due to some errors.",
          });

          this.setState({ activeEdit: false });
        }
      });
  };

  handleStageChange = ({ target: { value } }) => {
    const {
      openCart,
      updateCart,
      currentCartId,
      getStageChanges,
      onLostSelect,
    } = this.props;

    if (value === "lost") {
      onLostSelect(openCart);
    } else {
      http
        .put(openCart.routes.changeStage, { cart: { stage: value } })
        .then((response) => {
          updateCart(response.data);

          if (openCart.id === currentCartId) {
            getStageChanges(currentCartId);
          }

          this.setState((prevState) => {
            const updatedCart = new CartModel({ ...prevState.openCart });
            updatedCart.set("stage", value);

            const updatedCartCopy = new CartModel({
              ...prevState.openCartCopy,
            });
            updatedCartCopy.set("stage", value);

            return { openCart: updatedCart, openCartCopy: updatedCartCopy };
          });

          window.flashMessages.addMessage({
            type: "success",
            text: "Cart stage has been changed.",
          });
        })
        .catch((error) => {
          window.flashMessages.addMessage({
            type: "error",
            text: `Cart stage cannot be changed due to error: ${error.response.data.errors.stage.join(
              "; "
            )}`,
          });
        });
    }
  };

  render() {
    const { selected, onSelect, deliveryOptions } = this.props;

    const {
      openCart: {
        id,
        formattedCreatedAt,
        product_name,
        branch,
        stage,
        previous_stage,
        rx_type,
        when_needed,
        final_quantity,
        max_quantity,
        finalPriceWithCurrency,
        have_discounts,
        delivery_type,
        daysInStage,
        totalDays,
        order_invoice_number,
        isEditable,
        isBranchChangePossible,
        errors = {},
      },
      activeEdit,
      processing,
    } = this.state;

    const availableStages = cartStagesByBranch[branch];
    const allowedStages = allowedStagesMap(stage, previous_stage);
    const errorsQuantity = _.union(errors.max_quantity, errors.quantity);

    return (
      <tr className="open-cart-item align-center">
        {activeEdit ? (
          <td className="pl-3">
            <InlineEditButtons
              approveAction={this.saveCart}
              cancelAction={this.handleCancelEdit}
              processing={processing}
            />
          </td>
        ) : (
          <>
            {isEditable && stage !== "lost" ? (
              <td className="pl-3">
                <input
                  type="checkbox"
                  value={id}
                  checked={selected}
                  onChange={onSelect}
                  className="custom-control-input"
                  id={`open-cart-item${id}`}
                />
                <label
                  htmlFor={`open-cart-item${id}`}
                  className="custom-control-label ml-4 mb-4"
                />
              </td>
            ) : (
              <td />
            )}
          </>
        )}
        <td className="text-center">{id}</td>
        <td>{formattedCreatedAt}</td>
        <td>{product_name}</td>
        <td>
          <select
            className="input input-select bg-cover"
            name="branch"
            value={branch || ""}
            onChange={this.handleAttributeChange}
            disabled={!isBranchChangePossible}
          >
            <option value="pharmacy_request">Pharmacy Request</option>
            <option value="refill_request">Refill Request</option>
            <option value="patient_request">Patient Request</option>
            <option value="transfer">Transfer</option>
            <option value="mailed">Mailed</option>
            <option value="no_doctor">No doctor</option>
            <option value="telemedicine">Telemedicine</option>
          </select>
        </td>
        <td>
          <select
            className="input input-select bg-cover"
            name="stage"
            value={stage || ""}
            onChange={this.handleStageChange}
          >
            {availableStages.map((stageName) => (
              <option
                value={stageName}
                key={stageName}
                disabled={!allowedStages.includes(stageName)}
              >
                {resolveRequestStageLabel(stageName, branch)}
              </option>
            ))}
          </select>
        </td>
        <td>
          <select
            className="input input-select bg-cover"
            name="rx_type"
            value={rx_type || ""}
            onChange={this.handleAttributeChange}
          >
            <option value="fresh">New</option>
            <option value="refill">Refill</option>
          </select>
        </td>
        <td>
          <select
            className="input input-select bg-cover"
            name="when_needed"
            value={when_needed || ""}
            onChange={this.handleAttributeChange}
          >
            <option value="now">Now</option>
            <option value="hold">Hold</option>
          </select>
        </td>
        <td>{daysInStage}</td>
        <td>{totalDays}</td>
        <td>{final_quantity}</td>
        <td>
          <CustomInput
            type="number"
            style={{ width: "70px" }}
            className={`input bg-white ${
              errorsQuantity.length && "is-invalid"
            }`}
            customspanclass="optional-line-height"
            name="max_quantity"
            onChange={this.handleAttributeChange}
            value={max_quantity || ""}
            error={errorsQuantity.length ? errorsQuantity[0] : null}
          />
        </td>
        <td>{finalPriceWithCurrency}</td>
        <td>{have_discounts ? "Yes" : "No"}</td>
        <td className="text-nowrap">{order_invoice_number || "---"}</td>
        <td>
          <select
            className="input input-select bg-cover"
            name="delivery_type"
            value={delivery_type || ""}
            onChange={this.handleAttributeChange}
            disabled={!isEditable}
          >
            <option value="" disabled>
              Not provided
            </option>
            {deliveryOptions.map((option) => (
              <option key={option.id} value={option.delivery_type}>
                {option.label}
              </option>
            ))}
          </select>
        </td>
      </tr>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCart: (cart) => dispatch(updateCartAction(cart)),
  getStageChanges: (id) => dispatch(getStageChangesAction(id)),
});

const mapStateToProps = (state) => ({
  currentCartId: state.carts.current_cart.id,
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenCartItem);
