import moment from 'moment';

import { BaseModel, BaseCollection } from './base';

class StageChangeModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      stage_from: '',
      branch: '',
      price: 0.0,
      created_at: '',
      closed_at: '',
      days_in_stage: 0,
      admin_name: '',
    };
  }

  get formattedCreatedAt() {
    return this.created_at ? moment(this.created_at).format('L') : '';
  }

  get formattedClosedAt() {
    return this.closed_at ? moment(this.closed_at).format('L') : '';
  }

  get priceWithCurrency() {
    return `$${Number(this.price).toFixed(2)}`;
  }
}

class StageChangesCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return StageChangeModel;
  }
}

export { StageChangeModel, StageChangesCollection };
