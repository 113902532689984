const initialState = {
  cart_issues: [],
  issue_options: [],
};

const issuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_ISSUE': {
      return {
        ...state,
        cart_issues: [...state.cart_issues, action.issue],
      };
    }
    case 'REFRESH_ISSUE': {
      const cartIssues = state.cart_issues.map((issue) => (
        issue.id === action.issue.id ? action.issue : issue
      ));

      return {
        ...state,
        cart_issues: cartIssues,
      };
    }
    default:
      return state;
  }
};

export default issuesReducer;
