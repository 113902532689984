import React, { Component } from 'react';
import { connect } from 'react-redux';

import CloseIcon from '@material-ui/icons/Close';

import { searchNotes } from '@actions/panel/cart_view/notes';
import { NoteModel } from '@models/note';
import TextInput from '@/components/shared/TextInput';
import { filterNotes } from './NoteFilterService';

class NoteSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.noteType !== prevProps.noteType) this.search();
  }

  handleSubmitForm = (event) => {
    event.preventDefault();
    this.search();
  }

  search = () => {
    const { cartId, currentUserId, noteType } = this.props;
    const { keyword } = this.state;

    const note = new NoteModel();
    const searchQuery = note.searchQuery({
      currentUserId,
      cartId,
      keyword,
      noteType,
    });

    filterNotes(note, searchQuery, this.props.searchNotes);
  }

  handleResetSearch = () => {
    this.setState({ keyword: '' }, () => this.search());
  }

  handleSearchInputChange = (event) => {
    this.setState({ keyword: event.target.value }, () => {
      if (this.state.keyword === '') this.search();
    });
  };

  render() {
    return (
      <form
        onSubmit={this.handleSubmitForm}
        className="note__search"
      >
        <div className="row">
          <TextInput
            value={this.state.keyword}
            placeholder="Search"
            onChange={this.handleSearchInputChange}
            customClass="with-button flex-grow-1"
            icon="search"
          />
          <button
            type="button"
            className="input-button note__search_reset"
            disabled={this.state.keyword === ''}
          >
            <CloseIcon onClick={this.handleResetSearch}>
              delete_sweep
            </CloseIcon>
          </button>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  searchNotes: (notes) => dispatch(searchNotes(notes)),
});

export default connect(
  null,
  mapDispatchToProps,
)(NoteSearch);
