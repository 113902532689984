import http from '@/utils/http';

const addIssue = (issue) => ({
  type: 'ADD_ISSUE',
  issue,
});

const refreshIssue = (issue) => ({
  type: 'REFRESH_ISSUE',
  issue,
});

const createIssue = (issue) => (dispatch) => http
  .post(issue.routes.all, { issue: issue.attributes })
  .then((response) => {
    window.flashMessages.addMessage({
      type: 'success',
      text: issue.createSuccessMessage(),
    });

    dispatch(addIssue(response.data));
  })
  .catch(() => {
    window.flashMessages.addMessage({
      type: 'error',
      text: 'Issue cannot be created due to some errors.',
    });
  });

const updateIssue = (issue) => (dispatch) => http
  .put(issue.routes.instance, { issue: issue.attributes })
  .then((response) => {
    window.flashMessages.addMessage({
      type: 'success',
      text: 'Issue has been updated.',
    });

    dispatch(refreshIssue(response.data));
  })
  .catch(() => {
    window.flashMessages.addMessage({
      type: 'error',
      text: 'Issue cannot be updated due to some errors.',
    });
  });

export {
  createIssue,
  updateIssue,
};
