import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateCart as updateCartAction } from '@actions/panel/cart_view/carts';
import { getStageChanges as getStageChangesAction } from '@actions/panel/cart_view/stage_changes';

import http from '@/utils/http';
import { cartStagesByBranch, allowedStagesMap, resolveRequestStageLabel } from '@/utils/panel';

class CartStagesTimeline extends Component {
  handleStageChange = ({ target: { value } }) => {
    const {
      currentCart,
      updateCart,
      getStageChanges,
      onLostSelect,
    } = this.props;

    if (value === 'lost') {
      onLostSelect();
    } else {
      http.put(currentCart.routes.changeStage, { cart: { stage: value } })
        .then((response) => {
          updateCart(response.data);
          getStageChanges(currentCart.id);

          if (response.data.stage === 'completed') {
            window.location = currentCart.routes.userPath;
          }

          window.flashMessages.addMessage({
            type: 'success',
            text: 'Cart stage has been changed.',
          });
        })
        .catch((error) => {
          window.flashMessages.addMessage({
            type: 'error',
            text: `Cart stage cannot be changed due to error: ${error.response.data.errors.stage.join('; ')}`,
          });
        });
    }
  }

  render() {
    const { currentCart } = this.props;
    const availableStages = cartStagesByBranch[currentCart.branch];
    const allowedStages = allowedStagesMap(currentCart.stage, currentCart.previous_stage);

    return (
      <div className="row align-items-center">
        <span className="cart-stages-timeline-title">Stage </span>
        <div className="cart-stages-timeline py-2">
          {availableStages.map((stage) => (
            <div className="form-check form-check-inline" key={`stage-${stage}`}>
              <input
                className="form-check-input"
                type="radio"
                name="stage"
                value={stage}
                id={`stage-${stage}`}
                checked={currentCart.stage === stage}
                onChange={this.handleStageChange}
                disabled={!allowedStages.includes(stage)}
              />
              <label htmlFor={`stage-${stage}`} className="form-check-label">
                {resolveRequestStageLabel(stage, currentCart.branch)}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCart: (cart) => dispatch(updateCartAction(cart)),
  getStageChanges: (id) => dispatch(getStageChangesAction(id)),
});

export default connect(null, mapDispatchToProps)(CartStagesTimeline);
