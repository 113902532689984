const initializeDiscountTemplateForm = () => {
  $(() => {
    const allVariantsOption = $('#discount_template_product_variant_id').find("option[value='']");

    $('#discount_template_type').change((e) => {
      allVariantsOption.attr('disabled', false);

      $('#discount_template_percent, #discount_template_price, #discount_template_tier, '
        + '#discount_template_extra_amount').parent().hide();
      switch (e.target.value) {
        case 'percentage':
        case 'delivery_percentage':
          $('#discount_template_percent').parent().show();
          break;
        case 'predefined':
          allVariantsOption.attr('disabled', true);
          $('#discount_template_price').parent().show();
          break;
        case 'few_extra':
          $('#discount_template_tier, #discount_template_extra_amount').parent().show();
          break;
        default:
          throw new Error('Unknown delivery type');
      }
    }).trigger('change');
  });
};

export default initializeDiscountTemplateForm;
