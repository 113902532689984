import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CartModel } from '@models/cart';
import { DiscountModel } from '@models/discount';

import TextInput from '@/components/shared/TextInput';

import http from '@/utils/http';

class DiscountForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      discount: props.discount,
      processing: false,
      edited: false,
    };
  }

  handleInputChange = ({ target: { name, value } }) => {
    this.setState((prevState) => {
      const discount = new DiscountModel({ ...prevState.discount });
      discount.set(name, value);

      return { discount, edited: true };
    });
  };

  handleTemplateChange = (event) => {
    const { globalDiscountTemplates, discountTemplates } = this.props;
    const { value: discount_template_id } = event.target;

    const allDiscountTemplates = globalDiscountTemplates.concat(discountTemplates.models);

    const globalDiscountTemplate = allDiscountTemplates.find(
      (template) => template.id === Number(discount_template_id),
    );

    const params = _.pick(globalDiscountTemplate, [
      'name',
      'type',
      'percent',
      'price',
      'tier',
      'extra_amount',
    ]);
    params.discount_template_id = globalDiscountTemplate.id;

    this.setState((prevState) => {
      const discount = new DiscountModel({ ...prevState.discount, ...params });
      discount.set('discount_template_id', discount_template_id);
      discount.set('errors', {});

      return { discount, edited: true };
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    const { discount } = this.state;
    const { handleDiscountSuccessSubmit, refreshCart } = this.props;
    const httpMethod = discount.id ? 'put' : 'post';

    this.setState({ processing: true });

    http({
      method: httpMethod,
      url: DiscountModel.routes.discountPath(discount.id),
      data: { discount: discount.attributes },
    }).then((response) => {
      const newDiscount = new DiscountModel(response.data);
      handleDiscountSuccessSubmit(newDiscount);
      refreshCart(newDiscount.cart_id);
      window.flashMessages.addMessage({ type: 'success', text: 'Discount has been created successfully.' });
    }).catch((error) => {
      this.setState({ discount: new DiscountModel(error.response.data) });
    }).finally(() => this.setState({ processing: false }));
  }

  render() {
    const {
      discount: {
        id,
        cart_id,
        discount_template_id,
        name,
        type,
        percent,
        price,
        extra_amount,
        errors,
      },
      discountTemplateId,
      processing,
      edited,
    } = this.state;

    const {
      globalDiscountTemplates,
      discountTemplates,
      onCancel,
      discountableCarts,
    } = this.props;

    const edition = !!id;

    return (
      <div className="discount-dialog mt-2">
        <form onSubmit={this.handleFormSubmit} className="discount__form">
          <div className="container p-0">
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label className="input-label" htmlFor="cart_id">Cart</label>
                  <select
                    className="input input-select bg-contain"
                    name="cart_id"
                    value={cart_id || ''}
                    onChange={this.handleInputChange}
                    disabled={edition}
                  >
                    {discountableCarts.map((cart) => (
                      <option
                        key={cart.id}
                        value={cart.id}
                      >
                        {cart.optionText}
                      </option>
                    ))}
                  </select>
                  {errors.cart_id && (
                    <span className="input__error">{errors.cart_id[0]}</span>
                  )}
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label className="input-label" htmlFor="discount_template_id">Coupon</label>
                  <select
                    className="input input-select bg-contain"
                    name="discount_template_id"
                    value={discount_template_id || ''}
                    onChange={this.handleTemplateChange}
                  >
                    <option disabled value="">Please select</option>

                    {globalDiscountTemplates.map((globalDiscountTemplate) => (
                      <option
                        key={globalDiscountTemplate.id}
                        value={globalDiscountTemplate.id}
                      >
                        {globalDiscountTemplate.name}
                      </option>
                    ))}
                    {discountTemplates.models.map((discountTemplate) => (
                      <option
                        key={discountTemplate.id}
                        value={discountTemplate.id}
                      >
                        {discountTemplate.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <TextInput
                  name="name"
                  label="Name"
                  type="text"
                  value={name || ''}
                  errors={errors.name}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label className="input-label" htmlFor="type">Type</label>
                  <select
                    className="input input-select bg-contain"
                    name="type"
                    value={type || ''}
                    onChange={this.handleInputChange}
                  >
                    <option disabled value="">Please select</option>
                    <option value="percentage">Percentage</option>
                    <option value="delivery_percentage">Delivery percentage</option>
                    <option value="predefined">Predefined price</option>
                    <option value="few_extra">A few extra</option>
                  </select>
                  {errors.type && (
                    <span className="input__error">{errors.type[0]}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              {['percentage', 'delivery_percentage'].includes(type) && (
                <div className="col-6">
                  <TextInput
                    name="percent"
                    label="Percent"
                    type="number"
                    step=" 0.00000001"
                    value={percent || ''}
                    errors={errors.percent}
                    onChange={this.handleInputChange}
                  />
                </div>
              )}
              {type === 'predefined' && (
                <div className="col-6">
                  <TextInput
                    name="price"
                    label="Price"
                    type="number"
                    min="0"
                    step="0.01"
                    value={price || ''}
                    errors={errors.price}
                    onChange={this.handleInputChange}
                  />
                </div>
              )}
              {type === 'few_extra' && (
                <div className="col-6">
                  <TextInput
                    name="extra_amount"
                    label="Quantity"
                    type="number"
                    min="0"
                    value={extra_amount || ''}
                    errors={errors.extra_amount}
                    onChange={this.handleInputChange}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="col row justify-content-end">
            <button
              type="reset"
              className="btn btn-sm btn-light mr-3"
              onClick={onCancel}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-sm btn-primary"
              disabled={discountTemplateId === '' || !edited || processing}
            >
              {processing ? 'Processing...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCart: new CartModel(state.carts.current_cart),
});

export default connect(mapStateToProps)(DiscountForm);
