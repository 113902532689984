import React, { useState } from "react";
import http from "@/utils/http";
import questionMarkIcon from "../../../../../assets/images/panel/icons/not_verified.png";

const ContactHeader = (props) => {
  const { currentCart, user, prices } = props;
  const [userReferrer, setUserReferrer] = useState(user.referrer);
  const [userPrice, setUserPrice] = useState(
    _.find(prices, ["id", user.price_id])?.id
  );

  const handleReferrerChange = (e) => {
    const referrer = e.target.value;

    http
      .put(user.routes.byId, {
        user: {
          referrer,
        },
      })
      .then(() => {
        setUserReferrer(referrer);

        window.flashMessages.addMessage({
          type: "success",
          text: "Referrer successfully updated",
        });
      })
      .catch((err) => {
        console.log(err);

        window.flashMessages.addMessage({
          type: "alert",
          text: "Unable to update referrer",
        });
      });
  };

  const handlePriceVersionChange = (e) => {
    const priceId = e.target.value;

    http
      .put(user.routes.byId, {
        user: {
          price_id: priceId,
        },
      })
      .then(() => {
        setUserPrice(priceId);

        window.flashMessages.addMessage({
          type: "success",
          text: "Price version successfully updated",
        });
      })
      .catch((err) => {
        console.log(err);

        window.flashMessages.addMessage({
          type: "alert",
          text: "Unable to update price version",
        });
      });
  };

  const getPlaidBadge = () => {
    const demoGraphicsVerified = user.demographics_verified;

    switch (user.demographics_status) {
      case "success":
        return "verified";
        break;
      case "failed":
        return "unverified";
        break;
      default:
        return "pending";
        break;
    }
  };

  return (
    <div className="basic-info">
      <strong className="contact-name">{user.fullname}</strong>
      <div class={`plaid-verification-badge ${getPlaidBadge()}`}>
        <span title={`Plaid ${getPlaidBadge()}`}>
          {!user.demographics_status ? (
            <>
              <img src={questionMarkIcon} className="question-mark-icon w-50" />
            </>
          ) : (
            <>
              {user.demographics_verified ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.516 2.17a.75.75 0 00-1.032 0 11.209 11.209 0 01-7.877 3.08.75.75 0 00-.722.515A12.74 12.74 0 002.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 00.374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 00-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08zm3.094 8.016a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clip-rule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                    clip-rule="evenodd"
                  />
                </svg>
              )}
            </>
          )}
        </span>
      </div>

      {user.poc_attributes && user.poc_attributes.name && (
        <span className="point-of-contact">
          POC: {user.poc_attributes.name}
        </span>
      )}

      <span className="mobile-phone list">
        M: {user.poc_attributes.mobile_phone || user.mobile_phone}
      </span>
      <span className="date_of_birth list">DOB: {user.date_of_birth}</span>
      <span className="age list">Age: {user.age}</span>
      <span className="referrer list mr-0">Referrer:</span>
      <div className="col-2">
        <select
          className="input input-select bg-contain referrer-selector"
          name="referrer"
          value={userReferrer || ""}
          onChange={handleReferrerChange}
        >
          <option value="no_referrer">No referrer</option>
          <option value="F">Facebook</option>
          <option value="G">Google</option>
          <option value="I">Instagram</option>
        </select>
      </div>
      <span className="referrer list mr-0">Price Version:</span>
      <div className="col-2">
        <select
          className="input input-select bg-contain price-selector"
          name="price"
          value={userPrice}
          onChange={handlePriceVersionChange}
        >
          <option value="" disabled />
          {prices.map((price) => (
            <option value={`${price.id}`}>{price.title}</option>
          ))}
        </select>
      </div>
      <strong className="cart-total-price">
        {currentCart.finalPriceWithCurrency}
      </strong>
    </div>
  );
};

export default ContactHeader;
