import React, { Component } from "react";

import FormDialog from "@components/shared/FormDialog";
import ContactForm from "@components/panel/views/ContactForm";

class ContactFormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactDialogStatus: false,
    };
  }

  handleOpenDialog = (dialogStatusName) => {
    this.setState({ [dialogStatusName]: true });
  };

  handleCloseDialog = (dialogStatusName) => {
    this.setState({ [dialogStatusName]: false });
  };

  render() {
    const { contactDialogStatus } = this.state;
    const { landingPages } = this.props;
    const { prices } = this.props;

    return (
      <div className="carts-users-container">
        <div className="row justify-content-end">
          <div className="carts-users-actions p-2">
            <button
              type="button"
              className="btn btn-sm btn-outline-primary mx-2"
              onClick={() => this.handleOpenDialog("contactDialogStatus")}
            >
              New Contact
            </button>
          </div>
        </div>

        <FormDialog
          title="Contact Form"
          isOpen={contactDialogStatus}
          onClose={() => this.handleCloseDialog("contactDialogStatus")}
          customClass="dialog__wide contacts_form"
        >
          <ContactForm
            landingPages={landingPages}
            onCancel={() => this.handleCloseDialog("contactDialogStatus")}
            prices={prices}
          />
        </FormDialog>
      </div>
    );
  }
}

export default ContactFormContainer;
