import { BaseModel, BaseCollection } from './base';

class ProductVariantModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      product_id: null,
      dosage: '',
      unit: '',
      product_name: '',
      price_thresholds: [],
    };
  }

  get fullname() {
    return [this.product_name, this.dosage].join(' ');
  }
}

class ProductVariantsCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return ProductVariantModel;
  }

  get selectableProducts() {
    const allProducts = this.models.map((variant) => (
      { id: variant.product_id, name: variant.product_name }
    ));

    return _.uniqBy(allProducts, 'id');
  }

  filterByProductId(id) {
    return this.models.filter((variant) => variant.product_id === Number(id));
  }
}

export { ProductVariantModel, ProductVariantsCollection };
