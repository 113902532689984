const calculatePrices = (variant, quantity) => {
  const baseThreshold = _.minBy(
    variant.price_thresholds,
    (thd) => thd.min_quantity
  );

  const basePrice = baseThreshold
    ? Number(baseThreshold.price * quantity).toFixed(2)
    : 0.0;

  const possibleThresholds = variant.price_thresholds.filter(
    (thd) => quantity >= thd.min_quantity
  );
  const threshold = _.maxBy(possibleThresholds, (thd) => thd.min_quantity);

  const price = threshold ? Number(threshold.price * quantity).toFixed(2) : 0.0;

  const priceDifference = Number(basePrice - price).toFixed(2);
  const discount = Math.round((priceDifference / basePrice) * 100) || 0;

  return {
    basePrice,
    price,
    priceDifference,
    discount,
  };
};

const showMinQuantityInfo = (variant, cart) =>
  variant && cart && cart.quantity < variant.min_order_quantity;

const isPickUpAvailable = (context, shippingAddresses) => {
  const shippingAddress = shippingAddresses.find(
    (address) => address.id === context.shipping_address_id
  );

  if (shippingAddress) {
    return shippingAddress.pick_up_available;
  } else {
    return null;
  }
};

export {
  calculatePrices,
  showMinQuantityInfo,
  isPickUpAvailable,
};
