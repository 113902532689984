// React
import React from 'react';
import ReactDOM from 'react-dom';

// Components
import FlashMessages from '@components/flash_messages/FlashMessages';

const initializeFlashMessages = (messages) => {
  ReactDOM.render(
    <FlashMessages messages={messages} />,
    document.querySelector('#flash-messages[data-behavior="react"]'),
  );
};

export default initializeFlashMessages;
