import React, { Component } from 'react';

import Message from './Message';

class FlashMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: props.messages,
    };

    window.flashMessages = this;
  }

  addMessage = (message) => {
    this.setState((prevState) => {
      const messages = [...prevState.messages, message];
      return { messages };
    });
  }

  deleteMessage = (message) => {
    this.setState((prevState) => {
      if (document.querySelector('.nav .based-in')) {
        document.querySelector('.nav .based-in').classList.remove('hidden');
      }
      const messages = prevState.messages.filter((el) => el !== message);
      return { messages };
    });
  };

  render() {
    return (
      <div>
        {this.state.messages.map((message) => {
          const key = Math.random()
            .toString(36)
            .substring(7);

          if (message?.text?.length) {
            return (
              <Message
                key={key}
                message={message}
                timeout="6000"
                onClose={() => this.deleteMessage(message)}
              />
            );
          }
        })}
      </div>
    );
  }
}

export default FlashMessages;
