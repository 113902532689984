import moment from 'moment';

import { BaseModel, BaseCollection } from './base';

class DiscountModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      cart_id: null,
      id: null,
      discount_template_id: null,
      name: '',
      code: '',
      created_at: '',
      admin_name: '',
      approved_by_admin_name: '',
      percent: '',
      price: '',
      tier: 0,
      extra_amount: '',
      approved: false,
    };
  }

  get formattedCreatedAt() {
    return this.created_at ? moment(this.created_at).format('L') : '';
  }

  static get routes() {
    return {
      discountPath: (id) => (id ? `/discounts/${id}` : '/discounts'),
      approvePath: (id) => `/discounts/${id}/approve`,
      deletePath: (id) => `/discounts/${id}`,
    };
  }
}

class DiscountsCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return DiscountModel;
  }
}

export { DiscountModel, DiscountsCollection };
