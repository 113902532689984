import React, { Component } from "react";

import http from "@/utils/http";

class SmartyAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: null,
      isAddressDropdownOpen: false,
      isSuiteDropdownOpen: false,
      loading: false,
      results: [],
      suiteList: [],
      selectedAddress: null,
    };
  }

  componentDidMount() {
    document.addEventListener("click", (event) => this.closeDropdown(event));
  }

  componentWillUnmount() {
    document.removeEventListener("click", (event) => this.closeDropdown(event));
  }

  closeDropdown = (event) => {
    if (
      !$(event.target).closest(".address-dropdown").length &&
      !$(event.target).is(".address-dropdown")
    ) {
      this.setState({
        isAddressDropdownOpen: false,
        isSuiteDropdownOpen: false,
      });
    }
  };

  handleAddressSearch = (e) => {
    const text = e.target.value;

    if (text.length > 3) {
      clearTimeout(this.state.timeout);

      this.setState({
        loading: true,
        results: [],
        isAddressDropdownOpen: true,
      });

      this.state.timeout = setTimeout(() => {
        http
          .get("/shipping_addresses/suggest", { params: { prefix: text } })
          .then((response) => {
            this.setState({
              results: response.data.result,
              isAddressDropdownOpen: true,
            });
          })
          .catch((error) => {
            throw error;
          })
          .finally(() => this.setState({ loading: false }));
      }, 500);
    } else {
      this.setState({ results: [], isAddressDropdownOpen: false });
    }
  };

  handleResultClick = (data) => {
    this.setState({
      isAddressDropdownOpen: false,
      isSuiteDropdownOpen: data.SuiteCount > 0,
      suiteList: data.SuiteCount === 0 ? [] : data.SuiteList,
      selectedAddress: data,
    });

    const address = {
      street_1: data.street_line,
      street_2: data.secondary,
      city: data.city,
      state: data.state,
      zip: data.zipcode || "",
      country: "US",
    };

    this.props.onSelect(address);
  };

  handleSuiteSelect = (suite) => {
    this.setState({
      isSuiteDropdownOpen: false,
    });

    const { selectedAddress } = this.state;

    const suiteIndex = selectedAddress.SuiteList.findIndex(
      (el) => el === suite
    );
    const zip = `${selectedAddress.PostalCode}-${selectedAddress.PlusFour[suiteIndex]}`;

    this.props.onSelect({
      street_1: selectedAddress.AddressLine1,
      street_2: suite,
      zip,
    });
  };

  render() {
    const {
      loading,
      isAddressDropdownOpen,
      isSuiteDropdownOpen,
      results,
      suiteList,
    } = this.state;

    const addressLineElement = this.props.children.find(
      (el) => el.key === "address_line"
    );
    const suiteElement = this.props.children.find(
      (el) => el.key === "suite_line"
    );
    const suiteDropdownStyle =
      suiteElement.props.type === "hidden"
        ? "address-dropdown"
        : "suite-dropdown";

    return (
      <div className="melissa-autocomplete with-dropdown">
        <div
          className={`address-dropdown ${isAddressDropdownOpen && "is-open"}`}
        >
          {React.cloneElement(addressLineElement, {
            onFocus: () =>
              this.setState({
                isAddressDropdownOpen: results.length > 0,
                isSuiteDropdownOpen: false,
              }),
            onKeyUp: this.handleAddressSearch,
          })}
        </div>
        {isAddressDropdownOpen && (
          <div className="dropdown-wrapper">
            <div className="dropdown select-dropdown autocomlete-dropdown address-dropdown">
              {loading && <span className="p-3">Loading...</span>}

              {!loading && results.length === 0 ? (
                <span className="p-3 mouseflow-hidden">No results</span>
              ) : (
                results.map((result) => (
                  <button
                    className="mouseflow-hidden"
                    type="button"
                    onClick={() => this.handleResultClick(result)}
                  >
                    {result.street_line} {result.secondary}{" "}
                    {result.entries > 0 && (
                      <>{`(${result.entries} or more entries)`} </>
                    )}
                    {result.city}, {result.state} {result.zipcode}
                  </button>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SmartyAutocomplete;
