import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createIssue as createIssueAction } from '@actions/panel/cart_view/issues';

import { IssueModel } from '@models/issue';

class IssueForm extends Component {
  constructor(props) {
    super(props);

    const branchName = this.branchName(props.currentCartId);
    const current_cart = props.carts.models.find((cart) => cart.id === Number(props.currentCartId));
    const view = current_cart.branch;

    this.state = {
      issue: new IssueModel({
        status: 'open',
        stage: this.stageName(props.currentCartId, branchName),
        view,
        branch: branchName,
        cart_id: props.currentCartId,
        lost_reason: false,
        processing: false,
        edited: false,
      }),
    };
  }

  handleFormSubmit = (event) => {
    event.preventDefault();

    const { issue } = this.state;
    const { createIssue, onCancel } = this.props;

    this.setState({ processing: true });

    createIssue(issue).finally(() => this.setState({ processing: false }));
    onCancel();
  }

  handleAttributeChange = ({ target: { name, value } }) => {
    this.setState((prevState) => {
      const issue = new IssueModel({ ...prevState.issue });
      issue.set(name, value);

      return { issue, edited: true };
    });
  }

  defaultView = (stage, views) => {
    const viewsForStage = views[this.stageKey(stage)];

    return _.snakeCase(viewsForStage[0]);
  }

  viewName = (branchName) => {
    if (branchName === 'transfer') {
      return 'transfer';
    }

    return this.defaultView(this.stageName(this.props.currentCartId), this.props.issueOptions.views);
  }

  stageName = (cartId, branchName) => {
    const carts = this.props.carts.models.filter((cart) => cart.id.toString() === cartId.toString());

    if (branchName === 'transfer' && carts[0].stage === 'request') {
      return 'transfer';
    }

    return _.capitalize(carts[0].stage);
  }

  branchName = (cartId) => {
    const carts = this.props.carts.models.filter((cart) => cart.id.toString() === cartId.toString());

    return carts[0].branch;
  }

  stageKey = (stage) => {
    let key = stage.toLowerCase();
    if (stage === 'Fulfillment') {
      key = 'order_fulfillment';
    }

    return key;
  }

  render() {
    const { issueOptions, carts, onCancel } = this.props;
    const issueViews = issueOptions.views;
    const issueNames = issueOptions.issues;

    const {
      issue: {
        cart_id,
        stage,
        name,
        view,
      },
      processing,
      edited,
    } = this.state;

    let issues = [];
    if (view !== '') {
      issues = issueNames[view.toLowerCase()];
    }

    return (
      <div className="shipping-address-dialog">
        <form onSubmit={this.handleFormSubmit} className="shipping-address__form">
          <div className="container">
            <div className="row">
              <div className="col-6 mb-2">
                <label htmlFor="issue_cart_id" className="input-label mb-0">Order</label>
                <select
                  value={cart_id}
                  name="cart_id"
                  className="input input-select bg-cover"
                  onChange={this.handleAttributeChange}
                >
                  <option value="">Please select</option>
                  {carts.models.map((cart) => (
                    <option value={cart.id} key={cart.id}>
                      {cart.optionText}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6 mb-2">
                <label htmlFor="issue_name" className="input-label mb-0">Issue</label>
                <select
                  value={name}
                  name="name"
                  className="input input-select bg-cover"
                  onChange={this.handleAttributeChange}
                >
                  <option value="">Please select</option>
                  {issues && issues.map((issueName) => (
                    <option value={issueName} key={issueName}>
                      {issueName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mb-2">
                <label htmlFor="issue_view" className="input-label mb-0">View</label>
                <select
                  value={view}
                  onChange={this.handleAttributeChange}
                  name="view"
                  className="input input-select bg-cover"
                >

                  <option value="">Please select</option>
                  {issueViews[this.stageKey(stage)].map((issueView) => (
                    <option key={issueView} value={_.snakeCase(issueView)}>
                      {_.capitalize(issueView)}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mx-0 row justify-content-end">
              <button
                type="reset"
                className="btn btn-sm btn-light mr-3"
                onClick={onCancel}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-sm btn-primary"
                disabled={!edited || processing}
              >
                {processing ? 'Processing...' : 'Save'}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createIssue: (issue) => dispatch(createIssueAction(issue)),
});

export default connect(null, mapDispatchToProps)(IssueForm);
