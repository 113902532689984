import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import { FaxModel, FaxesCollection } from '@models/fax';
import { CartModel } from '@models/cart';

import http from '@/utils/http';

class FaxesList extends Component {
  onClickFax = (event) => {
    this.props.onSelectFax(event.target.value);
  }

  onChangeInput = () => {
  }

  issueViewFaxCellContent = (cellInfo) => {
    const { faxes } = this.props;
    const faxId = faxes.models[cellInfo.index].id;

    return (
      <button
        type="button"
        className="btn btn-sm btn-outline-primary mx-2"
        onClick={function () { this.handlePreviewClick(faxId); }.bind(this)}
      >View Fax
      </button>
    );
  }

  handlePreviewClick = (faxId) => {
    const fax = new FaxModel({ id: faxId });
    http.post(fax.routes.show, {}, { responseType: 'blob' })
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }).catch((error) => {
        window.flashMessages.addMessage({
          type: 'error',
          text: error,
        });
      });
  }

  render() {
    const { faxes, selectedFaxId } = this.props;

    const columns = [
      {
        Header: '',
        accessor: 'id',
        Cell: (props) => (
          <input
            type="radio"
            name="fax_to_edit"
            onClick={this.onClickFax}
            checked={props.value === selectedFaxId}
            onChange={this.onChangeInput}
            value={props.value}
            className="number"
          />
        ),
      },
      {
        Header: 'To',
        accessor: 'doctor_fullname',
      },
      {
        Header: 'Subject',
        accessor: 'fax_top_note',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Category',
        accessor: 'branch',
      },
      {
        Header: 'Date',
        accessor: 'send_date',
      },
      {
        Header: 'Time',
        accessor: 'send_hour',
      },
      {
        Header: 'Prescriber number',
        accessor: 'doctor_fax',
      },
      {
        Header: 'View fax',
        ccessor: 'id',
        Cell: this.issueViewFaxCellContent,
      },
      {
        Header: 'Sent by',
        accessor: 'admin_fullname',
      },

    ];

    return (
      <ReactTable
        minRows={1}
        columns={columns}
        data={faxes.models}
        defaultPageSize={10}
        pageSizeOptions={[10, 30, 50]}
        className="-striped -highlight"
      />
    );
  }
}

FaxesList.propTypes = {
  faxes: PropTypes.instanceOf(FaxesCollection).isRequired,
};

const mapStateToProps = (state) => ({
  currentCart: new CartModel(state.carts.current_cart),
});

export default connect(mapStateToProps)(FaxesList);
